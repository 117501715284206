import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Dropdown,
  Grid,
  Row,
  TextInputSkeleton,
  Tile,
} from "carbon-components-react";
import { Add16, Save16, TrashCan16 } from "@carbon/icons-react";
import { PuerperaRow } from "../../components/Viewer";
import { SchemaEditor } from "../../components/Editor";
import { CentersContext, SchemaContext } from "../../context";
import * as A from "../../utils/array";
import * as RS from "../../utils/room-schedule";
import { useUserData } from "../../hooks";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import * as S from "../../utils/schema";

const EditorWrapper = styled.div`
  margin: 32px;
`;

const ScheduleRowWrapper = styled.div`
  margin-top: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 24px;
`;

export const BookRoomCreateOrEdit = () => {
  const { pid } = useParams();

  const navigate = useNavigate();
  const user = useUserData();

  const { bookRoom: bookRoomSchema } = useContext(SchemaContext) || {};

  const puerperaURL = `${URL.puerpera}${pid}/`;
  const { data: puerperaData } = useSWR([puerperaURL], F.withToken);

  const [bookRoomData, setBookRoomData] = useState({});
  const [bookRoomID, setBookRoomID] = useState();

  useEffect(() => {
    const payload = { employee_id: user?.employee_id, puerpera_id: pid };
    F.post(`${URL.book.room}`, payload).then((r) => {
      setBookRoomData(r);
    });
  }, [user, pid, setBookRoomData]);

  const [countSchedule, setCountSchedule] = useState(0);
  // 存放原始的 schedule list
  const [originalScheduleList, setOriginalScheduleList] = useState([]);
  // 存放會被編輯的 schedule list
  const [scheduleList, setScheduleList] = useState([]);

  useEffect(() => {
    if (!bookRoomData.id) return;
    if (!!bookRoomID) return;

    const url = `${URL.book.roomSchedule}?expand=room.center&bookroom_id=${bookRoomData.id}`;
    F.withToken(url).then((list) => {
      setBookRoomID(bookRoomData.id);
      setCountSchedule(list.length);
      setOriginalScheduleList(list);
      setScheduleList(list);
    });
  }, [
    bookRoomData,
    setOriginalScheduleList,
    setScheduleList,
    bookRoomID,
    setBookRoomID,
  ]);

  const isBookRoomReady = S.createSimpleValidator(bookRoomSchema);
  const isScheduleListSaved = useMemo(
    () => A.isEqual(originalScheduleList, scheduleList, RS.isEqual),
    [originalScheduleList, scheduleList]
  );
  const isReady = useMemo(
    () => isBookRoomReady(bookRoomData) && isScheduleListSaved,
    [isBookRoomReady, bookRoomData, isScheduleListSaved]
  );

  const isLoadingPuerpera = !pid && !puerperaData;

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;
    const { id, ...payload } = bookRoomData;
    await F.put(`${URL.book.room}${id}/`, payload);
    navigate(`/bookroom/${id}`);
  }, [navigate, bookRoomData, isReady]);

  const handleAddSchedule = () => {
    const nextId = countSchedule + 1;
    setCountSchedule(nextId);
    setScheduleList(A.append(scheduleList, { _id: nextId }));
  };

  const handleUpdateSchedule = useCallback(
    (i) => (s) => {
      setScheduleList((ss) => {
        return A.replace(ss, { ...ss[i], ...s }, i);
      });
    },
    [setScheduleList]
  );

  const handleSaveSchedule = useCallback(
    (i) => (s) => {
      setOriginalScheduleList((ss) => {
        return A.replace(ss, { ...ss[i], ...s }, i);
      });
      setScheduleList((ss) => {
        return A.replace(ss, { ...ss[i], ...s }, i);
      });
    },
    [setOriginalScheduleList, setScheduleList]
  );

  const handleRemoveSchedule = useCallback(
    (i) => async (s) => {
      if (s.id) {
        await F.deleteWithToken(`${URL.book.roomSchedule}${s.id}/`);
        setOriginalScheduleList((ss) => A.remove(ss, i));
        setScheduleList((ss) => A.remove(ss, i));
        setCountSchedule(countSchedule - 1);
      } else {
        setScheduleList((ss) => A.remove(ss, i));
        setCountSchedule(countSchedule - 1);
      }
    },
    [setScheduleList, countSchedule, setCountSchedule]
  );

  return (
    <Content className="bookroom-create">
      <Breadcrumb>
        <BreadcrumbItem href="/bookroom">訂房/排房管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          編輯
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>新增訂房/排房</Tile>

      <PuerperaRow
        showPuerperaLink={true}
        showPhone={true}
        data={puerperaData}
        loading={isLoadingPuerpera}
      />

      <Grid>
        <Column>
          <EditorWrapper>
            <SchemaEditor
              fields={bookRoomSchema.fields}
              excludes={[
                "id",
                "employee_id",
                "created",
                "modified",
                "employee",
                "puerpera",
                "puerpera_id",
              ]}
              data={bookRoomData}
              onChange={(x) => {
                if (!user) return;
                setBookRoomData({ ...x, employee_id: user.employee_id });
              }}
            />
          </EditorWrapper>
        </Column>

        <Grid>
          <Column>
            {scheduleList ? (
              scheduleList.map((s, i) => {
                const sid = s.id !== undefined ? s.id : s._id;
                return (
                  <ScheduleRowWrapper>
                    <ScheduleRow
                      key={sid}
                      id={sid}
                      schedule={s}
                      bookroomID={bookRoomData.id}
                      puerperaID={pid}
                      onChange={handleUpdateSchedule(i)}
                      onSaveSchedule={handleSaveSchedule(i)}
                      onRemoveClick={handleRemoveSchedule(i)}
                    />
                  </ScheduleRowWrapper>
                );
              })
            ) : (
              <TextInputSkeleton />
            )}
          </Column>

          <Column>
            <EditorWrapper>
              <Button
                kind="primary"
                size="small"
                renderIcon={Add16}
                onClick={handleAddSchedule}
              >
                新增時段
              </Button>
            </EditorWrapper>
          </Column>
        </Grid>

        <Column>
          <Grid>
            <Row>
              <Column>
                <Button
                  disabled={!isReady}
                  kind="primary"
                  size="small"
                  renderIcon={Save16}
                  onClick={handleSubmit}
                >
                  確認訂房/排房
                </Button>
              </Column>
            </Row>
          </Grid>
        </Column>
      </Grid>
    </Content>
  );
};

const ScheduleRow = ({
  id,
  schedule,
  bookroomID,
  puerperaID,
  onChange,
  onSaveSchedule,
  onRemoveClick,
}) => {
  const { list: centerList } = useContext(CentersContext);
  const { bookRoomSchedule: brsSchema } = useContext(SchemaContext) || {};
  const user = useUserData();
  const validate = useMemo(
    () => S.createSimpleValidator(brsSchema),
    [brsSchema]
  );

  const [center, setCenter] = useState(schedule?.room?.center ?? "");

  const isReady = validate(schedule);

  const handleChangeSchedule = useCallback(
    (s) => {
      if (typeof onChange !== "function") return;
      onChange(s);
    },
    [onChange]
  );

  const handleSaveSchedule = useCallback(
    async (s) => {
      if (!isReady) return;
      let newSchedule;

      function checkPayload(raw) {
        const { parkingspace_id, contract_id, ...other } = raw;
        let payload = { ...other };
        if (parkingspace_id !== 0) {
          payload["parkingspace_id"] = parkingspace_id;
        } else {
          payload["parkingspace_id"] = null;
        }
        if (contract_id !== 0) {
          payload["contract_id"] = contract_id;
        } else {
          payload["contract_id"] = null;
        }
        return payload;
      }

      if (s.id) {
        // Update schedule flow
        const payload = checkPayload(s);
        newSchedule = await F.put(`${URL.book.roomSchedule}${s.id}/`, payload);
      } else {
        // Create schedule flow
        const { _id, ...other } = s;
        const payload = checkPayload(other);
        const r = await F.post(URL.book.roomSchedule, payload);
        newSchedule = { ...r, _id };
      }

      if (typeof onSaveSchedule !== "function") return;
      onSaveSchedule(newSchedule);
    },
    [isReady, onSaveSchedule]
  );

  return (
    <Grid>
      <Column md={2}>
        <Dropdown
          id={`center-selector_${id}`}
          labelText="館別"
          label="請選擇館別"
          items={centerList}
          itemToString={(item) => item.name}
          selectedItem={center}
          onChange={(v) => {
            setCenter(v.selectedItem);
          }}
        />
      </Column>
      <Column>
        <EditorWrapper>
          <SchemaEditor
            id={`editor_${id}`}
            fields={brsSchema.fields}
            excludes={[
              "id",
              "bookroom_id",
              "employee_id",
              "created",
              "modified",
              "book_days",
              "cost_sum",
            ]}
            filter={{ center: center, puerpera: puerperaID }}
            data={schedule}
            onChange={(x) => {
              if (!user) return;
              handleChangeSchedule({
                ...x,
                employee_id: user.employee_id,
                bookroom_id: bookroomID,
              });
            }}
          />
        </EditorWrapper>
      </Column>
      <Column>
        <Grid>
          <Row>
            <Column>
              <Button
                disabled={!isReady}
                kind="primary"
                size="small"
                renderIcon={Save16}
                onClick={() => handleSaveSchedule(schedule)}
              >
                儲存
              </Button>
            </Column>
            <Column>
              <Button
                kind="primary"
                size="small"
                renderIcon={TrashCan16}
                onClick={() => {
                  onRemoveClick(schedule);
                }}
              >
                刪除
              </Button>
            </Column>
          </Row>
        </Grid>
      </Column>
    </Grid>
  );
};
