import { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  Modal,
} from "carbon-components-react";
import { Add16, Edit20, EditOff20 } from "@carbon/icons-react";
import dayjs from "dayjs";
import useSWR from "swr";

import { FoodVendorRow } from "../../components/Viewer";

import { usePuerperaAndCenter } from "../../hooks";
import { DateTimeFormat } from "../../utils/datetime";
import { UserContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";

const headers = [
  {
    key: "start_date",
    name: "開始日期",
  },
  {
    key: "start_meal",
    name: "從那一餐開始",
  },
  {
    key: "foodvendor",
    name: "膳食供廠商",
  },
  {
    key: "employee_name",
    name: "操作人員",
  },
  {
    key: "modified",
    name: "變更日期",
  },
  {
    key: "actions",
    name: "編輯/取消",
  },
];

export const FoodVendorFromHousing = () => {
  const { hid } = useParams();

  const {
    token: { access },
  } = useContext(UserContext);

  const changeFVHURL = `${URL.changeFoodVendorHistory}?expand=employee,foodvendor.center,start_meal&status=1&housing=${hid}`;
  const { data: rawFoodVendorData } = useSWR(
    access && [changeFVHURL],
    F.withToken
  );

  const [puerpera, puerperaDisplay, center] = usePuerperaAndCenter({
    housing: hid,
  });

  const [currentFoodVendor, setCurrentFoodVendor] = useState();
  const [foodVendorData, setFoodVendorData] = useState([]);

  const isLoading = !rawFoodVendorData && !puerpera;

  useEffect(() => {
    if (!rawFoodVendorData || rawFoodVendorData == null) {
      return;
    }
    // 找到 reason 是空的，為第一個
    const findFirst = (fv) => fv.reason === null;
    var firstFV = rawFoodVendorData.find(findFirst);
    if (firstFV === undefined) {
      return;
    }
    var fvList = A.removeBy(rawFoodVendorData, findFirst);
    setCurrentFoodVendor(firstFV.foodvendor ?? {});
    setFoodVendorData(fvList);
  }, [rawFoodVendorData]);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/housing/">住房管理</BreadcrumbItem>
        <BreadcrumbItem href={`/housing/${hid}`}>
          {puerperaDisplay}
        </BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          膳食供廠商
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>更換膳食紀錄</Tile>

      <FoodVendorRow
        puerpera={puerpera}
        foodVendor={currentFoodVendor}
        center={center}
      />

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <ChangeFoodVendorHistoryTable housing={hid} items={foodVendorData} />
      )}
    </Content>
  );
};

function ChangeFoodVendorHistoryTable({ housing, items }) {
  const cleanData = items.map(
    ({ employee, foodvendor, start_meal, ...other }) => {
      return {
        ...other,
        foodvendor: foodvendor?.name ?? "",
        start_meal: start_meal?.name ?? "",
        employee_name: employee?.name ?? "-",
      };
    }
  );

  const [openModal, setOpenModal] = useState(false);
  const [currentHistoryID, setCurrentHistoryID] = useState(0);
  const [modalStatus, setModalStatus] = useState("inactive");

  const handleSubmit = async () => {
    if (currentHistoryID === 0) {
      return;
    }
    setModalStatus("active");
    await F.patch(`${URL.changeFoodVendorHistory}${currentHistoryID}/`, {
      status: 0,
    });
    setOpenModal(false);
    setModalStatus("finished");
  };

  return (
    <>
      <Modal
        open={openModal}
        danger
        modalHeading="您確定要取消嗎？"
        modalLabel="取消膳食紀錄"
        primaryButtonText="確認"
        secondaryButtonText="取消"
        loadingStatus={modalStatus}
        onRequestClose={() => setOpenModal(false)}
        onSecondarySubmit={() => setOpenModal(false)}
        onRequestSubmit={handleSubmit}
      />
      <DataTable rows={cleanData} headers={headers}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button
                  renderIcon={Add16}
                  as={Link}
                  to={`/housing/${housing}/createFoodVendorHisotry`}
                >
                  更換膳食供廠商
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()} isSortable>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      isSortable={header.key !== "actions"}
                    >
                      {header.name}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      const id = cell.id.split(":")[0];
                      let content;
                      switch (header) {
                        case "start_date":
                        case "created":
                        case "modified":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "room":
                        case "employee_name":
                        case "foodvendor":
                        case "start_meal":
                          content = cell.value;
                          break;
                        case "actions":
                          content = (
                            <div>
                              <Button
                                kind="ghost"
                                renderIcon={Edit20}
                                iconDescription="編輯"
                                as={Link}
                                to={`/housing/${housing}/foodVendorHisotry/${id}/edit`}
                              />
                              <Button
                                kind="ghost"
                                renderIcon={EditOff20}
                                iconDescription="取消"
                                onFocus={() => setCurrentHistoryID(id)}
                                onClick={() => setOpenModal(true)}
                              />
                            </div>
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
