import { useState } from "react";
import { Dropdown, DropdownSkeleton } from "carbon-components-react";
import { Label } from "../Label";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../../api";

export const Employee = ({
  id,
  className,
  style,
  filter = {},
  field = {},
  data,
  onChange,
}) => {
  const { center, department } = filter;
  const { key, required = false, ui } = field;
  const { disabled = false } = ui;
  const { type = "number", title } = field.schema;
  var eTitle = title;
  if (key === "service_employee_id") {
    eTitle = "服務人員";
  }

  const [shouldValidate, setShouldValidate] = useState(false);
  const invalid = shouldValidate && required && data === undefined;

  const params = new URLSearchParams();
  params.append("fields", "id,name");
  params.append("status", 1);
  params.append("center", center?.id ?? "");
  if (department !== undefined) {
    department.split(",").forEach((x) => {
      params.append("department", x);
    });
  }
  const url = `${URL.staff}?${params.toString()}`;
  const { data: employeeList } = useSWR([url], F.withToken);
  const list = employeeList ?? [];
  const item = (list || []).find((x) => x.id === data);

  return !list ? (
    <DropdownSkeleton id={id} className={className} style={style} />
  ) : (
    <Dropdown
      id={`${id || "employee"}__dropdown`}
      className={className}
      style={style}
      titleText={<Label required={required}>{eTitle}</Label>}
      label="請選擇"
      items={list}
      itemToString={(x) => x.name}
      selectedItem={item}
      invalid={invalid}
      disabled={disabled}
      invalidText="此為必填欄位"
      onFocus={() => setShouldValidate(false)}
      onBlur={() => setShouldValidate(true)}
      onChange={(e) => {
        let v = e.selectedItem.id;
        if (type === "number") {
          v = +v;
        }
        if (typeof onChange !== "function") return;
        onChange({ [key]: v });
      }}
    />
  );
};
