import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import { useQuery } from "../../hooks";

import { SelectPuerpery } from "../Puerpera/SelectPuerpery";

export const CustomerBookVisitSelectPuerpery = () => {
  const navigate = useNavigate();
  const q = useQuery();
  let cbvId = q.get("cbv");

  const url = `${URL.bookVisit.customer}${cbvId}/`;
  const { data, error, isValidating } = useSWR([url], F.withToken);

  const [searchValue, setSearchValue] = useState("");

  const hadData = !isValidating && !error && data;
  useEffect(() => {
    if (!hadData) {
      return;
    }
    const puerpera_status = data["puerpera_status"];
    if (puerpera_status !== "" && puerpera_status !== "正常") {
      if (puerpera_status === "姓名") {
        setSearchValue(data["contract_name"]);
      } else if (puerpera_status === "電話") {
        setSearchValue(data["contract_phone"]);
      }
    }
  }, [hadData, data]);

  return (
    <SelectPuerpery
      isCustomerBookVisit={true}
      searchString={searchValue}
      hadDefautSearchValue={true}
      targetBlock={(pid) =>
        navigate(`/bookvisit/create?puerpera=${pid}&cbv=${cbvId}`)
      }
      actionButtonTitle="新增預約參訪"
      actionButtonLink={`/bookvisit/create?cbv=${cbvId}`}
    />
  );
};
