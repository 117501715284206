import PropTypes from "prop-types";
import CherieHeader from "./CherieHeader";

CherieHeader.propTypes = {
  user: PropTypes.shape({}),
};

CherieHeader.defaultProps = {
  user: null,
};

export default CherieHeader;
