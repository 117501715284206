import { useState } from "react";
import { Dropdown, DropdownSkeleton } from "carbon-components-react";
import { Label } from "../Label";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../../api";

export const VisitSchedule = ({
  id,
  className,
  style,
  filter = {},
  field = {},
  data,
  onChange,
}) => {
  const { center } = filter;
  const { key, required = false } = field;
  const title = "預約時段";

  const [shouldValidate, setShouldValidate] = useState(false);
  const invalid = shouldValidate && required && data === undefined;

  // Must had center.id, no id use first one
  const url = `${URL.center}${center?.id ?? "1"}`;
  const { data: cc } = useSWR([url], F.withToken);
  const vsDict = cc?.visit_schedules.items ?? {};
  // use filter to clean empty item
  const list = Object.keys(vsDict)
    .map((x) => vsDict[x])
    .filter((x) => x);
  const item = (list || []).find((x) => x === data);

  return !list ? (
    <DropdownSkeleton id={id} className={className} style={style} />
  ) : (
    <Dropdown
      id={id}
      className={className}
      style={style}
      titleText={<Label required={required}>{title}</Label>}
      label="請選擇"
      items={list}
      itemToString={(x) => x}
      selectedItem={item}
      invalid={invalid}
      invalidText="此為必填欄位"
      onFocus={() => setShouldValidate(false)}
      onBlur={() => setShouldValidate(true)}
      onChange={(e) => {
        const v = e.selectedItem;
        if (typeof onChange !== "function") return;
        onChange({ [key]: v });
      }}
    />
  );
};
