import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Fetcher as F } from "../../api";

export const UserInfo = () => {
  const params = useParams();

  const { data, error } = useSWR(
    `${window.django.urls.api}/users/${params.uid}/`,
    F.simple
  );

  console.log(data, error);

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading</div>;

  return (
    <div>
      <span>This is {params.uid}</span>
    </div>
  );
};
