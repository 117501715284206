import { Component } from "react";
// import * as Widget from "./Widget";
import styled from "styled-components";

import { Item } from "./Item";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 32px;
`;

export class SchemaViewer extends Component {
  constructor(props) {
    super(props);
    this._handleChange = this._handleChange.bind(this);
  }

  _handleChange(obj) {
    const { fields = [], data, onChange } = this.props;
    if (typeof onChange !== "function") return;
    const newData = {
      ...data,
      ...obj,
    };
    let result = {};
    for (let field of fields) {
      const v = newData[field.key];
      if (v !== undefined) result[field.key] = v;
    }
    onChange(result);
  }

  render() {
    const {
      id,
      className,
      style,
      fields = [],
      isLoading = false,
      excludes = [],
      data = {},
    } = this.props;
    let flds = [];

    let commentObj = fields.find((f) => f.key === "comment");

    for (let field of fields) {
      if (excludes.includes(field.key)) continue;
      // Ignore comment
      if (commentObj && field.key === "comment") continue;
      flds.push(field);
    }
    // Push at latest
    if (commentObj && excludes.includes("comment") === false) {
      flds.push(commentObj);
    }

    return (
      <Container id={id} className={className} style={style}>
        {flds.map((field) => {
          const key = `${id}_${field.key}`;
          const Comp = findComponentForField(field);
          /* const Comp = comp(field); */

          let _data = data && data[field.key];

          const valuePath = field.ui["ui:valuepath"];
          if (
            valuePath &&
            _data &&
            _data.constructor === Object &&
            valuePath in _data
          ) {
            _data = _data[valuePath];
          }
          const rowspan = field.ui["ui:rowspan"] ?? 0;
          const colspan = field.ui["ui:colspan"] ?? 0;
          let style = {};
          if (field.ui["style"]) {
            style = field.ui["style"];
          }
          if (rowspan) {
            style["grid-row-end"] = `span ${rowspan}`;
          }
          if (colspan) {
            style[`grid-column-end`] = `span ${colspan}`;
          }
          return (
            <Comp
              key={key}
              id={key}
              style={style}
              field={field}
              data={_data}
              onChange={this._handleChange}
              loading={isLoading}
            />
          );
        })}
      </Container>
    );
  }
}

function DefaultItem({
  id,
  className,
  style,
  field,
  loading = false,
  data = "",
}) {
  const { title } = field.schema;

  return (
    <Item
      id={id}
      className={className}
      style={style}
      loading={loading}
      labelText={title}
    >
      {data}
    </Item>
  );
}

function BooleanItem({
  id,
  className,
  style,
  field,
  loading = false,
  data = false,
}) {
  const { title } = field.schema;

  const value = data ? "是" : "否";

  return (
    <Item
      id={id}
      className={className}
      style={style}
      loading={loading}
      labelText={title}
    >
      {value}
    </Item>
  );
}

function GenderItem({
  id,
  className,
  style,
  field,
  loading = false,
  data = false,
}) {
  const { title } = field.schema;

  function genderText(gender) {
    if (gender === "M") return "男";
    if (gender === "F") return "女";
    return "不明";
  }
  const value = genderText(data);

  return (
    <Item
      id={id}
      className={className}
      style={style}
      loading={loading}
      labelText={title}
    >
      {value}
    </Item>
  );
}

function ManyToManyItem({
  id,
  className,
  style,
  field,
  loading = false,
  data = "",
}) {
  const { keypath = "name" } = field;
  const { title } = field.schema;

  const value = Array.isArray(data)
    ? data.map((x) => x[keypath]).join(", ")
    : "";

  return (
    <Item
      id={id}
      className={className}
      style={style}
      loading={loading}
      labelText={title}
    >
      {value}
    </Item>
  );
}

function findComponentForField(field) {
  let widget = field.ui["ui:display"] ?? "_";
  switch (widget) {
    case "m2m":
      return ManyToManyItem;
    case "boolean":
      return BooleanItem;
    case "gender":
      return GenderItem;
    default:
      return DefaultItem;
  }
}
