import styled from "styled-components";
import { TextArea } from "carbon-components-react";

const Container = styled.div`
  position: relative;
`;

const Counter = styled.div.attrs((props) => ({ className: "bx--label" }))`
  position: absolute;
  top: 0;
  right: 0;
`;

const Count = styled.span`
  color: ${(props) => (props.overflow ? "#da1e28" : "inherit")};
`;

export const NoteArea = (props) => {
  const { id, className, style, value } = props;
  const length = value?.length ?? 0;
  const max = 100;

  return (
    <Container id={id} className={className} style={style}>
      <Counter>
        <Count overflow={length > max}>{length}</Count>/{max}
      </Counter>
      <TextArea {...props} />
    </Container>
  );
};
