import { CheckboxList } from "./CheckboxList";

export const MedicalHistory = ({
  id,
  className,
  style,
  field = {},
  data,
  onChange,
}) => {
  const { schema, ...o } = field;
  const fixSchema = { ...schema, api: "medicalHistory" };
  const fixField = { ...o, schema: fixSchema };

  return (
    <CheckboxList
      id={id}
      className={className}
      style={style}
      field={fixField}
      data={data}
      onChange={onChange}
    />
  );
};
