import React from "react";
import { Link } from "react-router-dom";

export const UserList = () => (
  <ul>
    <li>
      <Link to="/users/superbil">superbil</Link>
    </li>
  </ul>
);
