import { Column, Link, Row, Grid } from "carbon-components-react";
import styled from "styled-components";

import { Comment } from "./Comment";
import { Item } from "./Item";
import { PuerperaRow } from "./PuerperaRow";

const EventItem = styled.div`
  margin: 24px;
`;

export const HousingViewer = ({ loading = false, data = {} }) => {
  const {
    comment = "",
    care_comment = "",
    events = [],
    bookRoom = {
      latest_brs: {},
      fisrt_brs: {},
    },
    foodvendor = {},
    cost_postpartum = "",
    cost_cyuanya = "",
    notice = false,
    puerperaNote = {
      father_name: "",
      father_phone: "",
      father_mobilephone: "",
    },
    food_allergies = "",
  } = data;

  return (
    <>
      <PuerperaRow data={bookRoom.puerpera} loading={loading} />

      <Grid>
        <Row>
          <Column>
            <Item labelText="館別" loading={loading}>
              {bookRoom.latest_brs?.room?.center.name ?? ""}
            </Item>
          </Column>
          <Column>
            <Item labelText="房間" loading={loading}>
              {bookRoom.latest_brs?.room?.name ?? ""}
            </Item>
          </Column>
          <Column>
            <Item labelText="房型" loading={loading}>
              {bookRoom.latest_brs?.room?.roomType?.name ?? ""}
            </Item>
          </Column>
        </Row>

        <Row>
          <Column>
            <Item labelText="住房起始" loading={loading}>
              <Link href={`/bookroom/${bookRoom.id}`}>
                {bookRoom.first_brs?.checkin_date ?? ""}
              </Link>
            </Item>
          </Column>
          <Column>
            <Item labelText="住房結束" loading={loading}>
              {bookRoom.latest_brs?.checkout_date ?? ""}
            </Item>
          </Column>
          <Column>
            <Item labelText="合約編號" loading={loading}>
              {bookRoom?.contract?.contract_number ?? ""}
            </Item>
          </Column>
        </Row>

        {/* <Row> */}
        {/*   <Column> */}
        {/*     <Item labelText="新生兒生日" loading={loading}></Item> */}
        {/*   </Column> */}
        {/*   <Column> */}
        {/*     <Item labelText="性別" loading={loading}></Item> */}
        {/*   </Column> */}
        {/*   <Column> */}
        {/*     <Item labelText="床號" loading={loading}></Item> */}
        {/*   </Column> */}
        {/* </Row> */}

        <Row>
          <Column>
            <Item labelText="老公名子" loading={loading}>
              {puerperaNote?.father_name ?? ""}
            </Item>
          </Column>
          <Column>
            <Item labelText="老公電話" loading={loading}>
              {puerperaNote?.father_phone ?? ""}
            </Item>
          </Column>
          <Column>
            <Item labelText="老公手機" loading={loading}>
              {puerperaNote?.father_mobilephone ?? ""}
            </Item>
          </Column>
        </Row>

        <Row>
          <Column>
            <Item labelText="膳食廠商" loading={loading}>
              {foodvendor?.name ?? ""}
            </Item>
          </Column>
          <Column>
            <Item labelText="飲食禁忌" loading={loading}>
              {puerperaNote?.mom_dietary_taboos ?? ""}
            </Item>
          </Column>
          <Column>
            <Item labelText="食物過敏" loading={loading}>
              {food_allergies}
            </Item>
          </Column>
        </Row>

        <Row>
          <Column>
            <Item labelText="特別注意" loading={loading}>
              {notice ? "是" : "否"}
            </Item>
          </Column>
          <Column>
            <Item labelText="產後費用" loading={loading}>
              {cost_postpartum}
            </Item>
          </Column>
          <Column>
            <Item labelText="全亞費用" loading={loading}>
              {cost_cyuanya}
            </Item>
          </Column>
        </Row>
      </Grid>

      {events.map((e) => (
        <EventItem>
          <Grid>
            <Row>
              <Column sm={0.5}>
                <Item loading={loading}>{e.deadline_date}</Item>
              </Column>
              <Column>
                <Item loading={loading}>{e.name}</Item>
              </Column>
              <Column>
                <Item loading={loading}>{e.status ? "完成" : "未完成"}</Item>
              </Column>
            </Row>
          </Grid>
        </EventItem>
      ))}

      <Grid>
        <Row>
          <Column>
            <Comment labelText="備註" loading={loading}>
              {comment || "無"}
            </Comment>
          </Column>
          <Column>
            <Comment labelText="電訪備註" loading={loading}>
              {care_comment || "無"}
            </Comment>
          </Column>
        </Row>
      </Grid>
    </>
  );
};
