import { useMemo, useContext } from "react";
import useSWR from "swr";
import { URL, Fetcher as F } from "../api";
import { DepartmentsContext, CentersContext } from "../context";
import { denormalizeStaff } from "../utils";

export const useStaff = (id) => {
  const { list: centers } = useContext(CentersContext);
  const { list: departments } = useContext(DepartmentsContext);
  const staffURL = `${URL.staff}${id}/`;
  const { data: rawStaff } = useSWR([staffURL], F.withToken);

  const staff = useMemo(
    () => denormalizeStaff(centers, departments, rawStaff),
    [rawStaff, centers, departments]
  );

  return staff;
};
