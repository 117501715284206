import { useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Grid,
  Pagination,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
} from "carbon-components-react";
import { Add16, AddFilled20, Catalog20, Search16 } from "@carbon/icons-react";

import dayjs from "dayjs";
import useSWR from "swr";

import { DateTimeFormat } from "../../utils/datetime";
import { UserContext, CentersContext, StatusContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import { hasId } from "../../utils";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

const headers = [
  {
    key: "note_first_contact_date",
    name: "首觸日",
  },
  {
    key: "name",
    name: "顧客/產婦",
  },
  {
    key: "mobile_phone",
    name: "電話",
  },
  {
    key: "note_edc",
    name: "預產期",
  },
  {
    key: "book_date",
    name: "預約日期",
  },
  {
    key: "centers",
    name: "參觀館別",
  },
  {
    key: "note_fetus",
    name: "胎次",
  },
  {
    key: "service_employee",
    name: "服務人員",
  },
  {
    key: "status",
    name: "狀態",
  },
  {
    key: "actions",
    name: "參訪",
  },
];

const createQueryString = ({
  offset,
  pageSize,
  sinceBookDate,
  untilBookDate,
  sinceFirstContactDate,
  untilFirstContactDate,
  sinceEDC,
  untilEDC,
  customerOrPuerperaKeyword,
  center,
  status,
  servicerName,
}) => {
  const params = new URLSearchParams();
  params.append(
    "expand",
    "centers,customer,service_employee,status,note.puerpera"
  );
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (pageSize !== undefined) {
    params.append("limit", pageSize);
  }
  if (sinceBookDate !== undefined) {
    params.append(
      "sinceBookDate",
      dayjs(sinceBookDate).format(DateTimeFormat.date)
    );
  }
  if (untilBookDate !== undefined) {
    params.append(
      "untilBookDate",
      dayjs(untilBookDate).format(DateTimeFormat.date)
    );
  }
  if (sinceFirstContactDate !== undefined) {
    params.append(
      "since_first_contact_date",
      dayjs(sinceFirstContactDate).format(DateTimeFormat.date)
    );
  }
  if (untilFirstContactDate !== undefined) {
    params.append(
      "until_first_contact_date",
      dayjs(untilFirstContactDate).format(DateTimeFormat.date)
    );
  }
  if (sinceEDC !== undefined) {
    params.append("since_edc", dayjs(sinceEDC).format(DateTimeFormat.date));
  }
  if (untilEDC !== undefined) {
    params.append("until_edc", dayjs(untilEDC).format(DateTimeFormat.date));
  }
  if (center !== undefined) {
    const cid = center.id;
    if (cid !== 0) {
      params.append("center", cid);
    }
  }
  if (status !== undefined) {
    params.append("status", status.id);
  }
  if (customerOrPuerperaKeyword !== undefined) {
    params.append("search", customerOrPuerperaKeyword);
  }
  if (servicerName !== undefined) {
    params.append("service", servicerName);
  }
  return params.toString();
};

export const BookVisitSearch = () => {
  const {
    token: { access },
  } = useContext(UserContext);
  const { list: centerList } = useContext(CentersContext);

  const { bookVisitStatus } = useContext(StatusContext);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const offset = (page - 1) * pageSize;

  const [sinceBookDate, setSinceBookDate] = useState();
  const [untilBookDate, setUntilBookDate] = useState();
  const [sinceFirstContactDate, setSinceFirstContactDate] = useState();
  const [untilFirstContactDate, setUntilFirstContactDate] = useState();
  const [sinceEDC, setSinceEDC] = useState();
  const [untilEDC, setUntilEDC] = useState();
  const [center, setCenter] = useState();
  const [status, setStatus] = useState();
  const [customerOrPuerperaKeyword, setCustomerOrPuerperaKeyword] = useState();
  const [servicerName, setServicerName] = useState();

  const queryString = useMemo(
    () =>
      createQueryString({
        offset,
        pageSize,
        sinceBookDate,
        untilBookDate,
        sinceFirstContactDate,
        untilFirstContactDate,
        sinceEDC,
        untilEDC,
        customerOrPuerperaKeyword,
        center,
        status,
        servicerName,
      }),
    [
      offset,
      pageSize,
      sinceBookDate,
      untilBookDate,
      sinceFirstContactDate,
      untilFirstContactDate,
      sinceEDC,
      untilEDC,
      customerOrPuerperaKeyword,
      center,
      status,
      servicerName,
    ]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const bookVisitURL = `${URL.bookVisit.list}?${realyQuery}`;
  const { data } = useSWR(access && [bookVisitURL], F.withToken);

  const isLoading = !data;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/bookvisit">預約參訪</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          搜尋顧客
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>
        <Grid>
          <Row>
            <Column md={1}>
              <Dropdown
                id="center-selector"
                titleText="館別"
                label="全部"
                items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
                itemToString={(item) => item.name}
                selectedItem={center}
                onChange={(v) => {
                  setCenter(v.selectedItem);
                }}
              />
            </Column>
            <Column>
              <DatePicker
                dateFormat="Y/m/d"
                datePickerType="range"
                onChange={([startDate, endDate]) => {
                  setSinceBookDate(startDate);
                  setUntilBookDate(endDate);
                }}
              >
                <DatePickerInput
                  id="book-visit__search__startDate"
                  placeholder="yyyy/mm/dd"
                  labelText="起始預約日期"
                />
                <DatePickerInput
                  id="book-visit__search__endDate"
                  placeholder="yyyy/mm/dd"
                  labelText="結束預約日期"
                />
              </DatePicker>
            </Column>
            <Column md={1}>
              <Dropdown
                id="status-selector"
                titleText="狀態"
                label=""
                items={bookVisitStatus}
                itemToString={(item) => item.name}
                selectedItem={status}
                onChange={(v) => {
                  setStatus(v.selectedItem);
                }}
              />
            </Column>
            <Column>
              <DatePicker
                dateFormat="Y/m/d"
                datePickerType="range"
                onChange={([startDate, endDate]) => {
                  setSinceFirstContactDate(startDate);
                  setUntilFirstContactDate(endDate);
                }}
              >
                <DatePickerInput
                  id="book-visit__note_first_contact_date__start"
                  placeholder="yyyy/mm/dd"
                  labelText="起始首觸日"
                />
                <DatePickerInput
                  id="book-visit__note_first_contact_date__end"
                  placeholder="yyyy/mm/dd"
                  labelText="結束首觸日"
                />
              </DatePicker>
            </Column>
            <Column>
              <DatePicker
                dateFormat="Y/m/d"
                datePickerType="range"
                onChange={([startDate, endDate]) => {
                  setSinceEDC(startDate);
                  setUntilEDC(endDate);
                }}
              >
                <DatePickerInput
                  id="book-visit__note__edc_since"
                  placeholder="yyyy/mm/dd"
                  labelText="起始預產期"
                />
                <DatePickerInput
                  id="book-visit__note__edc_until"
                  placeholder="yyyy/mm/dd"
                  labelText="結束起始預產期"
                />
              </DatePicker>
            </Column>
            <Column md={1}>
              <Search
                size="lg"
                labelText="搜尋顧客或產婦"
                placeholder="姓名、電話"
                onChange={(e) => {
                  setCustomerOrPuerperaKeyword(e.target.value);
                }}
              />
            </Column>
            <Column md={1}>
              <Search
                size="lg"
                labelText="搜尋服務人員"
                placeholder="服務人員姓名"
                onChange={(e) => {
                  setServicerName(e.target.value);
                }}
              />
            </Column>
            <Column>
              <Button
                renderIcon={Search16}
                iconDescription="搜尋"
                hasIconOnly
                onClick={() => {
                  setPage(1);
                  const queryString = createQueryString({
                    sinceBookDate,
                    untilBookDate,
                    sinceFirstContactDate,
                    untilFirstContactDate,
                    sinceEDC,
                    untilEDC,
                    customerOrPuerperaKeyword,
                    center,
                    offset: 0,
                    pageSize,
                    status,
                    servicerName,
                  });
                  setRealyQuery(queryString);
                }}
              ></Button>
            </Column>
          </Row>
        </Grid>
      </Tile>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <CustomerTable
          page={page}
          offset={offset}
          pageSize={pageSize}
          customers={data}
          onPageChange={({ page, pageSize }) => {
            const offset = (page - 1) * pageSize;
            setPage(page);
            setPageSize(pageSize);
            const queryString = createQueryString({
              offset: offset,
              pageSize,
              sinceBookDate,
              untilBookDate,
              sinceFirstContactDate,
              untilFirstContactDate,
              sinceEDC,
              untilEDC,
              customerOrPuerperaKeyword,
              center,
              status,
              servicerName,
            });
            setRealyQuery(queryString);
          }}
        />
      )}
    </Content>
  );
};

function CustomerTable({ page, pageSize, customers, onPageChange }) {
  const total = (customers && customers.count) || 0;
  const data = (customers && customers.results) || [];

  const cleanData = data.map(
    ({ customer, centers, service_employee, note, status, ...other }) => {
      let name = "";
      let phone = "";
      let puperpera_id = null;
      if (customer) {
        name = customer.name;
        phone = customer.mobile_phone;
      } else if (note && note.puerpera) {
        name = note.puerpera.name;
        phone = note.puerpera.mobile_phone;
        puperpera_id = note.puerpera.id;
      }
      return {
        ...other,
        name: name,
        mobile_phone: phone,
        service_employee: service_employee?.name ?? "",
        centers: centers.map((c) => c.name).toString(),
        status: status?.name ?? "",
        note_edc: note?.edc,
        note_first_contact_date: note?.first_contact_date,
        note_fetus: note?.fetus ?? "",
        puperpera_id: puperpera_id,
      };
    }
  );

  const pageProps = {
    disabled: !customers,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  return (
    <DataTable rows={cleanData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button renderIcon={Add16} as={Link} to="/bookvisit/create">
                新增預約參訪
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    key={header.key}
                    {...getHeaderProps({ header })}
                    isSortable={header.key !== "actions"}
                  >
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const rowData = cleanData.find(hasId(row.id));
                return (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      const bvId = cell.id.split(":")[0];
                      let content;
                      let puperperaURL = null;
                      if (rowData?.puperpera_id) {
                        puperperaURL = `/puerpera/${rowData?.puperpera_id}/info`;
                      }

                      switch (header) {
                        case "note_first_contact_date":
                        case "note_edc":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "book_date":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.datetime
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "name":
                          if (puperperaURL) {
                            content = (
                              <Link to={puperperaURL}>{cell.value}</Link>
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "mobile_phone":
                        case "service_employee":
                        case "status":
                        case "centers":
                        case "note_fetus":
                          content = cell.value;
                          break;
                        case "actions":
                          content = (
                            <div>
                              <Button
                                kind="ghost"
                                renderIcon={Catalog20}
                                iconDescription="瀏覽預約參觀"
                                as={Link}
                                to={`/bookvisit/${bvId}`}
                              />
                              <Button
                                kind="ghost"
                                renderIcon={AddFilled20}
                                iconDescription="新增預約參觀時程"
                                as={Link}
                                to={`/schedule/${bvId}/create`}
                              />
                            </div>
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
