import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  TextInputSkeleton,
  Tile,
} from "carbon-components-react";
import { Save16 } from "@carbon/icons-react";
import { PuerperaRow } from "../../components/Viewer";
import { SchemaEditor } from "../../components/Editor";
import { SchemaContext } from "../../context";
import { useUserData } from "../../hooks";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import * as S from "../../utils/schema";

const EditorWrapper = styled.div`
  margin: 16px;
`;

const EventRowWrapper = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 24px;
`;

export const HousingCreateOrEdit = () => {
  const { hid } = useParams();

  const navigate = useNavigate();
  const user = useUserData();

  const { housing: housingSchema } = useContext(SchemaContext) || {};

  const housingURL = `${URL.housing}${hid}/?expand=events,bookRoom.puerpera,bookRoom.latest_brs.room.center`;
  const { data: housingData } = useSWR([housingURL], F.withToken);

  const [housing, setHousing] = useState({});
  const [housingEventList, setHousingEventList] = useState([]);

  useEffect(() => {
    if (!housingData) return;
    const { evnets, ...o } = housingData;
    setHousing(o);
    setHousingEventList(housingData.events);
  }, [setHousingEventList, setHousing, housingData]);

  const isHousingReady = S.createSimpleValidator(housingSchema);
  const isReady = useMemo(
    () => isHousingReady(housingData),
    [isHousingReady, housingData]
  );

  const puerperaData = housingData?.bookRoom?.puerpera;
  const isLoadingPuerpera = !hid && !puerperaData;

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;
    const {
      id,
      employee_id,
      comment,
      care_comment,
      cost_postpartum,
      cost_cyuanya,
      notice,
      foodvendor_id,
    } = housing;
    let payload = {
      employee_id,
      comment,
      care_comment,
      cost_postpartum,
      cost_cyuanya,
      notice,
      foodvendor_id,
      events: housingEventList,
    };
    // console.debug(housing, payload);

    const r = await F.put(`${URL.housing}${id}/`, payload);
    // console.debug(r.id);

    navigate(`/housing/${r.id}`);
  }, [navigate, housing, housingEventList, isReady]);

  const handleUpdate = useCallback(
    (i) => (s) => {
      setHousingEventList((ss) => {
        return A.replace(ss, { ...ss[i], ...s }, i);
      });
    },
    [setHousingEventList]
  );

  const center = housing?.bookRoom?.latest_brs?.room?.center;

  return (
    <Content className="housing-create">
      <Breadcrumb>
        <BreadcrumbItem href="/housing">住房管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          新增/編輯
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>新增/編輯住房狀態</Tile>

      <PuerperaRow
        showPuerperaLink={true}
        data={puerperaData}
        loading={isLoadingPuerpera}
      />

      <Grid>
        <Column>
          <EditorWrapper>
            <SchemaEditor
              fields={housingSchema.fields}
              filter={{ center: center }}
              excludes={[
                "id",
                "employee_id",
                "created",
                "modified",
                "employee",
                "puerpera",
                "puerpera_id",
                "events",
                "bookRoom",
                "fetus",
                "puerperaNote",
                "today_brs",
                "food_allergies",
              ]}
              data={housing}
              onChange={(x) => {
                if (!user) return;
                setHousing({ ...x, employee_id: user.employee_id });
              }}
            />
          </EditorWrapper>
        </Column>

        <Grid>
          <Column>
            {housingEventList ? (
              housingEventList.map((s, i) => {
                const sid = s.id !== undefined ? s.id : s._id;
                return (
                  <EventRowWrapper>
                    <EventRow
                      key={sid}
                      id={sid}
                      event={s}
                      parentID={housingData?.id}
                      onChange={handleUpdate(i)}
                    />
                  </EventRowWrapper>
                );
              })
            ) : (
              <TextInputSkeleton />
            )}
          </Column>
        </Grid>

        <Column>
          <Grid>
            <Row>
              <Column>
                <Button
                  disabled={!isReady}
                  kind="primary"
                  size="small"
                  renderIcon={Save16}
                  onClick={handleSubmit}
                >
                  儲存
                </Button>
              </Column>
            </Row>
          </Grid>
        </Column>
      </Grid>
    </Content>
  );
};

const EventRow = ({ id, event, parentID, onChange, onRemoveClick }) => {
  const { housingEvent: heSchema } = useContext(SchemaContext) || {};
  const user = useUserData();

  const handleChangeEvent = useCallback(
    (s) => {
      if (typeof onChange !== "function") return;
      onChange(s);
    },
    [onChange]
  );

  return (
    <Grid>
      <Column>
        <EditorWrapper>
          <SchemaEditor
            id={`editor_${id}`}
            fields={heSchema.fields}
            excludes={[
              "id",
              "housing",
              "housing_id",
              "employee",
              "employee_id",
              "created",
              "modified",
            ]}
            data={event}
            onChange={(x) => {
              if (!user) return;
              handleChangeEvent({
                ...x,
                employee_id: user.employee_id,
              });
            }}
          />
        </EditorWrapper>
      </Column>
    </Grid>
  );
};
