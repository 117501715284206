import { useMemo } from "react";
import { FormGroup, Checkbox, CheckboxSkeleton } from "carbon-components-react";
import { Label } from "../Label";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../../api";

export const CheckboxList = ({
  id,
  className,
  style,
  field = {},
  data = [],
  filter,
  onChange,
}) => {
  const { key, required = false } = field;
  const { title, api } = field.schema;
  const { center } = filter ?? {};

  let url = URL[api];
  if (center !== undefined && center?.id !== undefined) {
    url = `${url}?center=${center?.id ?? ""}`;
  }

  const { data: list } = useSWR([url], F.withToken);
  const dataMap = useMemo(() => {
    let obj = {};
    if (!list) return obj;

    for (let b of list) {
      obj[b.id] = false;
    }
    if (data !== null) {
      for (let id of data) {
        obj[id] = true;
      }
    }
    return obj;
  }, [list, data]);

  const isLoading = !list;

  return (
    <FormGroup
      id={id}
      className={className}
      style={style}
      legendText={<Label required={required}>{title}</Label>}
    >
      {isLoading ? (
        <>
          <CheckboxSkeleton />
          <CheckboxSkeleton />
          <CheckboxSkeleton />
        </>
      ) : (
        list.map(({ id: eid, name: ename }) => {
          const itemID = `${id}_${eid}`;
          return (
            <Checkbox
              key={itemID}
              id={itemID}
              className={className}
              labelText={`${ename}`}
              checked={dataMap[eid]}
              onChange={(value) => {
                const newMap = { ...dataMap, [eid]: value };
                let checkedList = [];
                for (let b of list) {
                  if (newMap[b.id]) checkedList.push(b.id);
                }
                if (typeof onChange !== "function") return;
                onChange({ [key]: checkedList });
              }}
            />
          );
        })
      )}
    </FormGroup>
  );
};
