import { useContext, useMemo } from "react";
import { FormGroup, Checkbox } from "carbon-components-react";
import { Label } from "../Label";
import { CentersContext } from "../../../context";

export const MultiCenter = ({
  id,
  className,
  style,
  field = {},
  data = [],
  onChange,
}) => {
  const { list: centerList = [] } = useContext(CentersContext);
  const centerMap = useMemo(() => {
    let obj = {};
    for (let c of centerList) {
      obj[c.id] = false;
    }
    for (let c of data) {
      obj[c] = true;
    }
    return obj;
  }, [centerList, data]);

  const { key, required = false } = field;
  const { title } = field.schema;

  return (
    <FormGroup
      id={id}
      className={className}
      style={style}
      legendText={<Label required={required}>{title}</Label>}
    >
      {centerList.map(({ id: cid, name: cname }) => (
        <Checkbox
          key={`${cid}`}
          id={`${cid}`}
          labelText={cname}
          checked={centerMap[cid]}
          onChange={(value) => {
            const newMap = { ...centerMap, [cid]: value };
            let centers = [];
            for (let id in newMap) {
              if (newMap[id]) centers.push(+id);
            }
            if (typeof onChange !== "function") return;
            onChange({ [key]: centers });
          }}
        />
      ))}
    </FormGroup>
  );
};
