import { useContext } from "react";
import useSWR from "swr";
import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useBookVisitNoteList = (filter = {}) => {
  const {
    token: { access },
  } = useContext(UserContext);

  const { puerpera } = filter;
  const params = new URLSearchParams();
  if (puerpera) {
    params.append("puerpera", puerpera);
  }
  const bookVisitNoteURL = `${URL.bookVisit.note}?${params.toString()}`;
  const { data: paged } = useSWR(access && [bookVisitNoteURL], F.withToken, {
    revalidateOnFocus: false,
  });

  return paged;
};
