import { Link } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Button,
} from "carbon-components-react";
import { Add16, Catalog20 } from "@carbon/icons-react";

import dayjs from "dayjs";
import { DateTimeFormat } from "../../utils/datetime";

import { usePuerpera, useBookVisitNoteList } from "../../hooks";
import { PuerperaRow } from "../../components/Viewer";

const headers = [
  {
    key: "created",
    header: "建檔日期",
  },
  {
    key: "edc",
    header: "預產期(EDC)",
  },
  {
    key: "fetus",
    header: "胎次",
  },
  {
    key: "birth",
    header: "幾胞胎",
  },
  {
    key: "birth_method",
    header: "生產方式",
  },
  {
    key: "actions",
    header: "瀏覽",
  },
];

export const NoteTab = ({ pid }) => {
  const puerpera = usePuerpera(pid);
  const basePath = `/puerpera/${pid}`;

  const pagedBookVisitNoteList = useBookVisitNoteList({ puerpera: pid });
  const { results: bvList = [] } = pagedBookVisitNoteList ?? [];

  return (
    <div>
      <PuerperaRow showContract={true} data={puerpera ?? {}}>
        <Button renderIcon={Add16} as={Link} to={`${basePath}/noteCreate`}>
          新增相關資訊
        </Button>
      </PuerperaRow>

      <DataTable rows={bvList} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    {...getHeaderProps({ header })}
                    isSortable={header.key !== "actions"}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const type = cell.info.header;
                      let content;
                      switch (type) {
                        case "created":
                        case "modified":
                        case "edc":
                          content = cell.value
                            ? dayjs(cell.value).format(DateTimeFormat.date)
                            : "";
                          break;
                        case "actions":
                          content = (
                            <Button
                              kind="ghost"
                              renderIcon={Catalog20}
                              as={Link}
                              to={`${basePath}/noteInfo/${row.id}`}
                            />
                          );
                          break;
                        default:
                          content = cell.value;
                          break;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </DataTable>
    </div>
  );
};
