function hasValue(v) {
  return v !== undefined && v !== null && v !== "";
}

export function createSimpleValidator(withFields = {}, excludes = []) {
  // prepare an exclude map
  const excludeMap = {};
  for (let key of excludes) {
    excludeMap[key] = true;
  }
  // prepare a list of required keys, only if they are not excluded
  const { fields = [], moreRequriedkeys = [] } = withFields;
  let keys = fields
    .filter((field) => field.required && !excludeMap[field.key])
    .map((field) => field.key);
  const moreKeys = moreRequriedkeys.filter((key) => !excludeMap[key]);
  keys = keys.concat(moreKeys);
  // return a function that checks if all keys have values
  return function (obj) {
    if (!obj) return false;
    return keys.map((key) => obj[key]).every(hasValue);
  };
}

export function createInitialData(withFields = {}) {
  const { fields = [] } = withFields;
  let result = {};
  for (let field of fields) {
    const { default: defaultValue } = field.schema;
    if (defaultValue !== undefined) {
      result[field.key] = defaultValue;
    }
  }
  return result;
}
