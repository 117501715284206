import { useContext, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  Tile,
} from "carbon-components-react";
import { RequestQuote16, Undo16 } from "@carbon/icons-react";
import { RoomTypeContext } from "../../context";
import { RoomViewer } from "../../components/Viewer";
import "./Info.scss";

import useSWR from "swr";

import { CentersContext } from "../../context";
import { URL, Fetcher as F } from "../../api";

export const RoomInfo = () => {
  const { rid } = useParams();
  const roomURL = `${URL.room}${rid}/`;
  const { data = {} } = useSWR([roomURL], F.withToken);

  const { list: roomTypeList = [] } = useContext(RoomTypeContext);
  const roomType = roomTypeList.find((x) => x.id === data.roomType_id);

  const { list: centerList = [] } = useContext(CentersContext) || {};
  const center = centerList.find((x) => x.id === data.center_id);

  const isLoading = !data || !roomType || !center;

  const room = useMemo(
    () => ({
      ...data,
      roomType,
      center,
    }),
    [data, roomType, center]
  );

  return (
    <Content className="room-info">
      <Breadcrumb>
        <BreadcrumbItem href="/">系統管理</BreadcrumbItem>
        <BreadcrumbItem href="/room">房間管理</BreadcrumbItem>
      </Breadcrumb>

      <Tile>瀏覽房間資訊</Tile>

      <Grid>
        <Row>
          <Column className="room-info__content" sm={{ span: 2, offset: 1 }}>
            <RoomViewer loading={isLoading} data={room} />
            <div className="room-info__actions">
              <Button
                as={Link}
                to={`/room/${rid}/edit`}
                kind="primary"
                size="small"
                renderIcon={RequestQuote16}
              >
                編輯
              </Button>
              <Button
                as={Link}
                to="/room/"
                kind="primary"
                size="small"
                renderIcon={Undo16}
              >
                返回房間清單
              </Button>
            </div>
          </Column>
          <Column sm={1} />
        </Row>
      </Grid>
    </Content>
  );
};
