import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  Tile,
} from "carbon-components-react";
import { Add16, Save16 } from "@carbon/icons-react";

import { SchemaContext } from "../../context";
import { SchemaEditor } from "../../components/Editor";
import { SchemaViewer } from "../../components/Viewer";

import dayjs from "dayjs";
import useSWR from "swr";
import { DateTimeFormat } from "../../utils/datetime";
import { useUserData } from "../../hooks";
import { URL, Fetcher as F } from "../../api";
import * as S from "../../utils/schema";

const Container = styled.div`
  background-color: #f4f4f4;
  padding: 32px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

const ButtonWrapper = styled.div`
  margin: 32px;
`;

export const CustomerBookVisitEdit = () => {
  const navigate = useNavigate();
  const user = useUserData();

  const { cid } = useParams();

  const { customerBookVisit: cbvSchema } = useContext(SchemaContext) || {};

  const department = user.bookvisit_schedule_employee_groups ?? "";
  // TODO: Add center filter, upgarde to use same Editor
  const staffParams = new URLSearchParams();
  staffParams.append("fields", "id,name");
  staffParams.append("status", 1);
  if (department !== undefined) {
    department.split(",").forEach((x) => {
      staffParams.append("department", x);
    });
  }
  const staffURL = `${URL.staff}?${staffParams.toString()}`;

  // TODO: use staffList
  // eslint-disable-next-line
  const { data: staffList } = useSWR([staffURL], F.withToken, {
    revalidateOnFocus: false,
  });

  const url = `${URL.bookVisit.customer}${cid}?expand=employee,centers,visit_schedule`;
  const { data: customerBookVisit } = useSWR([url], F.withToken, {
    revalidateOnFocus: false,
  });

  const [cbv, setCBV] = useState({});

  useEffect(() => {
    if (!customerBookVisit) return;
    setCBV(customerBookVisit);
  }, [customerBookVisit]);

  const isBVCReady = S.createSimpleValidator(cbvSchema);
  const isReady = useMemo(
    () => user && isBVCReady(cbv),
    [user, cbv, isBVCReady]
  );

  const handleSubmit = useCallback(
    async (isStoreOnly) => {
      if (!isReady) return;

      let updatedDate = dayjs().format(DateTimeFormat.date);
      // Only update some fields
      let {
        id,
        created,
        modified,
        centers,
        puerpera_status,
        employee,
        visit_schedule,
        ...o
      } = cbv;

      const isNormalBookVisitCustomer = puerpera_status === "正常";

      let payload = {
        ...o,
        updated_date: updatedDate,
        employee: user.employee_id,
      };
      await F.put(`${URL.bookVisit.customer}${id}/`, payload);

      if (isStoreOnly) {
        navigate(`/bookvisit/customer/`);
        return;
      }

      if (isNormalBookVisitCustomer) {
        navigate(`/bookvisit/create?cbv=${id}`);
      } else {
        navigate(`/bookvisit/customer/selectPuerpera?cbv=${id}`);
      }
    },
    [navigate, isReady, cbv, user]
  );

  const titleString = "網路預約狀態處理";
  const editFields = cbvSchema.fields
    .map((x) => x.key)
    .filter((x) => x !== "status");

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/bookvisit">預約參訪</BreadcrumbItem>
        <BreadcrumbItem href="/bookvisit/customer">網路參訪</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          {titleString}
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>{titleString}</Tile>

      <Container>
        <SchemaEditor
          fields={cbvSchema.fields}
          requiredFields={["status", "employee"]}
          excludes={editFields}
          data={cbv}
          onChange={(data) => {
            if (!user) return;
            const { status } = data;
            setCBV({
              ...cbv,
              status: status,
              employee: user.employee_id,
            });
          }}
        />

        <SchemaViewer
          fields={cbvSchema.fields}
          excludes={[
            "id",
            "created",
            "modified",
            "status",
            "employee",
            "updated_date",
            "run_check_status",
          ]}
          isLoading={!cbv}
          data={cbv}
        />
        <Actions>
          <ButtonWrapper>
            <Button
              disabled={!isReady}
              kind="primary"
              size="small"
              renderIcon={Save16}
              onClick={() => handleSubmit(true)}
            >
              儲存
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              disabled={!isReady}
              kind="primary"
              size="small"
              renderIcon={Add16}
              onClick={() => handleSubmit(false)}
            >
              轉換
            </Button>
          </ButtonWrapper>
        </Actions>
      </Container>
    </Content>
  );
};
