import { useState } from "react";
import { EmployeeModal, BabyBedModal } from "../components/Modal";

export const Demo = () => {
  const [openEmployee, setOpenEmployee] = useState(false);
  const [openBabyBed, setOpenBabyBed] = useState(false);

  return (
    <>
      <EmployeeModal
        open={openEmployee}
        onRequestClose={() => setOpenEmployee(false)}
        onRequestSubmit={(employee_id) => {
          console.log(employee_id);
          setOpenEmployee(false);
        }}
      />
      <button onClick={() => setOpenEmployee(true)}>open Employee modal</button>

      <BabyBedModal
        filter={{ center: 1 }}
        open={openBabyBed}
        onRequestClose={() => setOpenBabyBed(false)}
        onRequestSubmit={(babyBeds) => {
          console.log(babyBeds);
          setOpenBabyBed(false);
        }}
      />
      <button onClick={() => setOpenBabyBed(true)}>open BabyBed modal</button>
    </>
  );
};
