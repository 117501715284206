import styled from "styled-components";
import { SkeletonText } from "carbon-components-react";

const Container = styled.div`
  border-top: ${(props) => (props.showTop ? "1px solid #e0e0e0" : "none")};
  padding: 8px 16px;
`;

export const Item = ({
  loading = false,
  labelText = "",
  showTop = true,
  children,
}) => {
  return (
    <Container showTop={showTop}>
      {loading ? (
        <SkeletonText />
      ) : (
        <>
          {labelText && (
            <>
              <label>{labelText}</label>
              <span>：</span>
            </>
          )}
          {children}
        </>
      )}
    </Container>
  );
};
