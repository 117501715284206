import styled from "styled-components";
import { Comment } from "./Comment";
import { Item } from "./Item";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
`;

const Column = styled.div`
  > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const RoomViewer = ({ loading = false, data = {} }) => {
  const {
    center = { name: "" },
    floor = "",
    name = "",
    roomType = { name: "" },
    status = false,
    note = "",
  } = data;
  const statusText = status ? "開放" : "不開放";

  return (
    <Container>
      <Column>
        <Item labelText="館別" loading={loading}>
          {center.name}
        </Item>
        <Item labelText="樓層" loading={loading}>
          {floor}
        </Item>
        <Item labelText="房間名稱" loading={loading}>
          {name}
        </Item>
        <Item labelText="房間類型" loading={loading}>
          {roomType.name}
        </Item>
        <Item labelText="狀態" loading={loading}>
          {statusText}
        </Item>
      </Column>
      <Column>
        <Comment labelText="備註" loading={loading}>
          {note || "無"}
        </Comment>
      </Column>
    </Container>
  );
};
