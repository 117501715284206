import { useCallback } from "react";
import styled from "styled-components";
import {
  Checkbox,
  Dropdown,
  FormGroup,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from "carbon-components-react";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
`;

const Column = styled.div`
  > * {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CustomerEditor = ({
  disabled = false,
  relationshipsEnum = [],
  data = {},
  onChange,
}) => {
  const {
    name = "",
    phone = "",
    mobile_phone = "",
    gender = "M",
    email = "",
    address = "",
    relationship = "",
    myself = false,
  } = data;

  const update = useCallback(
    (obj) => ({
      name,
      phone,
      mobile_phone,
      gender,
      email,
      address,
      myself,
      relationship,
      ...obj,
    }),
    [name, phone, mobile_phone, gender, email, address, myself, relationship]
  );

  return (
    <Container>
      <Column>
        <TextInput
          id="customer__name"
          disabled={disabled}
          labelText="聯絡人姓名"
          placeholder="請輸入聯絡人姓名"
          value={name}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ name: evt.target.value }));
          }}
        />
        <TextInput
          id="customer__mobile_phone"
          disabled={disabled}
          labelText="聯絡人手機"
          placeholder="09xx-xxxxxx"
          value={mobile_phone}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ mobile_phone: evt.target.value }));
          }}
        />
        <TextInput
          id="customer__phone"
          disabled={disabled}
          labelText="聯絡人電話"
          placeholder="0x-xxxxxxxx"
          value={phone}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ phone: evt.target.value }));
          }}
        />
      </Column>
      <Column>
        <FormGroup legendText="聯絡人性別">
          <RadioButtonGroup
            disabled={disabled}
            name="customer__gender"
            valueSelected={gender}
            onChange={(value) => {
              if (typeof onChange !== "function") return;
              onChange(update({ gender: value }));
            }}
          >
            <RadioButton id="customer__gender--male" labelText="男" value="M" />
            <RadioButton
              id="customer__gender--female"
              labelText="女"
              value="F"
            />
          </RadioButtonGroup>
        </FormGroup>
        <Checkbox
          id="customer__myself"
          labelText="是否產婦本人"
          checked={myself}
          onChange={(value) => {
            if (typeof onChange !== "function") return;
            onChange(update({ myself: value }));
          }}
        />
        <Dropdown
          id="customer__relationship"
          disabled={disabled || relationshipsEnum.length === 0}
          titleText="與產婦關係"
          label="請選擇"
          items={relationshipsEnum}
          itemToString={(x) => x}
          selectedItem={relationship}
          onChange={(v) => {
            if (typeof onChange !== "function") return;
            onChange(update({ relationship: v.selectedItem }));
          }}
        />
        <TextInput
          id="customer__email"
          disabled={disabled}
          labelText="E-Mail"
          placeholder="xxx@mail.com"
          value={email}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ email: evt.target.value }));
          }}
        />
        <TextInput
          id="customer__address"
          disabled={disabled}
          labelText="地址"
          placeholder="請輸入聯絡地址"
          value={address}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ address: evt.target.value }));
          }}
        />
      </Column>
    </Container>
  );
};
