import { useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
} from "carbon-components-react";

import { Add16, View16 } from "@carbon/icons-react";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import useSWR from "swr";
import { URL, Fetcher as F } from "../../api";
import { DateTimeFormat } from "../../utils/datetime";

import {
  defaultPageSize,
  defaultPageSizeOptions,
  PuerperaWithBabyViewer,
} from "../../components/Viewer";

dayjs.extend(duration);

const headers = [
  {
    key: "record",
    name: "日期",
  },
  {
    key: "sum_milk_mon",
    name: "母奶",
  },
  {
    key: "sum_milk_formula",
    name: "配方",
  },
  {
    key: "sum_mom_baby_duration",
    name: "親子同室時數",
  },
  {
    key: "count_breast_feeding",
    name: "親餵次數",
  },
  {
    key: "count_urinate",
    name: "小便",
  },
  {
    key: "count_stool",
    name: "大便",
  },
  {
    key: "avg_rr",
    name: "呼吸",
  },
  {
    key: "avg_hr",
    name: "心跳",
  },
  {
    key: "avg_temperature",
    name: "體溫",
  },
  {
    key: "avg_weight",
    name: "體重",
  },
  {
    key: "avg_guts",
    name: "膽色素",
  },
];

const createQueryString = ({ offset, limit, babyID }) => {
  const params = new URLSearchParams();
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  if (babyID !== undefined) {
    params.append("baby", babyID);
  }
  return params.toString();
};

export const BabyStatusSummary = () => {
  const { bid: babyID } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const queryString = useMemo(
    () =>
      createQueryString({
        offset,
        limit,
        babyID,
      }),
    [offset, limit, babyID]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const expand =
    "puerpera,puerperaNote,bookroom.contract," +
    "bookroom.latest_brs.room.center,bookroom.latest_brs.room.roomType";
  const babyURL = `${URL.baby}${babyID}/?expand=${expand}`;
  const { data: babyData } = useSWR([babyURL], F.withToken);

  const babyStatusURL = `${URL.summary.babyStatusRecord}?${realyQuery}`;
  const { data: babyStatusRecordData } = useSWR([babyStatusURL], F.withToken);

  const isLoadingBaby = !babyData;
  const isLoadingStatusRecord = !babyStatusRecordData;

  const title = "新生兒每日護理統計";

  return (
    <Content className="BabyStatusSummary-info">
      <Breadcrumb>
        <BreadcrumbItem href="/care">護理管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          {title}
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>{title}</Tile>

      <Grid>
        <Column>
          <PuerperaWithBabyViewer loading={isLoadingBaby} data={babyData} />

          {isLoadingStatusRecord ? (
            <DataTableSkeleton showHeader={false} />
          ) : (
            <BabyStatusRecordTable
              page={page}
              offset={offset}
              limit={limit}
              babyID={babyID}
              data={babyStatusRecordData}
              onPageChange={({ page, pageSize }) => {
                setPage(page);
                setLimit(pageSize);
                const queryString = createQueryString({
                  offset,
                  limit,
                  babyID,
                });
                setRealyQuery(queryString);
              }}
            />
          )}
        </Column>
      </Grid>
    </Content>
  );
};

function BabyStatusRecordTable({ page, limit, babyID, data, onPageChange }) {
  const pageSize = limit;
  const total = (data && data.count) || 0;
  const _data = (data && data.results) || [];

  const cleanData = _data.map(({ record, ...other }) => {
    return {
      ...other,
      id: `bsr-${record}`,
      record,
    };
  });

  const pageProps = {
    disabled: !data,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  return (
    <DataTable rows={cleanData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button renderIcon={View16} as={Link} to={`/care/${babyID}`}>
                新生兒護理明細
              </Button>
              <Button
                renderIcon={Add16}
                as={Link}
                to={`/care/${babyID}/createBabyStatus`}
              >
                新增新生兒記錄
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const { header = "" } = cell && cell.info;
                    let content;
                    switch (header) {
                      case "record":
                        if (cell.value !== null) {
                          content = dayjs(cell.value).format(
                            DateTimeFormat.date
                          );
                        } else {
                          content = cell.value;
                        }
                        break;
                      case "sum_mom_baby_duration":
                        if (cell.value !== null) {
                          content = dayjs
                            .duration(cell.value * 1000)
                            .format(DateTimeFormat.time);
                        } else {
                          content = cell.value;
                        }
                        break;
                      case "sum_milk_mon":
                      case "sum_milk_formula":
                      case "count_breast_feeding":
                      case "count_urinate":
                      case "count_stool":
                      case "avg_rr":
                      case "avg_hr":
                      case "avg_temperature":
                      case "avg_weight":
                      case "avg_guts":
                        content = cell.value;
                        break;
                      default:
                        content = <span>?</span>;
                        break;
                    }
                    return <TableCell key={cell.id}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
