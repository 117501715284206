import { Link } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Content,
  DataTable,
  DataTableSkeleton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  Pagination,
} from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../api";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

const staffHeaders = [
  {
    key: "center",
    name: "單位",
  },
  {
    key: "department",
    name: "部門",
  },
  {
    key: "name",
    name: "姓名",
  },
  {
    key: "phone",
    name: "行動電話",
  },
  {
    key: "email",
    name: "電子郵件",
  },
  {
    key: "note",
    name: "備註",
  },
];

export const List = () => {
  const url = `${URL.staff}?expand=center,department`;
  const { data: staffs } = useSWR([url], F.withToken);

  const isLoading = !staffs;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="#">系統管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          員工管理
        </BreadcrumbItem>
      </Breadcrumb>

      {isLoading ? (
        <DataTableSkeleton showHeader={true} />
      ) : (
        <StaffTable staffs={staffs} />
      )}
    </Content>
  );
};

function StaffTable({ staffs }) {
  const cleanStaffs = staffs.map(({ center, department, ...other }) => {
    return {
      ...other,
      center: center?.name ?? "",
      department: department?.name ?? "",
    };
  });

  const pageProps = () => ({
    disabled: true,
    page: 1,
    totalItems: staffs.length,
    pagesUnknown: false,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: defaultPageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
  });

  return (
    <DataTable rows={cleanStaffs} headers={staffHeaders}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        onInputChange,
        getTableContainerProps,
      }) => (
        <TableContainer title="員工列表" {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button renderIcon={Add16} as={Link} to="/staff/create">
                新增員工
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    key={header.key}
                    {...getHeaderProps({ header })}
                    isSortable={header.key !== "note"}
                  >
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const { header = "" } = cell && cell.info;
                    var content;
                    switch (header) {
                      case "user":
                      case "center":
                      case "department":
                      case "phone":
                      case "email":
                      case "note":
                        content = cell.value;
                        break;
                      case "name":
                        content = (
                          <Link to={`/staff/${row.id}`}>{cell.value}</Link>
                        );
                        break;
                      default:
                        content = <span>?</span>;
                    }
                    return <TableCell key={cell.id}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination {...pageProps()} />
        </TableContainer>
      )}
    </DataTable>
  );
}
