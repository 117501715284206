import { useContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Column,
  Content,
  FluidForm,
  FormGroup,
  FormLabel,
  Grid,
  InlineLoading,
  InlineNotification,
  TextInput,
  Tile,
} from "carbon-components-react";

import { URL, Fetcher as F } from "../../api";

import { UserContext } from "../../context";
import { useUserData } from "../../hooks";

const PasswordProps = {
  className: "some-class",
  id: "password",
  type: "password",
  pattern: "(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{6,}",
};

export const UserProfile = () => {
  const navigate = useNavigate();
  const user = useUserData();
  const { logout } = useContext(UserContext);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [status, setStatus] = useState("active");
  const [description, setDescription] = useState("建立中...");
  const [message, setMessage] = useState("");
  const [ariaLive, setAriaLive] = useState("off");

  const handleChangePassword = useCallback(async () => {
    setMessage("");
    if (newPassword !== newPasswordConfirm) {
      setMessage("驗證新密碼錯誤");
      setAriaLive("off");
      return;
    }
    if (newPassword === oldPassword) {
      setMessage("新密碼和目前密碼相同");
      setAriaLive("off");
      return;
    }

    setAriaLive("assertive");
    setDescription("更新中…");

    let p;
    try {
      p = await F.put(`${URL.changePassword}${user.employee_id}/`, {
        old_password: oldPassword,
        new_password: newPasswordConfirm,
      });
    } catch (e) {
      // console.debug(e.message);
    } finally {
      // console.debug(`change password response`, p);
    }
    if (p === undefined) {
      setMessage("修改密碼失敗");
      setStatus("active"); // Let user can send again
      setAriaLive("off");
    } else {
      setStatus("finished");
      setDescription("修改密碼成功");
      setAriaLive("off");
    }
  }, [
    newPassword,
    newPasswordConfirm,
    oldPassword,
    setAriaLive,
    setDescription,
    setMessage,
    setStatus,
    user,
  ]);

  return (
    <Content>
      <Tile>個人頁面</Tile>
      <Grid>
        <Column>{user && <p>姓名： {user.name}</p>}</Column>
        <Column sm={{ span: 2 }}>
          <Tile>修改密碼</Tile>
          <FormGroup>
            <FormLabel>
              <ul>你的密碼必須包含至少 8 個字元。</ul>
              <ul>你不能使用常見的密碼。</ul>
              <ul>你的密碼不能完全是數字。</ul>
            </FormLabel>
            <FluidForm>
              <TextInput
                required
                labelText="目前密碼"
                {...PasswordProps}
                onChange={(e) => setOldPassword(e.target.value)}
              />

              <TextInput
                required
                labelText="新密碼"
                {...PasswordProps}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <TextInput
                required
                labelText="確認新密碼"
                {...PasswordProps}
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
              />

              {status !== "active" ? (
                <InlineLoading
                  style={{ marginLeft: "1rem" }}
                  description={description}
                  status={status}
                  aria-live={ariaLive}
                />
              ) : (
                <Button type="submit" onClick={handleChangePassword}>
                  修改密碼
                </Button>
              )}

              {message && (
                <InlineNotification
                  kind="error"
                  role="alert"
                  title="錯誤"
                  subtitle={message}
                />
              )}
            </FluidForm>
          </FormGroup>
        </Column>

        <Column sm={{ offset: 3 }}>
          <Button
            onClick={() => {
              logout();
              navigate("/login");
            }}
          >
            登出
          </Button>
        </Column>
      </Grid>
    </Content>
  );
};
