import { useState, useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionSkeleton,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  Tile,
} from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";
import dayjs from "dayjs";

import {
  useUserData,
  useQuery,
  useSchemas,
  useCustomerBookVisit,
} from "../../hooks";
import { SchemaEditor } from "../../components/Editor";
import { PuerperaRow, SchemaViewer } from "../../components/Viewer";

import useSWR, { useSWRConfig } from "swr";
import { URL, Fetcher as F } from "../../api";
import * as S from "../../utils/schema";

const Container = styled.div`
  background-color: #f4f4f4;
  padding: 32px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

export const BookVisitCreate = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const user = useUserData();
  const q = useQuery();
  const puerperaId = q.get("puerpera");
  const customerBookVisitId = q.get("cbv");

  const {
    customer: customerSchema,
    bookVisit: bookVisitSchema,
    bookVisitNote: bookVisitNoteSchema,
    customerBookVisit: cbvSchema,
  } = useSchemas();

  const [isSending, setIsSending] = useState(false);
  const isCustomerReady = S.createSimpleValidator(customerSchema);
  const initialCustomer = useMemo(
    () => S.createInitialData(customerSchema),
    [customerSchema]
  );

  const isBookVisitNoteReady = S.createSimpleValidator(bookVisitNoteSchema);
  const initialbookVisitNote = useMemo(
    () => S.createInitialData(bookVisitNoteSchema),
    [bookVisitNoteSchema]
  );

  const isBookVisitReady = S.createSimpleValidator(bookVisitSchema);
  const initialbookVisit = useMemo(
    () => S.createInitialData(bookVisitSchema),
    [bookVisitSchema]
  );

  const isDataReady = useCallback(
    (customer, note, visit) => {
      let ready = true;
      if (note !== undefined) {
        ready = isBookVisitNoteReady(note);
      }
      if (customer.name !== undefined) {
        ready = !!(ready && isCustomerReady(customer));
      }
      ready = !!(ready && isBookVisitReady(visit));
      return ready;
    },
    [isBookVisitNoteReady, isCustomerReady, isBookVisitReady]
  );

  const { data: puerperaData } = useSWR(
    puerperaId ? [`${URL.puerpera}${puerperaId}/`] : null,
    F.withToken
  );

  const [customer, setCustomer] = useState(initialCustomer);
  const [note, setNote] = useState(initialbookVisitNote);
  const [visit, setVisit] = useState(initialbookVisit);

  const hadCBV = customerBookVisitId === null;
  const cbv = useCustomerBookVisit(customerBookVisitId);
  const [hadCBVForCustomer, setHadCBVForCustomer] = useState(hadCBV);
  const [hadCBVForNote, setHadCBVForNote] = useState(hadCBV);
  const [hadCBVForBookVisit, setHadCBVForBookVisit] = useState(hadCBV);

  useEffect(() => {
    if (hadCBVForCustomer || puerperaId) return;
    if (cbv) {
      setCustomer({
        ...customer,
        name: cbv.contract_name,
        mobile_phone: cbv.contract_phone,
      });
      setHadCBVForCustomer(true);
    }
  }, [
    hadCBVForCustomer,
    puerperaId,
    cbv,
    customer,
    setCustomer,
    setHadCBVForCustomer,
  ]);
  // console.debug(`customer`, initialCustomer, customer);

  useEffect(() => {
    if (hadCBVForNote) return;
    if (cbv) {
      let newNote = {
        ...note,
        first_contact: "官網表單",
      };
      if (cbv.edc) {
        newNote["edc"] = cbv.edc;
      }
      if (cbv.line_id) {
        newNote["line_id_invite"] = cbv.line_id;
      }
      if (cbv.fetus) {
        newNote["fetus"] = cbv.fetus;
      }
      if (cbv.birth) {
        newNote["birth"] = cbv.birth;
      }
      if (cbv.how_to_know) {
        newNote["howToKnow"] = cbv.how_to_know;
      }
      if (cbv.first_contact_date) {
        newNote["first_contact_date"] = cbv.first_contact_date;
      }
      setNote(newNote);
      setHadCBVForNote(true);
    }
  }, [cbv, note, setNote, hadCBVForNote, setHadCBVForNote]);
  // console.debug(`note`, initialbookVisitNote, note);

  useEffect(() => {
    if (hadCBVForBookVisit) return;
    if (cbv) {
      let newVisit = {
        ...visit,
        comment: cbv.question_request,
        centers: cbv.centers.map((x) => x.id),
      };
      if (cbv.visit_date) {
        newVisit["book_date"] = dayjs(cbv.visit_date).format();
      }
      if (cbv.visit_schedule) {
        newVisit["visit_schedule_display"] = cbv.visit_schedule;
      }
      setVisit(newVisit);
      setHadCBVForBookVisit(true);
    }
  }, [cbv, visit, setVisit, hadCBVForBookVisit, setHadCBVForBookVisit]);
  // console.debug(`visit`, initialbookVisit, visit);

  const isReady = useMemo(
    () => user && isDataReady(customer, note, visit),
    [user, customer, note, visit, isDataReady]
  );

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;

    if (isSending) return;
    setIsSending(true);

    let c;
    if (customer.name !== undefined) {
      const payload = { ...customer, employee_id: user.employee_id };
      c = await F.post(URL.customer, payload);
      mutate([URL.customer + c.id], c, false);
    }

    let n;
    if (note !== undefined) {
      let notePayload = note;
      if (puerperaId) {
        notePayload["puerpera_id"] = puerperaId;
      }
      n = await F.post(URL.bookVisit.note, notePayload);
      mutate([URL.bookVisit.note + n.id], n, false);
    }
    let { book_date, ...cleanVisit } = visit;
    let visitWithIds = cleanVisit;
    if (n) {
      visitWithIds["note_id"] = n.id;
    }
    if (c) {
      visitWithIds["customer_id"] = c.id;
    }
    if (book_date !== undefined && book_date !== "") {
      visitWithIds["book_date"] = book_date;
    }

    const v = await F.post(URL.bookVisit.list, visitWithIds);
    setIsSending(false);
    mutate([URL.bookVisit.list + v.id], v, false);

    if (puerperaId) {
      navigate(`/puerpera/${puerperaId}/notes`);
    } else {
      navigate(`/bookvisit/${v.id}`);
    }
  }, [
    navigate,
    mutate,
    isReady,
    user,
    customer,
    note,
    visit,
    puerperaId,
    isSending,
    setIsSending,
  ]);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/bookvisit">預約參訪</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          新增預約參訪
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>新增產婦預約參訪</Tile>

      <Container>
        <Accordion>
          {customerBookVisitId &&
            (cbv ? (
              <AccordionItem title="網路預約" open>
                <SchemaViewer
                  fields={cbvSchema.fields}
                  excludes={[
                    "id",
                    "created",
                    "modified",
                    "status",
                    "employee",
                    "updated_date",
                    "run_check_status",
                  ]}
                  isLoading={!cbv}
                  data={cbv}
                />
              </AccordionItem>
            ) : (
              <AccordionSkeleton open count={1} />
            ))}
          {puerperaId &&
            (puerperaData ? (
              <AccordionItem title="產婦" open>
                <PuerperaRow
                  showPuerperaLink={true}
                  showPhone={true}
                  data={puerperaData}
                ></PuerperaRow>
              </AccordionItem>
            ) : (
              <AccordionSkeleton open count={1} />
            ))}
          {!puerperaId && (
            <AccordionItem title="聯絡人" open>
              <SchemaEditor
                fields={customerSchema.fields}
                excludes={["id", "employee_id", "created", "modified"]}
                data={customer}
                onChange={(data) => {
                  setCustomer(data);
                }}
              />
            </AccordionItem>
          )}
          <AccordionItem title="預約參訪" open>
            <SchemaEditor
              fields={bookVisitSchema.fields}
              filter={{ department: user.bookvisit_employee_groups }}
              excludes={[
                "id",
                "created",
                "modified",
                "puerpera_id",
                "customer_id",
                "keyin_employee_id",
                "note_id",
              ]}
              data={visit}
              onChange={(data) => {
                if (!user) return;
                setVisit({ ...data, keyin_employee_id: user.employee_id });
              }}
            />
          </AccordionItem>
          <AccordionItem title="產婦相關" open>
            <SchemaEditor
              fields={bookVisitNoteSchema.fields}
              excludes={[
                "id",
                "created",
                "modified",
                "puerpera_id",
                "keyin_employee_id",
              ]}
              data={note}
              onChange={(data) => {
                if (!user) return;
                setNote({ ...data, keyin_employee_id: user.employee_id });
              }}
            />
          </AccordionItem>
        </Accordion>
        <Actions>
          <Button
            disabled={!isReady}
            kind="primary"
            size="small"
            renderIcon={Add16}
            onClick={handleSubmit}
          >
            新增預約參訪
          </Button>
        </Actions>
      </Container>
    </Content>
  );
};
