export const emptyCenter = {
  id: -1,
  slug: "",
  name: "",
  address: "",
};

export const emptyDepartment = {
  id: -1,
  name: "",
};

export const hasId = (id) => (y) => (y && y.id) === id;

export const has_id = (id) => (y) => (y && y._id) === id;

export const identity = (x) => x;

export function normalize(xs = []) {
  let result = {};
  for (let x of xs) {
    if (!x.id) continue;
    result[x.id] = x;
  }
  return result;
}

export const denormalizeStaff = (centers = [], departments = [], staff) => {
  if (!staff) return;
  const center = centers.find(hasId(staff.center_id));
  const department = departments.find(hasId(staff.department_id));
  if (!center || !department) return;
  return {
    ...staff,
    center,
    department,
  };
};
