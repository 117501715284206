import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useFoodVendor = () => {
  const {
    token: { access },
  } = useContext(UserContext);

  const url = `${URL.foodVendor}?fields=id,name`;
  const { data = [] } = useSWR(access && [url], F.withToken, {
    revalidateOnFocus: false,
  });

  return data;
};
