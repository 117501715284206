import { useContext, useState, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Content,
  Breadcrumb,
  BreadcrumbItem,
  Tile,
  Grid,
  Row,
  Column,
  InlineNotification,
  Button,
} from "carbon-components-react";
import { Save16 } from "@carbon/icons-react";

import { CentersContext, DepartmentsContext } from "../../context";
import { useSWRConfig } from "swr";
import { URL, Fetcher as F } from "../../api";
import { StaffEditor } from "../../components/Editor";

const StaffColumn = styled(Column)`
  padding-top: 38px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

export const StaffCreate = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const [staff, setStaff] = useState();
  const staffReady = isStaffReady(staff);

  const { list: centerList } = useContext(CentersContext);
  const { list: departmentList } = useContext(DepartmentsContext);

  const [message, setMessage] = useState("");

  const handleSubmit = useCallback(async () => {
    if (!staffReady) return;

    F.post(URL.staff, staff)
      .then((r) => {
        mutate([URL.staff + r.id], r, false);
        navigate(`/staff/${r.id}`);
      })
      .catch((err) => {
        setMessage(err.message);
      });
  }, [navigate, mutate, staffReady, staff, setMessage]);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/">系統管理</BreadcrumbItem>
        <BreadcrumbItem href="/staff">員工管理</BreadcrumbItem>
      </Breadcrumb>

      <Tile>新增員工</Tile>

      <Grid>
        <Row>
          <StaffColumn sm={{ span: 2, offset: 1 }}>
            <StaffEditor
              isCreater={true}
              disabled={!centerList.length || !departmentList.length}
              centers={centerList}
              departments={departmentList}
              data={staff}
              onChange={setStaff}
            />
            <Actions>
              <Button
                kind="primary"
                size="small"
                renderIcon={Save16}
                disabled={!centerList || !departmentList || !staffReady}
                onClick={handleSubmit}
              >
                儲存
              </Button>
            </Actions>
            {message && (
              <InlineNotification
                kind="error"
                role="alert"
                title="錯誤"
                subtitle={message}
              />
            )}
          </StaffColumn>
          <Column sm={1} />
        </Row>
      </Grid>
    </Content>
  );
};

function isStaffReady(staff) {
  if (!staff) return false;
  return (
    staff.username &&
    staff.password &&
    staff.name &&
    staff.email &&
    staff.center_id !== -1 &&
    staff.department_id !== -1
  );
}
