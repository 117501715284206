import { useRef, useState, useEffect } from "react";
import { Dropdown, DropdownSkeleton } from "carbon-components-react";
import { Label } from "../Label";
import { identity } from "../../../utils";

export const Enum = ({
  id,
  className,
  style,
  field = {},
  data,
  itemToString = identity,
  onChange,
}) => {
  const { key, required = false, ui } = field;
  const { title, enum: statusEnum } = field.schema;
  let { default: defaultValue } = field.schema;
  if (Array.isArray(defaultValue)) {
    defaultValue = defaultValue[0];
  }
  const initRef = useRef(false);
  const initDataRef = useRef(data);
  const disabled = ui["ui:readonly"] ?? false;
  const [shouldValidate, setShouldValidate] = useState(false);
  const invalid = shouldValidate && required && data === undefined;

  useEffect(() => {
    if (initRef.current) return;
    if (typeof onChange !== "function") return;
    initRef.current = true;
    onChange({ [key]: initDataRef.current ?? defaultValue });
  }, [key, defaultValue, onChange]);

  return !statusEnum ? (
    <DropdownSkeleton id={id} className={className} style={style} />
  ) : (
    <Dropdown
      id={id}
      className={className}
      style={style}
      titleText={<Label required={required}>{title}</Label>}
      label="請選擇"
      disabled={disabled}
      items={statusEnum}
      itemToString={itemToString}
      selectedItem={data}
      invalid={invalid}
      invalidText="此為必選欄位"
      onFocus={() => setShouldValidate(false)}
      onBlur={() => setShouldValidate(true)}
      onChange={(e) => {
        let v = e.selectedItem;
        if (typeof onChange !== "function") return;
        onChange({ [key]: v });
      }}
    />
  );
};
