import { useState, useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import {
  Content,
  Breadcrumb,
  BreadcrumbItem,
  Tile,
  Accordion,
  AccordionItem,
  Button,
} from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";

import { SchemaEditor } from "../../components/Editor";
import { useUserData, useSchemas } from "../../hooks";

import useSWR, { useSWRConfig } from "swr";
import { URL, Fetcher as F } from "../../api";
import * as S from "../../utils/schema";

const Container = styled.div`
  background-color: #f4f4f4;
  padding: 32px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

export const BookVisitEdit = () => {
  const { bvid } = useParams();

  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const bookVisitURLWithID = `${URL.bookVisit.list}${bvid}/`;
  const bookVisitFetchURL = `${bookVisitURLWithID}?expand=customer,note,status`;
  const { data: rawBookVisit } = useSWR([bookVisitFetchURL], F.withToken);

  const user = useUserData();

  const {
    customer: customerSchema,
    bookVisit: bookVisitSchema,
    bookVisitNote: bookVisitNoteSchema,
  } = useSchemas();

  const isCustomerReady = S.createSimpleValidator(customerSchema);
  const isBookVisitReady = S.createSimpleValidator(bookVisitSchema);
  const isBookVisitNoteReady = S.createSimpleValidator(bookVisitNoteSchema);

  const isDataReady = useCallback(
    (customer, note, visit) => {
      let ready = true;
      if (note !== undefined && note !== null) {
        ready = isBookVisitNoteReady(note);
        // console.debug(`ready note`, ready, note);
      }
      if (customer !== undefined) {
        ready = !!(ready && isCustomerReady(customer));
        // console.debug(`ready customer`, ready, customer);
      }
      ready = !!(ready && isBookVisitReady(visit));
      // console.debug(`ready visit`, ready, visit);
      return ready;
    },
    [isBookVisitNoteReady, isCustomerReady, isBookVisitReady]
  );

  const [customer, setCustomer] = useState();
  const [customerID, setCustomerID] = useState();
  const [note, setNote] = useState();
  const [noteID, setNoteID] = useState();
  const [visit, setVisit] = useState();

  useEffect(() => {
    if (!rawBookVisit) return;
    const {
      created,
      modified,
      customer: rawCustomer,
      note: rawNote,
      ...other
    } = rawBookVisit;

    if (rawCustomer) {
      const { id: cid, ...c } = rawCustomer;
      // console.debug(`cid`, cid, c);
      setCustomerID(cid);
      setCustomer(c);
    }
    setVisit(other);
    setNoteID(rawBookVisit.note_id);
    setNote(rawNote);
  }, [rawBookVisit]);

  const isReady = useMemo(
    () => user && isDataReady(customer, note, visit),
    [user, customer, note, visit, isDataReady]
  );

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;

    if (customerID) {
      const c = await F.put(`${URL.customer}${customerID}/`, customer);
      mutate([URL.customer + c.id], c, false);
    }

    let n;
    if (note !== null) {
      const { id, created, modified, ...noteTo } = note;
      // If had noteID, just put to update, otherwise create one
      if (!!noteID) {
        n = await F.put(`${URL.bookVisit.note}${noteID}/`, noteTo);
        mutate([URL.bookVisit.note + noteID], n, false);
      } else {
        n = await F.post(URL.bookVisit.note, noteTo);
        mutate([URL.bookVisit.note], n, false);
      }
    }

    if (visit !== null) {
      const { id: vid, created, modified, note_id, ...visitToPut } = visit;
      var visitWithIds = {
        ...visitToPut,
      };
      if (customerID) {
        visitWithIds["customer_id"] = customerID;
      }
      if (note !== null) {
        visitWithIds["note_id"] = n.id;
      }

      const v = await F.put(`${URL.bookVisit.list}${bvid}/`, visitWithIds);
      mutate([URL.bookVisit + v.id], v, false);
      navigate(`/bookvisit/${v.id}`);
    } else {
      navigate(`/bookvisit/`);
    }
  }, [
    navigate,
    mutate,
    isReady,
    customer,
    note,
    visit,
    bvid,
    customerID,
    noteID,
  ]);

  return (
    <Content>
      <Breadcrumb isCurrentPage="true">
        <BreadcrumbItem href="/bookvisit">預約參訪</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          編輯預約參訪
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>編輯預約參訪</Tile>

      <Container>
        <Accordion>
          {customerID && (
            <AccordionItem title="聯絡人" open>
              <SchemaEditor
                fields={customerSchema.fields}
                excludes={["id", "employee_id", "created", "modified"]}
                data={customer}
                onChange={(data) => {
                  if (!user) return;
                  setCustomer({ ...data, employee_id: user.employee_id });
                }}
              />
            </AccordionItem>
          )}

          <AccordionItem title="預約參訪" open>
            <SchemaEditor
              fields={bookVisitSchema.fields}
              filter={{ department: user.bookvisit_employee_groups }}
              excludes={[
                "id",
                "customer_id",
                "puerpera_id",
                "keyin_employee_id",
                "note_id",
                "created",
                "modified",
              ]}
              data={visit}
              onChange={(data) => {
                if (!user) return;
                setVisit({ ...data, keyin_employee_id: user.employee_id });
              }}
            />
          </AccordionItem>
          <AccordionItem title="產婦相關" open={!!note}>
            <SchemaEditor
              fields={bookVisitNoteSchema.fields}
              excludes={[
                "id",
                "puerpera_id",
                "keyin_employee_id",
                "service_employee_id",
                "created",
                "modified",
              ]}
              data={note}
              onChange={(data) => {
                if (!user) return;
                setNote({ ...data, keyin_employee_id: user.employee_id });
              }}
            />
          </AccordionItem>
        </Accordion>
        <Actions>
          <Button
            disabled={!isReady}
            kind="primary"
            size="small"
            renderIcon={Add16}
            onClick={handleSubmit}
          >
            儲存
          </Button>
        </Actions>
      </Container>
    </Content>
  );
};
