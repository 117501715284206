import { useState } from "react";
import {
  Column,
  DatePicker,
  DatePickerInput,
  Row,
  TimePicker,
} from "carbon-components-react";
import { Label } from "../Label";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DateTimeFormat, TimeZone } from "../../../utils/datetime";

const TIME_PATTERN = "([012][0-9])([0-5][0-9])";
const timeRegex = new RegExp(TIME_PATTERN);

dayjs.extend(utc);
dayjs.extend(timezone);

export const DateTime = ({
  id,
  className,
  style,
  disabled = false,
  field = {},
  data = "",
  dateFormat = DateTimeFormat.date,
  timeFormat = DateTimeFormat.shortTime,
  timeZone = TimeZone,
  onChange,
}) => {
  dayjs.tz.setDefault(timeZone);

  const { key, required = false } = field;
  const { title } = field.schema;

  const hadData = data !== "" && data !== null;
  const [_date, setDate] = useState(dayjs(hadData ? data : undefined));

  var dateStr = "";
  var timeStr = "";
  if (hadData) {
    const _d = dayjs(data);
    dateStr = _d.format(dateFormat);
    timeStr = _d.format(timeFormat);
  }

  const handleDateUpdate = ([date = ""]) => {
    if (!date) return;

    if (date === "") {
      if (typeof onChange !== "function") return;
      onChange({ [key]: null });
      return;
    }

    let newDate = dayjs(date);
    if (_date) {
      newDate = newDate.hour(_date.hour()).minute(_date.minute());
    }
    setDate(newDate);

    if (!newDate.isValid()) return;
    const v = newDate.toISOString();

    if (typeof onChange !== "function") return;
    onChange({ [key]: v });
  };

  const handleTimeUpdate = (input) => {
    const str = input.target.value;

    if (str === "") {
      if (typeof onChange !== "function") return;
      onChange({ [key]: null });
      return;
    }

    if (str.length < 4) return;

    const match = str.match(timeRegex);
    if (!match) return;
    const [, hour, minute] = match;
    let newDate = _date ? _date.clone() : dayjs();
    newDate = newDate.hour(parseInt(hour, 10)).minute(parseInt(minute, 10));
    setDate(newDate);

    if (!newDate.isValid()) return;
    const v = newDate.toISOString();

    if (typeof onChange !== "function") return;
    onChange({ [key]: v });
  };

  return (
    <Row>
      <Column>
        <DatePicker
          id={`date-picker-${id}`}
          className={className}
          style={style}
          datePickerType="single"
          dateFormat="Y/m/d"
          disabled={disabled}
          value={dateStr}
          onChange={handleDateUpdate}
        >
          <DatePickerInput
            id={`${id || "date-picker"}__input`}
            placeholder="yyyy/mm/dd"
            labelText={<Label required={required}>{title}</Label>}
          />
        </DatePicker>
      </Column>
      <Column>
        <TimePicker
          id={`time-picker-${id}`}
          className={className}
          labelText={<Label required={required}>時間</Label>}
          placeholder="HHMM"
          pattern={TIME_PATTERN}
          value={timeStr}
          onChange={handleTimeUpdate}
        ></TimePicker>
      </Column>
    </Row>
  );
};
