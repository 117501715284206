import useSWR from "swr";
import { URL, Fetcher as F } from "../api";

export const useContractList = (filter = {}) => {
  const { puerpera } = filter;
  const params = new URLSearchParams();
  params.append("expand", "center");
  if (puerpera) {
    params.append("puerpera", puerpera);
  }
  const contractURL = `${URL.contract}?${params.toString()}`;
  const { data = [] } = useSWR([contractURL], F.withToken, {
    revalidateOnFocus: false,
  });

  return data;
};
