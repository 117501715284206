import { useContext, useState, useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  InlineLoading,
  Row,
  Tile,
} from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";

import { SchemaEditor } from "../../components/Editor";
import { SchemaContext } from "../../context";
import { useUserData, useQuery } from "../../hooks";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import * as S from "../../utils/schema";

const Container = styled.div`
  background-color: #f4f4f4;
  padding: 32px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

export const PuerperaCreate = () => {
  const navigate = useNavigate();
  const user = useUserData();
  const q = useQuery();
  const customerID = q.get("customer");

  const { puerpera: puerperaSchema } = useContext(SchemaContext) || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [description, setDescription] = useState("建立中...");
  const [ariaLive, setAriaLive] = useState("off");

  const { data: customer } = useSWR(
    !!customerID ? [`${URL.customer}${customerID}/`] : null,
    F.withToken
  );

  const initialPuerpera = useMemo(() => {
    if (customerID === null) {
      return { weight: 0, height: 0 };
    } else if (!customer) {
      return null;
    }

    const {
      name = "",
      mobile_phone = "",
      phone = "",
      address = "",
      email = "",
      weight = 0,
      height = 0,
    } = customer;
    return {
      name,
      mobile_phone,
      phone,
      address,
      email,
      weight,
      height,
    };
  }, [customerID, customer]);
  const [puerpera, setPuerpera] = useState(initialPuerpera);

  useEffect(() => {
    setPuerpera(initialPuerpera);
  }, [setPuerpera, initialPuerpera]);

  const isPuerperaReady = S.createSimpleValidator(puerperaSchema);
  const isReady = useMemo(
    () => user && isPuerperaReady(puerpera),
    [user, puerpera, isPuerperaReady]
  );

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;

    setIsSubmitting(true);
    setAriaLive("assertive");

    const b =
      puerpera.birthday && puerpera.birthday !== "" ? puerpera.birthday : null;

    const payload = {
      ...puerpera,
      customer_id: customerID,
      birthday: b,
    };
    const p = await F.post(URL.puerpera, payload);

    setSuccess(!!p.id);
    setDescription(p.id ? "建立成功" : "建立失敗");

    navigate(`/puerpera/${p.id}/info`);
  }, [navigate, isReady, puerpera, customerID]);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/puerpera">產婦管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          新增產婦
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>新增產婦</Tile>

      <Grid>
        <Row>
          <Column>
            <Container>
              <SchemaEditor
                fields={puerperaSchema.fields}
                excludes={[
                  "id",
                  "created",
                  "modified",
                  "employee_id",
                  "customer_id",
                ]}
                requiredFields={["tw_identity"]}
                data={puerpera}
                onChange={(x) => {
                  if (!user) return;
                  setPuerpera({ ...x, employee_id: user.employee_id });
                }}
              />
              <Actions>
                {isSubmitting ? (
                  <InlineLoading
                    description={description}
                    status={success ? "finished" : "active"}
                    aria-live={ariaLive}
                  />
                ) : (
                  <Button
                    disabled={!isReady}
                    kind="primary"
                    size="small"
                    renderIcon={Add16}
                    onClick={handleSubmit}
                  >
                    新增產婦
                  </Button>
                )}
              </Actions>
            </Container>
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
