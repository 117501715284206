import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useCustomerBookVisitStatus = () => {
  const {
    token: { access },
  } = useContext(UserContext);

  const { data: cbvList = [] } = useSWR(
    access && [URL.bookVisit.customerStatus],
    F.withToken,
    { revalidateOnFocus: false }
  );

  return cbvList;
};
