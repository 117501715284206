import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useFoodMeal = () => {
  const {
    token: { access },
  } = useContext(UserContext);

  const { data = [] } = useSWR(access && [URL.foodMeal], F.withToken, {
    revalidateOnFocus: false,
  });

  return data;
};
