export function append(xs, x) {
  return [...xs, x];
}

export function insert(xs, x, index) {
  return [...xs.slice(0, index), x, ...xs.slice(index)];
}

export function remove(xs, index) {
  return [...xs.slice(0, index), ...xs.slice(index + 1)];
}

export function removeBy(xs, f) {
  const index = xs.findIndex(f);
  if (index === -1) return xs;
  return remove(xs, index);
}

export function replace(xs, x, index) {
  return [...xs.slice(0, index), x, ...xs.slice(index + 1)];
}

function defaultEqual(a, b) {
  return a === b;
}

export function isEqual(xs, ys, isEq = defaultEqual) {
  if (xs.length !== ys.length) return false;
  for (let i in xs) {
    if (!isEq(xs[i], ys[i])) return false;
  }
  return true;
}
