import styled from "styled-components";

import dayjs from "dayjs";
import { DateTimeFormat } from "../../utils/datetime";

import { Item } from "./Item";
import { Comment } from "./Comment";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
`;

const Column = styled.div`
  > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const BookVisitViewer = ({ loading = false, data = {} }) => {
  const {
    status = "",
    centers = [],
    book_date = "",
    visit_schedule_display = "",
    comment = "",
    service_employee = "",
  } = data;

  return (
    <Container>
      <Column>
        <Item labelText="預約參觀狀態" loading={loading}>
          {status?.name ?? ""}
        </Item>
        <Item labelText="希望參觀館別">
          <ul>
            {centers.map(({ slug, name }) => (
              <li key={slug}>{name}</li>
            ))}
          </ul>
        </Item>
        <Item labelText="服務人員" loading={loading}>
          {service_employee?.name ?? ""}
        </Item>
      </Column>
      <Column>
        <Item labelText="預計參訪日期" loading={loading}>
          {book_date && dayjs(book_date).format(DateTimeFormat.date)}
        </Item>
        <Item labelText="預計參訪時段" loading={loading}>
          {visit_schedule_display}
        </Item>
        <Comment labelText="備註" loading={loading}>
          {comment}
        </Comment>
      </Column>
    </Container>
  );
};
