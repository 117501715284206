import styled from "styled-components";
import { SkeletonText } from "carbon-components-react";

const Title = styled.div`
  font-size: 1rem;
  margin-bottom: 2px;
`;

const Content = styled.div`
  font-size: 0.875rem;
`;

const Container = styled.div`
  border-top: 1px solid #e0e0e0;
  padding: 16px;
`;

export const Comment = ({ loading = false, labelText = "", children }) => {
  return (
    <Container>
      {loading ? (
        <SkeletonText />
      ) : (
        <>
          <Title>{labelText}</Title>
          <Content>{children}</Content>
        </>
      )}
    </Container>
  );
};
