import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useBookVisitInteractionStatus = () => {
  const {
    token: { access },
  } = useContext(UserContext);

  const { data: isList = [] } = useSWR(
    access && [URL.bookVisit.interactionStatus],
    F.withToken,
    { revalidateOnFocus: false }
  );

  return isList;
};
