import { useCallback } from "react";
import styled from "styled-components";
import {
  Column,
  DatePicker,
  DatePickerInput,
  Dropdown,
  FileUploader,
  Link,
  Row,
  TextInput,
} from "carbon-components-react";

import { Download16 } from "@carbon/icons-react";

import dayjs from "dayjs";
import { DateTimeFormat } from "../../utils/datetime";

import { hasId } from "../../utils";
import { useSchemas } from "../../hooks";
import { NoteArea } from "./NoteArea";

const TopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  margin-bottom: 32px;
`;

export const ContractEditor = ({
  disabled = false,
  isEdit = false,
  centerList = [],
  data = {},
  onChange,
}) => {
  const { contract: contractSchema } = useSchemas();
  const csp = contractSchema?.schema?.properties ?? {};
  const reasonEnum = csp.reason?.enum ?? [];

  const {
    center_id = "",
    contract_number = "",
    file,
    note = "",
    sign = "",
    terminate_date = "",
    reason = "",
  } = data;

  const update = useCallback(
    (obj) => ({
      center_id,
      contract_number,
      file,
      note,
      sign,
      terminate_date,
      reason,
      ...obj,
    }),
    [center_id, contract_number, file, note, sign, terminate_date, reason]
  );

  return (
    <div>
      <TopRow>
        <Column>
          <TextInput
            id="contract__contract_number"
            disabled={disabled}
            labelText="合約編號"
            placeholder="請輸入合約編號"
            value={contract_number}
            onChange={(evt) => {
              if (typeof onChange !== "function") return;
              onChange(update({ contract_number: evt.target.value }));
            }}
          />
          <Dropdown
            id="contract__conters"
            disabled={disabled || centerList.length === 0}
            titleText="館別"
            label="請選擇館別"
            selectedItem={centerList.find(hasId(center_id))}
            items={centerList}
            itemToString={(x) => x.name}
            onChange={(v) => {
              if (typeof onChange !== "function") return;
              onChange(update({ center_id: v.selectedItem.id }));
            }}
          />
        </Column>

        <Column>
          <Row>
            <Column>
              <DatePicker
                datePickerType="single"
                value={sign}
                dateFormat="Y/m/d"
                onChange={([date = ""]) => {
                  if (typeof onChange !== "function" || !date) return;
                  onChange(
                    update({ sign: dayjs(date).format(DateTimeFormat.date) })
                  );
                }}
              >
                <DatePickerInput
                  id="contract__sign"
                  placeholder="yyyy/mm/dd"
                  labelText="簽約日期"
                />
              </DatePicker>
            </Column>
            <Column>
              <DatePicker
                datePickerType="single"
                value={terminate_date}
                dateFormat="Y/m/d"
                onChange={([date = ""]) => {
                  if (typeof onChange !== "function" || !date) return;
                  onChange(
                    update({
                      terminate_date: dayjs(date).format(DateTimeFormat.date),
                    })
                  );
                }}
              >
                <DatePickerInput
                  id="contract__terminate_date"
                  placeholder="yyyy/mm/dd"
                  labelText="退訂日期"
                />
              </DatePicker>
            </Column>
          </Row>
          <Dropdown
            id="contract__reason"
            disabled={disabled || reasonEnum.length === 0}
            titleText="退訂原因"
            label="請選擇"
            selectedItem={reason}
            items={reasonEnum}
            itemToString={(x) => x}
            onChange={(v) => {
              if (typeof onChange !== "function") return;
              onChange(update({ reason: v.selectedItem }));
            }}
          />
        </Column>
      </TopRow>
      <TopRow>
        <Column>
          {isEdit ? (
            <Link href={file} renderIcon={Download16}>
              合約
            </Link>
          ) : (
            <FileUploader
              disabled={disabled}
              filenameStatus="edit"
              multiple={false}
              labelTitle="檔案上傳"
              labelDescription="僅可上傳PDF格式檔案"
              accept={[".pdf"]}
              buttonLabel="新增檔案"
              onChange={(evt) => {
                if (typeof onChange !== "function") return;
                const { files: [file] = [] } = evt.target;
                if (!file) return;
                onChange(update({ file }));
              }}
              onDelete={() => {
                if (typeof onChange !== "function") return;
                onChange(update({ file: null }));
              }}
            />
          )}
        </Column>
      </TopRow>
      <Column>
        <NoteArea
          id="contract__note"
          disabled={disabled}
          labelText="備註"
          placeholder="詳細說明"
          value={note}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ note: evt.target.value }));
          }}
        />
      </Column>
    </div>
  );
};
