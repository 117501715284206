import { Column, Grid, Row, Link } from "carbon-components-react";

import { Item } from "./Item";

export const BabyStatusSummaryStatusViewer = ({
  loading = false,
  data = {},
  onChangeLimit,
}) => {
  if (loading) {
    return (
      <>
        <Grid>
          <Row>
            <Column>
              <Item loading={loading} />
            </Column>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Column>
              <Item loading={loading} />
            </Column>
          </Row>
        </Grid>
      </>
    );
  }

  const fieldKeys = Object.keys(data);
  const fieldWithValue = fieldKeys.map((k) => {
    const { name, fieldKeys, fieldNames, limit_keys, ...o } = data[k];
    var subFields = [];
    for (let [index, val] of fieldKeys.entries()) {
      subFields.push({
        key: val,
        text: fieldNames[index],
        lq: limit_keys[index],
        val: o[val],
      });
    }
    return {
      key: k,
      name: name,
      fields: subFields,
    };
  });

  return (
    <div>
      {fieldWithValue.map((value) => {
        return (
          <Grid>
            <Row>
              <Column>
                <Item labelText={value.name} loading={loading} />
              </Column>
              {value.fields.map((vf) => (
                <Column>
                  <Item labelText={vf.text} loading={loading}>
                    <Link
                      onClick={() => {
                        onChangeLimit({
                          k: value.name,
                          a: vf.text,
                          q: `${value.key}=${vf.lq}`,
                        });
                      }}
                    >
                      {vf.val}
                    </Link>
                  </Item>
                </Column>
              ))}
            </Row>
          </Grid>
        );
      })}
    </div>
  );
};
