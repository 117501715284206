import { FormGroup, Toggle } from "carbon-components-react";
import { Label } from "../Label";

export const Switch = ({
  id,
  className,
  style,
  field = {},
  data,
  onChange,
}) => {
  const { key, required = false, ui } = field;
  const { title, enum: enums, enumNames } = field.schema;

  const disabled = ui["ui:readonly"] ?? false;
  const _enums = enums ? enums : [false, true];

  return (
    <FormGroup
      id={id}
      className={className}
      style={style}
      legendText={<Label required={required}>{title}</Label>}
    >
      <Toggle
        id={`${id || "switch"}__toggler`}
        disabled={disabled}
        labelA={enumNames ? enumNames[0] : "否"}
        labelB={enumNames ? enumNames[1] : "是"}
        toggled={data}
        onChange={(evt) => {
          const v = evt.target.checked;
          if (typeof onChange !== "function") return;
          const out = v ? _enums[1] : _enums[0];
          onChange({ [key]: out });
        }}
      />
    </FormGroup>
  );
};
