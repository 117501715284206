import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useCenters = () => {
  const {
    token: { access },
  } = useContext(UserContext);
  const { data: centerList = [] } = useSWR(
    access && [URL.center],
    F.withToken,
    { revalidateOnFocus: false }
  );

  return centerList;
};
