import { useContext, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  Dropdown,
  DatePicker,
  DatePickerInput,
  Grid,
  Pagination,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  InlineLoading,
  OverflowMenu,
  OverflowMenuItem,
  Modal,
} from "carbon-components-react";

import {
  Add16,
  DocumentExport16,
  Launch16,
  Search16,
} from "@carbon/icons-react";

import dayjs from "dayjs";
import useSWR from "swr";
import { DateTimeFormat } from "../../utils/datetime";
import { UserContext, CentersContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import { useQuery } from "../../hooks";
import * as A from "../../utils/array";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

const headers = [
  {
    key: "center",
    name: "館別",
  },
  {
    key: "room",
    name: "房名",
  },
  {
    key: "puerpera_name",
    name: "媽媽姓名",
  },
  {
    key: "checkin_date",
    name: "入住日",
  },
  {
    key: "checkout_date",
    name: "退房日",
  },
  {
    key: "fetus",
    name: "胎次",
  },
  {
    key: "foodvendor",
    name: "膳食",
  },
  {
    key: "actual_checkin_date",
    name: "實際入住日",
  },
  {
    key: "actions",
    name: "",
  },
];

const createQueryString = ({
  center,
  puerperaName,
  roomName,
  contractNumber,
  actualCheckinDate,
  date,
  offset,
  limit,
}) => {
  const params = new URLSearchParams();
  params.append("expand", "bookRoom.puerpera,foodvendor");
  if (center !== undefined) {
    const cid = center.id;
    if (cid !== 0) {
      params.append("center", cid);
    }
  }
  if (puerperaName !== undefined) {
    params.append("puerpera_name", puerperaName);
  }
  if (roomName !== undefined) {
    params.append("room_name", roomName);
  }
  if (contractNumber !== undefined) {
    params.append("contract_name", contractNumber);
  }
  if (actualCheckinDate !== undefined) {
    params.append(
      "actual_checkin_date",
      dayjs(actualCheckinDate).format(DateTimeFormat.date)
    );
  }
  if (date !== undefined) {
    params.append("date", date);
  }
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  return params.toString();
};

export const HousingSearch = () => {
  const { list: centerList } = useContext(CentersContext);

  const q = useQuery();
  let date = q.get("_date");
  if (date === "" || date === null) {
    date = dayjs().format(DateTimeFormat.date);
  }

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const [center, setCenter] = useState();
  const [puerperaName, setPuerperName] = useState();
  const [roomName, setRoomName] = useState();
  const [contractNumber, setContractNumber] = useState();
  const [actualCheckinDate, setActualCheckinDate] = useState();

  const [isTodayExporting, setIsTodayExporting] = useState(false);
  const [exportTodayStatus, setExportTodayStatus] = useState("off");
  const [exportTodayURL, setExportTodayURL] = useState("");

  const [isTomorrowExporting, setIsTomorrowExporting] = useState(false);
  const [exportTomorrowStatus, setExportTomorrowStatus] = useState("off");
  const [exportTomorrowURL, setExportTomorrowURL] = useState("");

  const queryString = useMemo(
    () => createQueryString({ center, date, offset, limit }),
    [center, date, offset, limit]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const {
    token: { access },
  } = useContext(UserContext);
  const housingURL = `${URL.housing}?${realyQuery}`;
  const { data } = useSWR(access && [housingURL], F.withToken);

  const isLoading = !data;

  const handleTodayExport = useCallback(async () => {
    setExportTodayStatus("active");
    setIsTodayExporting(true);
    let d = date;
    if (d === null || d === "") {
      // Today
      d = dayjs().format(DateTimeFormat.date);
    }
    const url = `${URL.export.housing}?c=today&date=${d}`;
    F.withToken(url)
      .then((r) => {
        // console.debug(`export today success`, r);
        setExportTodayStatus("finished");
        setIsTodayExporting(false);
        if (r.sheet_url !== undefined) {
          setExportTodayURL(r.sheet_url);
        }
      })
      .catch((r) => {
        // console.debug(`export today catch error`, r);
        setExportTodayStatus("error");
        setIsTodayExporting(false);
      });
  }, [date, setIsTodayExporting, setExportTodayStatus, setExportTodayURL]);

  const handleTomorrowExport = useCallback(async () => {
    setExportTomorrowStatus("active");
    setIsTomorrowExporting(true);
    let d = date;
    if (d === null || d === "") {
      // Tomorrow
      d = dayjs().add(1, "day").format(DateTimeFormat.date);
    } else if (dayjs(d, DateTimeFormat.date).isValid()) {
      d = dayjs(d, DateTimeFormat.date)
        .add(1, "day")
        .format(DateTimeFormat.date);
    }
    const url = `${URL.export.housing}?c=tomorrow&date=${d}`;
    F.withToken(url)
      .then((r) => {
        // console.debug(`export tomorrow success`, r);
        setExportTomorrowStatus("finished");
        setIsTomorrowExporting(false);
        if (r.sheet_url !== undefined) {
          setExportTomorrowURL(r.sheet_url);
        }
      })
      .catch((r) => {
        // console.debug(`export tomorrow catch error`, r);
        setExportTomorrowStatus("error");
        setIsTomorrowExporting(false);
      });
  }, [
    date,
    setIsTomorrowExporting,
    setExportTomorrowStatus,
    setExportTomorrowURL,
  ]);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/housing">住房管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          搜尋
        </BreadcrumbItem>
      </Breadcrumb>
      <Grid>
        <Row>
          <Column>
            <Button
              kind="secondary"
              size="small"
              renderIcon={DocumentExport16}
              onClick={handleTodayExport}
            >
              匯出住房日誌(當日)
              {(isTodayExporting || exportTodayStatus !== "off") && (
                <InlineLoading status={exportTodayStatus} />
              )}
            </Button>
            {(isTodayExporting || exportTodayStatus !== "off") && (
              <Button
                kind="secondary"
                renderIcon={Launch16}
                iconDescription="匯出住房日誌(當日)網址"
                hasIconOnly
                disabled={exportTodayURL === ""}
                as="a"
                target="_blank"
                href={exportTodayURL}
              />
            )}
            <Button
              kind="secondary"
              size="small"
              renderIcon={DocumentExport16}
              onClick={handleTomorrowExport}
            >
              匯出住房日誌(隔日)
              {(isTomorrowExporting || exportTomorrowStatus !== "off") && (
                <InlineLoading status={exportTomorrowStatus} />
              )}
            </Button>
            {(isTomorrowExporting || exportTomorrowStatus !== "off") && (
              <Button
                kind="secondary"
                renderIcon={Launch16}
                iconDescription="匯出住房日誌(隔日)網址"
                hasIconOnly
                disabled={exportTomorrowURL === ""}
                as="a"
                target="_blank"
                href={exportTomorrowURL}
              />
            )}
          </Column>
        </Row>
      </Grid>
      <Tile>
        <Grid>
          <Row>
            <Column md={1}>
              <Dropdown
                id="center-selector"
                titleText="館別"
                label="全部"
                items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
                itemToString={(item) => item.name}
                selectedItem={center}
                onChange={(v) => {
                  setCenter(v.selectedItem);
                }}
              />
            </Column>
            <Column>
              <Search
                size="lg"
                labelText="搜尋產婦姓名"
                placeholder="產婦姓名"
                onChange={(e) => {
                  const v = e.target.value;
                  setPuerperName(v);
                }}
              />
            </Column>
            <Column>
              <Search
                size="lg"
                labelText="搜尋房名"
                placeholder="房名"
                onChange={(e) => {
                  const v = e.target.value;
                  setRoomName(v);
                }}
              />
            </Column>
            <Column>
              <Search
                size="lg"
                labelText="搜尋合約編號"
                placeholder="合約編號"
                onChange={(e) => {
                  const v = e.target.value;
                  setContractNumber(v);
                }}
              />
            </Column>
            <Column>
              <DatePicker
                dateFormat="Y/m/d"
                datePickerType="single"
                onChange={([date]) => {
                  setActualCheckinDate(date);
                }}
              >
                <DatePickerInput
                  placeholder="yyyy/mm/dd"
                  labelText="實際入住日"
                />
              </DatePicker>
            </Column>
            <Column sm={1}>
              <Button
                renderIcon={Search16}
                iconDescription="搜尋"
                hasIconOnly
                onClick={() => {
                  setPage(1);
                  const queryString = createQueryString({
                    center,
                    puerperaName,
                    roomName,
                    contractNumber,
                    actualCheckinDate,
                    offset: 0,
                    limit,
                  });
                  setRealyQuery(queryString);
                }}
              ></Button>
            </Column>
          </Row>
        </Grid>
      </Tile>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <HousingTable
          page={page}
          offset={offset}
          limit={limit}
          bookrooms={data}
          onPageChange={({ page, pageSize }) => {
            const limit = pageSize;
            const offset = (page - 1) * limit;
            // offset and limit will not be update until the next event loop iteration
            setPage(page);
            setLimit(limit);
            // so we update the real query by ourselves
            const queryString = createQueryString({
              center,
              puerperaName,
              roomName,
              contractNumber,
              actualCheckinDate,
              offset,
              limit,
            });
            setRealyQuery(queryString);
          }}
        />
      )}
    </Content>
  );
};

function HousingTable({ page, limit, bookrooms, onPageChange }) {
  const pageSize = limit;
  const total = (bookrooms && bookrooms.count) || 0;
  const data = (bookrooms && bookrooms.results) || [];

  const cleanData = data.map(
    ({ bookRoom, employee, today_brs, foodvendor, ...other }) => {
      const room = today_brs?.room;
      return {
        ...other,
        puerpera_name: bookRoom?.puerpera?.name ?? "",
        checkin_date: today_brs?.checkin_date,
        checkout_date: today_brs?.checkout_date,
        room: room?.name ?? "",
        center: room?.center?.name ?? "",
        foodvendor: foodvendor?.name ?? "",
      };
    }
  );

  const pageProps = {
    disabled: !bookrooms,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  const [openModal, setOpenModal] = useState(false);
  const [rowID, setRowID] = useState(0);
  const [modalStatus, setModalStatus] = useState("inactive");

  const handleSubmit = async () => {
    if (rowID === 0) {
      return;
    }
    setModalStatus("active");
    await F.patch(`${URL.housing}${rowID}/`, {
      actual_checkin_date: dayjs().format(DateTimeFormat.date),
    });
    setOpenModal(false);
    setModalStatus("finished");
  };

  return (
    <>
      <Modal
        open={openModal}
        danger
        modalHeading="您確定要新增入住嗎？"
        modalLabel="實際入住日"
        primaryButtonText="確認"
        secondaryButtonText="取消"
        loadingStatus={modalStatus}
        onRequestClose={() => setOpenModal(false)}
        onSecondarySubmit={() => setOpenModal(false)}
        onRequestSubmit={handleSubmit}
      />
      <DataTable rows={cleanData} headers={headers}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button
                  renderIcon={Add16}
                  as={Link}
                  to="/housing/selectPuerpera"
                >
                  新增住房
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()} isSortable>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      isSortable={header.key !== "actions"}
                    >
                      {header.name}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      const id = cell.id.split(":")[0];
                      let content;
                      switch (header) {
                        case "checkin_date":
                        case "checkout_date":
                        case "created":
                        case "actual_checkin_date":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content =
                              header === "actual_checkin_date"
                                ? "尚未入住"
                                : cell.value;
                          }
                          break;
                        case "room":
                        case "puerpera_name":
                        case "center":
                        case "fetus":
                        case "foodvendor":
                          content = cell.value;
                          break;
                        case "actions":
                          content = (
                            <OverflowMenu size="sm" flipped>
                              <OverflowMenuItem
                                itemText="瀏覽"
                                href={`/housing/${id}`}
                              />
                              <OverflowMenuItem
                                itemText="編輯"
                                href={`/housing/${id}/edit`}
                              />
                              <OverflowMenuItem
                                hasDivider
                                itemText="確認入住"
                                onFocus={() => setRowID(id)}
                                onClick={() => setOpenModal(true)}
                              />
                            </OverflowMenu>
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Pagination {...pageProps} />
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
