import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useBookVisitStatus = () => {
  const {
    token: { access },
  } = useContext(UserContext);

  const { data: bvsList = [] } = useSWR(
    access && [URL.bookVisit.status],
    F.withToken,
    { revalidateOnFocus: false }
  );

  return bvsList;
};
