import { useContext, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import {
  Content,
  Breadcrumb,
  BreadcrumbItem,
  Tile,
  Grid,
  Row,
  Column,
  Button,
} from "carbon-components-react";
import { Save16 } from "@carbon/icons-react";

import { useSWRConfig } from "swr";
import { URL, Fetcher as F } from "../../api";
import { DepartmentsContext, CentersContext } from "../../context";
import { useStaff } from "../../hooks";
import { StaffEditor } from "../../components/Editor";

const StaffColumn = styled(Column)`
  padding-top: 38px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

export const StaffEdit = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { sid } = useParams();

  const [staff, setStaff] = useState();
  const staffReady = isStaffReady(staff);

  const { list: departmentList } = useContext(DepartmentsContext);
  const { list: centerList } = useContext(CentersContext);
  const sourceStaff = useStaff(sid);

  const staffURL = `${URL.staff}${sid}/`;

  useEffect(() => {
    if (!sourceStaff) return;
    setStaff(sourceStaff);
  }, [sourceStaff]);

  const handleSubmit = useCallback(() => {
    if (!staffReady) return;

    const { username, password, ...cleanStaff } = staff;

    mutate([staffURL], cleanStaff, false);
    const promise = F.put(staffURL, cleanStaff);
    mutate([staffURL], promise);

    navigate(`/staff/${sid}`);
  }, [navigate, mutate, staffReady, staffURL, staff, sid]);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/">系統管理</BreadcrumbItem>
        <BreadcrumbItem href="/staff">員工管理</BreadcrumbItem>
      </Breadcrumb>

      <Tile>編輯員工</Tile>

      <Grid>
        <Row>
          <StaffColumn sm={{ span: 2, offset: 1 }}>
            <StaffEditor
              disabled={!sourceStaff}
              centers={centerList}
              departments={departmentList}
              data={staff}
              onChange={setStaff}
            />
            <Actions>
              <Button
                kind="primary"
                size="small"
                renderIcon={Save16}
                disabled={!centerList || !departmentList || !staffReady}
                onClick={handleSubmit}
              >
                儲存
              </Button>
            </Actions>
          </StaffColumn>
          <Column sm={1} />
        </Row>
      </Grid>
    </Content>
  );
};

function isStaffReady(staff) {
  if (!staff) return false;
  return (
    staff.name &&
    staff.email &&
    staff.center_id !== -1 &&
    staff.department_id !== -1
  );
}
