import { useContext, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Button,
  Column,
  Content,
  FluidForm,
  Grid,
  Header,
  HeaderContainer,
  HeaderName,
  InlineLoading,
  InlineNotification,
  Row,
  SkipToContent,
  TextInput,
  Tile,
} from "carbon-components-react";
import { UserFilled20 } from "@carbon/icons-react";
import { UserContext } from "../../context";

const LoginHeader = () => (
  <Header aria-label="Cherie header">
    <SkipToContent />

    <HeaderName to="/" element={Link} prefix="璽悅資訊系統">
      <UserFilled20 />
    </HeaderName>
  </Header>
);

const AccountInputProps = {
  className: "some-class",
  id: "account",
  labelText: "使用者帳號",
  placeholder: "您的使用者名稱",
  type: "account",
};

const PasswordProps = {
  className: "some-class",
  id: "password",
  labelText: "密碼",
  type: "password",
};

export const LoginPage = (props) => {
  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const checkButton = useRef();

  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [description, setDescription] = useState("登入中…");
  const [ariaLive, setAriaLive] = useState("off");

  function handleLogin(e) {
    e.preventDefault();

    const user = {
      username: account,
      password: password,
    };

    setMessage("");
    setLoading(true);
    setAriaLive("assertive");

    login(user).then((data) => {
      if (data === undefined) {
        setSuccess(true);
        setDescription("登入失敗");
        return;
      }

      if (data.access) {
        setSuccess(true);
        setDescription("登入成功");
        // Auto redired to home '/'
        navigate("/");
      } else if (data.username) {
        setMessage(`使用者帳號` + data.username);
      } else if (data.password) {
        setMessage(`密碼` + data.password);
      } else if (data.detail) {
        setMessage(`帳號或密碼錯誤`);
      }
      setLoading(false);
      setDescription("登入中…");
      setAriaLive("off");
    });
  }

  return (
    <>
      <HeaderContainer render={LoginHeader} />

      <Content>
        <Grid>
          <Row>
            <Column sm={{ span: 2, offset: 1 }}>
              <Tile>登入系統</Tile>

              <FluidForm>
                <TextInput
                  required
                  {...AccountInputProps}
                  onChange={(e) => setAccount(e.target.value)}
                />

                <TextInput
                  required
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                  {...PasswordProps}
                  onChange={(e) => setPassword(e.target.value)}
                />

                {loading || success ? (
                  <InlineLoading
                    style={{ marginLeft: "1rem" }}
                    description={description}
                    status={success ? "finished" : "active"}
                    aria-live={ariaLive}
                  />
                ) : (
                  <Button type="submit" onClick={handleLogin} ref={checkButton}>
                    登入
                  </Button>
                )}

                {message && (
                  <InlineNotification
                    kind="error"
                    role="alert"
                    title="登入錯誤"
                    subtitle={message}
                  />
                )}
              </FluidForm>
            </Column>
          </Row>
        </Grid>
      </Content>
    </>
  );
};

export default LoginPage;
