import * as A from "./array";
import * as R from "./room";

export function isEqual(a, b) {
  if (a === undefined || b === undefined) {
    return a === b;
  }

  return (
    a.id === b.id &&
    a.book_type === b.book_type &&
    a.bookroom_id === b.bookroom_id &&
    a.checkin_date === b.checkin_date &&
    a.checkout_date === b.checkout_date &&
    a.cost === b.cost &&
    a.employee_id === b.employee_id &&
    a.parkingspace_id === b.parkingspace_id &&
    a.room_id === b.room_id &&
    A.isEqual(a.babybeds, b.babybeds) &&
    R.isEqual(a.room, b.room)
  );
}
