import { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  Checkbox,
  DatePicker,
  DatePickerInput,
  Dropdown,
  DropdownSkeleton,
  FormGroup,
  TextInput,
} from "carbon-components-react";

import { hasId } from "../../utils";
import { Label } from "./Label";
import { NoteArea } from "./NoteArea";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
`;

const Column = styled.div`
  > * {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const BookVisitScheduleEditor = ({
  disabled = false,
  centerList = [],
  roomTypeList = [],
  interactionStatusList = [],
  vsEnum = [],
  service_staff,
  data = {},
  onChange,
}) => {
  const {
    service_employee_id,
    customer_id,
    puerpera_id,
    interactionStatus_id,
    book_date = "",
    visit_schedule_display = "",
    is_rotation = false,
    cold = false,
    special_matters = "",
    baby_room = false,
    baby_floor = 1,
    comment = "",
    centers = [],
    roomTypes = [],
    ...other_data
  } = data;

  const update = useCallback(
    (obj) => ({
      ...other_data,
      service_employee_id,
      customer_id,
      puerpera_id,
      interactionStatus_id,
      book_date,
      visit_schedule_display,
      is_rotation,
      cold,
      special_matters,
      baby_room,
      baby_floor,
      comment,
      roomTypes,
      centers,
      ...obj,
    }),
    [
      service_employee_id,
      customer_id,
      puerpera_id,
      interactionStatus_id,
      book_date,
      visit_schedule_display,
      is_rotation,
      cold,
      special_matters,
      baby_room,
      baby_floor,
      comment,
      other_data,
      roomTypes,
      centers,
    ]
  );

  const centerMap = useMemo(() => {
    let obj = {};
    for (let c of centerList) {
      obj[c.id] = false;
    }
    for (let c of centers) {
      obj[c] = true;
    }
    return obj;
  }, [centerList, centers]);

  const roomTypeMap = useMemo(() => {
    let obj = {};
    for (let c of roomTypeList) {
      obj[c.id] = false;
    }
    for (let c of roomTypes) {
      obj[c] = true;
    }
    return obj;
  }, [roomTypeList, roomTypes]);

  return (
    <Container>
      <Column>
        <FormGroup legendText="希望參觀館別">
          {centerList.map(({ id, name }) => (
            <Checkbox
              key={id}
              id={`centers__${id}`}
              labelText={name}
              checked={centerMap[id]}
              onChange={(value) => {
                if (typeof onChange !== "function") return;
                const newMap = { ...centerMap, [id]: value };
                let centers = [];
                for (let id in newMap) {
                  if (newMap[id]) centers.push(+id);
                }
                onChange(update({ centers }));
              }}
            />
          ))}
        </FormGroup>
        <FormGroup legendText="希望參觀房間類別">
          {roomTypeList.map(({ id, name }) => (
            <Checkbox
              key={id}
              id={`roomTypes__${id}`}
              labelText={name}
              checked={roomTypeMap[id]}
              onChange={(value) => {
                if (typeof onChange !== "function") return;
                const newMap = { ...roomTypeMap, [id]: value };
                let roomTypes = [];
                for (let id in newMap) {
                  if (newMap[id]) roomTypes.push(+id);
                }
                onChange(update({ roomTypes }));
              }}
            />
          ))}
        </FormGroup>
        <FormGroup legendText="其他">
          <Checkbox
            id="bv_schedule__is_rotation"
            labelText="知輪參"
            checked={is_rotation}
            onChange={(value) => {
              if (typeof onChange !== "function") return;
              onChange(update({ is_rotation: value }));
            }}
          />
          <Checkbox
            id="bv_schedule__baby_room"
            labelText="帶看參觀嬰兒室"
            checked={baby_room}
            onChange={(value) => {
              if (typeof onChange !== "function") return;
              onChange(update({ baby_room: value }));
            }}
          />
          <TextInput
            id="bv_schedule__baby_floor"
            disabled={disabled}
            labelText="嬰兒室樓層"
            placeholder="樓層"
            value={baby_floor}
            onChange={(evt) => {
              if (typeof onChange !== "function") return;
              onChange(update({ baby_floor: evt.target.value }));
            }}
          />
          <Checkbox
            id="bv_schedule__is_cold"
            labelText="感冒"
            checked={cold}
            onChange={(value) => {
              if (typeof onChange !== "function") return;
              onChange(update({ cold: value }));
            }}
          />
          <TextInput
            id="bv_schedule__special_matters"
            disabled={disabled}
            labelText="特殊事項"
            placeholder="請輸入特殊事項"
            value={special_matters}
            onChange={(evt) => {
              if (typeof onChange !== "function") return;
              onChange(update({ special_matters: evt.target.value }));
            }}
          />
        </FormGroup>
      </Column>
      <Column>
        <Dropdown
          id="bv_schedule__situation"
          disabled={disabled || interactionStatusList.length === 0}
          titleText={<Label required={true}>互動狀況</Label>}
          label="請選擇"
          selectedItem={interactionStatusList.find(hasId(interactionStatus_id))}
          items={interactionStatusList}
          itemToString={(x) => x.name}
          onChange={(v) => {
            if (typeof onChange !== "function") return;
            onChange(update({ interactionStatus_id: v.selectedItem.id }));
          }}
        />
        <DatePicker
          datePickerType="single"
          disabled={disabled}
          dateFormat="Y/m/d"
          value={book_date}
          onChange={([date = ""]) => {
            if (typeof onChange !== "function") return;
            onChange(update({ book_date: date }));
          }}
        >
          <DatePickerInput
            id="bv_schedule__book_date"
            placeholder="yyyy/mm/dd"
            labelText={<Label required={true}>參觀日期</Label>}
          />
        </DatePicker>
        <Dropdown
          id="bv_schedule__visit_schedule"
          disabled={disabled || vsEnum.length === 0}
          titleText={<Label>參觀時段</Label>}
          label="請選擇"
          selectedItem={visit_schedule_display}
          items={Object.keys(vsEnum).map((k) => vsEnum[k])}
          itemToString={(x) => x}
          onChange={(v) => {
            if (typeof onChange !== "function") return;
            onChange(update({ visit_schedule_display: v.selectedItem }));
          }}
        />
        {service_staff ? (
          <Dropdown
            id="bv_schedule__service_employee"
            disabled={disabled || service_staff.length === 0}
            titleText={<Label required={true}>參觀導覽員工</Label>}
            label="請選擇"
            selectedItem={service_staff.find(hasId(service_employee_id))}
            items={service_staff}
            itemToString={(x) => x.name}
            onChange={(v) => {
              if (typeof onChange !== "function") return;
              onChange(update({ service_employee_id: v.selectedItem.id }));
            }}
          />
        ) : (
          <DropdownSkeleton />
        )}
        <NoteArea
          id="bv_schedule___comment"
          disabled={disabled}
          labelText="備註"
          placeholder="詳細說明"
          value={comment}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ comment: evt.target.value }));
          }}
        />
      </Column>
    </Container>
  );
};
