import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  DataTable,
  DataTableSkeleton,
  Grid,
  Row,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableHead,
  TableHeader,
  TableRow,
} from "carbon-components-react";
import { Edit20 } from "@carbon/icons-react";

import dayjs from "dayjs";
import { DateTimeFormat } from "../../utils/datetime";

import { hasId } from "../../utils";

const headers = [
  {
    key: "interactionStatus",
    header: "互動狀況",
  },
  {
    key: "book_date",
    header: "參訪日期",
  },
  {
    key: "visit_schedule_display",
    header: "參訪時段",
  },
  {
    key: "roomTypes",
    header: "參觀房間類別",
  },
  {
    key: "centers",
    header: "參觀館別",
  },
  {
    key: "actions",
    header: "動作",
  },
];

export const BookVisitScheduleTableViewer = ({
  loading = false,
  isPuerpera = false,
  data = [],
  puerperaID = null,
}) => {
  if (loading) return <DataTableSkeleton headers={headers} />;

  return (
    <DataTable rows={data} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getExpandHeaderProps,
        getRowProps,
        getTableProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                <TableExpandHeader
                  enableExpando={true}
                  {...getExpandHeaderProps()}
                />
                {headers.map((header, i) => (
                  <TableHeader
                    key={i}
                    {...getHeaderProps({ header })}
                    isSortable={header.key !== "actions"}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const rowData = data.find(hasId(row.id));
                return (
                  <React.Fragment key={row.id}>
                    <TableExpandRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => {
                        const type = cell.info.header;
                        const backType = isPuerpera ? "puerpera" : "customer";
                        let visitURL = `/schedule/${rowData?.book_visit_id}/edit/${row.id}?back=${backType}`;
                        if (puerperaID !== null) {
                          visitURL = visitURL.concat(`&p=${puerperaID}`);
                        }
                        let content;
                        switch (type) {
                          case "book_date":
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                            break;
                          case "interactionStatus":
                            content = cell.value?.name;
                            break;
                          case "centers":
                          case "roomTypes":
                            content = cell.value.map((x) => x.name).toString();
                            break;
                          case "actions":
                            content = (
                              <Button
                                kind="ghost"
                                renderIcon={Edit20}
                                iconDescription="瀏覽預約參觀"
                                as={Link}
                                disabled={!rowData?.book_visit_id}
                                to={visitURL}
                              />
                            );
                            break;
                          default:
                            content = cell.value;
                            break;
                        }
                        return <TableCell key={cell.id}>{content}</TableCell>;
                      })}
                    </TableExpandRow>
                    <TableExpandedRow
                      colSpan={headers.length + 1}
                      key={`${row.id}__expanded`}
                    >
                      <Grid>
                        <Row>
                          <Column>
                            <p>
                              立案員工：{" "}
                              {rowData && rowData.keyin_employee
                                ? rowData.keyin_employee.name
                                : ""}
                            </p>
                            <p>
                              參觀導覽員工：{" "}
                              {rowData && rowData.service_employee
                                ? rowData.service_employee.name
                                : ""}
                            </p>
                          </Column>
                          <Column>
                            <p>
                              知輪參：{" "}
                              {rowData && rowData.is_rotation ? "是" : "否"}
                            </p>
                            <p>
                              感冒： {rowData && rowData.cold ? "是" : "否"}
                            </p>
                            <p>
                              特殊事項： {rowData && rowData.special_matters}
                            </p>
                          </Column>
                          <Column>
                            <p>
                              參觀嬰兒室：{" "}
                              {rowData && rowData.baby_room ? "是" : "否"}
                            </p>
                            <p>嬰兒室樓層： {rowData && rowData.baby_floor}</p>
                          </Column>
                        </Row>
                      </Grid>
                      <Grid>
                        <p>備註： {rowData && rowData.comment}</p>
                      </Grid>
                    </TableExpandedRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};
