import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "carbon-components-react";
import { Save16 } from "@carbon/icons-react";

import styled from "styled-components";
import useSWR, { useSWRConfig } from "swr";

import { SchemaEditor } from "../../components/Editor";
import { PuerperaRow } from "../../components/Viewer";
import { useUserData, usePuerpera, useSchemas } from "../../hooks";

import { URL, Fetcher as F } from "../../api";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  > .bx--btn {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const PuerperaSpace = styled.div`
  min-height: 3rem; // same as the Carbon button
`;

export const BabyCreatedOrEditTab = ({ pid }) => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const user = useUserData();

  const puerpera = usePuerpera(pid);

  const { baby: babySchema } = useSchemas();

  const { bid } = useParams();
  const isEdit = !!bid;

  const url = `${URL.baby}${bid}/`;
  const { data: babyData } = useSWR(isEdit ? [url] : null, F.withToken);

  const [updateBaby, setUpdatedBaby] = useState();
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!babyData) return;
    setUpdatedBaby(babyData);
  }, [babyData]);

  const handleSubmit = useCallback(async () => {
    if (isSending) return;
    setIsSending(true);

    let r;
    if (isEdit) {
      const payload = updateBaby;
      r = await F.put(url, payload);
    } else {
      if (!pid) return; // 沒 pid 不給建
      var payload = updateBaby;
      payload["puerpera_id"] = pid;
      r = await F.post(URL.baby, payload);
    }
    mutate([URL.baby + r.id], r, false);
    setIsSending(false);

    navigate(`/puerpera/${pid}/baby/${r.id}`);
  }, [isSending, setIsSending, navigate, mutate, url, updateBaby, isEdit, pid]);

  const isLoading = !updateBaby;
  const checkPuerperaExisted = isEdit ? updateBaby?.puerpera_id : !!pid;

  return (
    <div>
      <PuerperaSpace>
        <PuerperaRow showContract={true} data={puerpera ?? {}} />
      </PuerperaSpace>
      <SchemaEditor
        fields={babySchema.fields}
        excludes={[
          "id",
          "created",
          "modified",
          "bookroom_id",
          "employee_id",
          "puerperaNote_id",
          "puerpera_id",
        ]}
        isLoading={isLoading}
        data={updateBaby}
        onChange={(data) => {
          if (!user) return;
          setUpdatedBaby({ ...data, employee_id: user.employee_id });
        }}
      />
      <Actions>
        <Button
          kind="primary"
          size="small"
          renderIcon={Save16}
          disabled={!checkPuerperaExisted}
          onClick={handleSubmit}
        >
          儲存
        </Button>
      </Actions>
    </div>
  );
};
