import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from "carbon-components-react";
import { AddFilled20, Catalog20 } from "@carbon/icons-react";

import dayjs from "dayjs";
import { DateTimeFormat } from "../../utils/datetime";

const headers = [
  {
    key: "note_first_contact_date",
    header: "首觸日",
  },
  {
    key: "note_edc",
    header: "預產期",
  },
  {
    key: "book_date",
    header: "預約日期",
  },
  {
    key: "centers",
    header: "參觀館別",
  },
  {
    key: "note_fetus",
    header: "胎次",
  },
  {
    key: "status",
    header: "預約參觀狀態",
  },
  {
    key: "actions",
    header: "動作",
  },
];

export const BookVisitTableViewer = ({
  loading = false,
  bookVisits = [],
  puerperaID = null,
}) => {
  if (loading) return <DataTableSkeleton headers={headers} />;

  const data = (bookVisits && bookVisits.results) || [];

  const cleanData = data.map(({ note, status, centers, ...other }) => {
    return {
      ...other,
      centers: centers.map((c) => c.name).toString(),
      status: status?.name ?? "",
      note_edc: note?.edc,
      note_first_contact_date: note?.first_contact_date,
      note_fetus: note?.fetus ?? "",
    };
  });

  return (
    <DataTable rows={cleanData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableHeader
                    key={i}
                    {...getHeaderProps({ header })}
                    isSortable={header.key !== "actions"}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const header = cell.info.header;
                      const bookVisitId = cell.id.split(":")[0];
                      let content;
                      switch (header) {
                        case "book_date":
                        case "note_edc":
                          content = cell.value
                            ? dayjs(cell.value).format(DateTimeFormat.date)
                            : "";
                          break;
                        case "centers":
                        case "note_first_contact_date":
                        case "note_fetus":
                        case "status":
                          content = cell.value;
                          break;
                        case "actions":
                          content = (
                            <div>
                              <Button
                                kind="ghost"
                                renderIcon={Catalog20}
                                iconDescription="瀏覽預約參觀"
                                as={Link}
                                to={`/bookvisit/${bookVisitId}`}
                              />
                              <Button
                                kind="ghost"
                                renderIcon={AddFilled20}
                                iconDescription="新增參訪紀錄"
                                as={Link}
                                to={`/schedule/${bookVisitId}/create?p=${puerperaID}`}
                              />
                            </div>
                          );
                          break;
                        default:
                          content = "?";
                          break;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};
