import { useContext, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Grid,
  Pagination,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
} from "carbon-components-react";
import { Add20, View20, Search16 } from "@carbon/icons-react";

import dayjs from "dayjs";
import useSWR from "swr";
import { DateTimeFormat } from "../../utils/datetime";
import { UserContext, CentersContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import { useQuery } from "../../hooks";
import * as A from "../../utils/array";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

const headers = [
  {
    key: "center",
    name: "館別",
  },
  {
    key: "room",
    name: "房名",
  },
  {
    key: "puerpera",
    name: "產婦",
  },
  {
    key: "baby_bed",
    name: "新生兒床",
  },
  {
    key: "baby_name",
    name: "新生兒姓名",
  },
  {
    key: "checkin_date",
    name: "入住日",
  },
  {
    key: "checkout_date",
    name: "退房日",
  },
  {
    key: "fetus",
    name: "胎次",
  },
  {
    key: "birth",
    name: "幾胞胎",
  },
  {
    key: "actions",
    name: "瀏覽/編輯",
  },
];

const createQueryString = ({
  checkin,
  checkout,
  checkDate,
  keyingKeyword,
  center,
  offset,
  limit,
  roomName,
}) => {
  const params = new URLSearchParams();
  params.append(
    "expand",
    "puerpera,puerperaNote,bookroom.latest_brs.room.center"
  );
  if (checkin !== undefined) {
    params.append("checkin", dayjs(checkin).format(DateTimeFormat.date));
  }
  if (checkout !== undefined) {
    params.append("checkout", dayjs(checkout).format(DateTimeFormat.date));
  }
  if (
    checkDate !== undefined &&
    checkin === undefined &&
    checkout === undefined
  ) {
    params.append("check_date", checkDate);
  }
  if (center !== undefined) {
    const cid = center.id;
    if (cid !== 0) {
      params.append("center", cid);
    }
  }
  if (keyingKeyword !== undefined) {
    params.append("search", keyingKeyword);
  }
  if (roomName !== undefined) {
    params.append("room", roomName);
  }
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  return params.toString();
};

export const CareSearch = () => {
  const { list: centerList } = useContext(CentersContext);

  const q = useQuery();
  const date = q.get("_date");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const [checkDate, setCheckDate] = useState();
  const [center, setCenter] = useState();
  const [keyingKeyword, setKeyingKeyword] = useState();
  const [roomName, setRoomName] = useState();

  const queryString = useMemo(
    () =>
      createQueryString({
        checkin,
        checkout,
        checkDate,
        keyingKeyword,
        center,
        roomName,
        offset,
        limit,
      }),
    [
      checkin,
      checkout,
      checkDate,
      keyingKeyword,
      center,
      roomName,
      offset,
      limit,
    ]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  useEffect(() => {
    if (date !== null) {
      setCheckDate(date);
    } else {
      const today = dayjs().format(DateTimeFormat.date);
      setCheckDate(today);
    }
    // Must reset query to refresh
    setRealyQuery(queryString);
  }, [date, setCheckDate, queryString, setRealyQuery]);

  const {
    token: { access },
  } = useContext(UserContext);
  const url = `${URL.care}?${realyQuery}`;
  const { data } = useSWR(access && [url], F.withToken);

  const isLoading = !data;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/care">護理管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          搜尋
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>護理管理</Tile>

      <Grid>
        <Row>
          <Column md={1}>
            <Dropdown
              id="center-selector"
              titleText="館別"
              label="全部"
              items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
              itemToString={(item) => item.name}
              selectedItem={center}
              onChange={(v) => {
                setCenter(v.selectedItem);
              }}
            />
          </Column>
          <Column>
            <DatePicker
              dateFormat="Y/m/d"
              datePickerType="range"
              onChange={([startDate, endDate]) => {
                setCheckin(startDate);
                setCheckout(endDate);
              }}
            >
              <DatePickerInput
                id="care__search__checkinDate"
                placeholder="yyyy/mm/dd"
                labelText="入住日"
              />
              <DatePickerInput
                id="care__search__checkoutDate"
                placeholder="yyyy/mm/dd"
                labelText="退房日"
              />
            </DatePicker>
          </Column>
          <Column>
            <Search
              labelText="搜尋"
              placeholder="產婦姓名、電話或身份證號"
              onChange={(e) => {
                const v = e.target.value;
                setKeyingKeyword(v);
              }}
            />
          </Column>
          <Column>
            <Search
              placeholder="房名"
              onChange={(e) => {
                const v = e.target.value;
                setRoomName(v);
              }}
            />
          </Column>
          <Column sm={1}>
            <Button
              renderIcon={Search16}
              iconDescription="搜尋"
              hasIconOnly
              onClick={() => {
                setPage(1);
                const queryString = createQueryString({
                  checkin,
                  checkout,
                  checkDate,
                  keyingKeyword,
                  center,
                  roomName,
                  offset: 0,
                  limit,
                });
                setRealyQuery(queryString);
              }}
            ></Button>
          </Column>
        </Row>
      </Grid>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <CareTable
          page={page}
          offset={offset}
          limit={limit}
          data={data}
          onPageChange={({ page, pageSize }) => {
            const limit = pageSize;
            const offset = (page - 1) * limit;
            // offset and limit will not be update checkout the next event loop iteration
            setPage(page);
            setLimit(limit);
            // so we update the real query by ourselves
            const queryString = createQueryString({
              checkin,
              checkout,
              checkDate,
              keyingKeyword,
              center,
              roomName,
              offset,
              limit,
            });
            setRealyQuery(queryString);
          }}
        />
      )}
    </Content>
  );
};

function CareTable({ page, limit, data, onPageChange }) {
  const pageSize = limit;
  const total = (data && data.count) || 0;
  const _data = (data && data.results) || [];

  const cleanData = _data.map(({ puerperaNote, bookroom, name, ...other }) => {
    return {
      ...other,
      center: bookroom?.latest_brs?.room?.center?.name ?? "",
      room: bookroom?.latest_brs?.room?.name ?? "",
      baby_bed: bookroom?.latest_brs?.room?.name ?? "",
      fetus: puerperaNote?.fetus ?? "",
      birth: puerperaNote?.birth ?? "",
      baby_name: name ?? "",
    };
  });

  const pageProps = {
    disabled: !data,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  return (
    <DataTable rows={cleanData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button renderIcon={View20} as={Link} to="/care/summary">
                每日所有新生兒統計
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    key={header.key}
                    {...getHeaderProps({ header })}
                    isSortable={header.key !== "actions"}
                  >
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const { header = "" } = cell && cell.info;
                    const babyID = cell.id.split(":")[0];
                    let content;
                    /* let center; */
                    /* if (header === "contract.center") console.log(cell.value); */
                    switch (header) {
                      case "checkin_date":
                      case "checkout_date":
                        if (cell.value !== null) {
                          content = dayjs(cell.value).format(
                            DateTimeFormat.date
                          );
                        } else {
                          content = cell.value;
                        }
                        break;
                      case "puerpera":
                        content = (
                          <Link to={`/puerpera/${cell.value?.id}/info`}>
                            {cell.value?.name}
                          </Link>
                        );
                        break;
                      case "center":
                      case "room":
                      case "baby_bed":
                      case "baby_name":
                      case "fetus":
                      case "birth":
                        content = cell.value;
                        break;
                      case "actions":
                        content = (
                          <div>
                            <Button
                              kind="ghost"
                              renderIcon={View20}
                              iconDescription="瀏覽"
                              as={Link}
                              to={`/care/${babyID}`}
                            />
                            <Button
                              kind="ghost"
                              renderIcon={Add20}
                              iconDescription="編輯"
                              as={Link}
                              to={`/care/${babyID}/createBabyStatus`}
                            />
                          </div>
                        );
                        break;
                      default:
                        content = <span>?</span>;
                    }
                    return <TableCell key={cell.id}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
