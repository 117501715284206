import { useParams, Link } from "react-router-dom";
import { Button } from "carbon-components-react";
import { RequestQuote16, Undo16 } from "@carbon/icons-react";

import styled from "styled-components";
import useSWR from "swr";

import { PuerperaRow, SchemaViewer } from "../../components/Viewer";
import { usePuerpera, useSchemas } from "../../hooks";

import { URL, Fetcher as F } from "../../api";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  > .bx--btn {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const PuerperaSpace = styled.div`
  min-height: 3rem; // same as the Carbon button
`;

export const NoteInfoTab = ({ pid }) => {
  const { nid } = useParams();

  const puerpera = usePuerpera(pid);
  const bvURL = `${URL.bookVisit.note}${nid}/?expand=mom_food_allergies,mom_medical_history`;
  const { data: note } = useSWR([bvURL], F.withToken);

  const { bookVisitNote: puerperaNoteSchema } = useSchemas();

  const isLoading = !note;

  return (
    <div>
      <PuerperaSpace>
        <PuerperaRow showContract={true} data={puerpera ?? {}} />
      </PuerperaSpace>
      <SchemaViewer
        fields={puerperaNoteSchema.fields}
        excludes={[
          "id",
          "created",
          "modified",
          "puerpera_id",
          "keyin_employee_id",
        ]}
        isLoading={isLoading}
        data={note}
      />
      <Actions>
        <Button
          as={Link}
          to={`/puerpera/${pid}/noteEdit/${nid}`}
          kind="primary"
          size="small"
          renderIcon={RequestQuote16}
        >
          編輯
        </Button>
        <Button
          as={Link}
          to={`/puerpera/${pid}/notes`}
          kind="primary"
          size="small"
          renderIcon={Undo16}
        >
          返回相關資訊清單
        </Button>
      </Actions>
    </div>
  );
};
