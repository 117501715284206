import { useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  Dropdown,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Toggle,
} from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";
import useSWR from "swr";

import { CentersContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

const roomHeaders = [
  {
    key: "center",
    name: "館別",
    header: "館別",
  },
  {
    key: "floor",
    name: "樓層",
    header: "樓層",
  },
  {
    key: "name",
    name: "房間名稱",
    header: "房間名稱",
  },
  {
    key: "roomType",
    name: "房型",
    header: "房型",
  },
  {
    key: "status",
    name: "狀態",
    header: "狀態",
  },
  {
    key: "note",
    name: "備註",
    header: "備註",
  },
];

export const RoomList = () => {
  const { list: centerList } = useContext(CentersContext);

  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(defaultPageSize);
  const [center, setCenter] = useState();

  const queryString = useMemo(() => {
    const params = new URLSearchParams();
    params.append("expand", "center,roomType");
    if (offset !== undefined) {
      params.append("offset", offset);
    }
    if (limit !== undefined) {
      params.append("limit", limit);
    }
    if (center !== undefined) {
      const cid = center.id;
      params.append("center", cid !== 0 ? cid : "");
    }
    return params.toString();
  }, [offset, limit, center]);

  const url = `${URL.room}?${queryString}`;
  const { data: rooms } = useSWR([url], F.withToken);

  const isLoading = !rooms;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/">首頁</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          房間狀態
        </BreadcrumbItem>
      </Breadcrumb>

      <Grid>
        <Column md={2}>
          <Dropdown
            id="rooms-center-selector"
            titleText="館別"
            label="全部"
            items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
            itemToString={(item) => item.name}
            selectedItem={center}
            onChange={(v) => {
              setPage(1);
              setOffset(0);
              setCenter(v.selectedItem);
            }}
          />
        </Column>
      </Grid>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <RoomListTable
          rooms={rooms ?? []}
          page={page}
          pageSize={limit}
          onPageChange={({ page, pageSize }) => {
            setPage(page);
            setOffset((page - 1) * pageSize);
            setLimit(pageSize);
          }}
        />
      )}
    </Content>
  );
};

function RoomListTable({
  page,
  pageSize,
  onPageChange,
  rooms,
  roomTypes,
  centers,
}) {
  const total = (rooms && rooms.count) || 0;
  const data = (rooms && rooms.results) || [];

  const cleanData = data.map(({ center, roomType, ...other }) => {
    return {
      ...other,
      center: center?.name ?? "",
      roomType: roomType?.name ?? "",
    };
  });

  const pageProps = () => ({
    disabled: !rooms,
    page: page,
    totalItems: total,
    pagesUnknown: false,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  });

  return (
    <DataTable rows={cleanData} headers={roomHeaders}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        onInputChange,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button renderIcon={Add16} as={Link} to="/room/create">
                新增房間
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    key={header.key}
                    {...getHeaderProps({ header })}
                    isSortable={header.key !== "note"}
                  >
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const { header = "" } = cell && cell.info;
                    var content = cell.value;
                    if (header === "name") {
                      content = (
                        <Link to={`/room/${row.id}`}>{cell.value}</Link>
                      );
                    } else if (header === "center") {
                      content = cell.value;
                    } else if (header === "roomType") {
                      content = cell.value;
                    } else if (header === "status") {
                      content = (
                        <Toggle
                          id={`t-${cell.id}`}
                          labelA="關閉"
                          labelB="開啟"
                          toggled={cell.value === 1}
                        />
                      );
                    }
                    return <TableCell key={cell.id}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination {...pageProps()} />
        </TableContainer>
      )}
    </DataTable>
  );
}
