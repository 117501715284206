import { useMemo } from "react";
import { FormGroup, Checkbox, CheckboxSkeleton } from "carbon-components-react";
import { Label } from "../Label";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../../api";

export const BabyBed = ({
  id,
  className,
  style,
  field = {},
  data = [],
  filter,
  onChange,
}) => {
  const { key, required = false } = field;
  const { title } = field.schema;
  const { center } = filter;

  const url = `${URL.babybed}?center=${center?.id ?? ""}`;
  const { data: babybeds } = useSWR([url], F.withToken);
  const babybedMap = useMemo(() => {
    let obj = {};
    if (!babybeds) return obj;

    for (let b of babybeds) {
      obj[b.id] = false;
    }
    for (let id of data) {
      obj[id] = true;
    }
    return obj;
  }, [babybeds, data]);

  const isLoading = !babybeds;

  return (
    <FormGroup
      id={id}
      className={className}
      style={style}
      legendText={<Label required={required}>{title}</Label>}
    >
      {isLoading ? (
        <>
          <CheckboxSkeleton />
          <CheckboxSkeleton />
          <CheckboxSkeleton />
        </>
      ) : (
        babybeds.map((babybed) => {
          const bid = `${id}_${babybed.id}`;
          return (
            <Checkbox
              key={bid}
              id={bid}
              className={className}
              labelText={`${babybed.name}`}
              checked={babybedMap[babybed.id]}
              onChange={(value) => {
                const newMap = { ...babybedMap, [babybed.id]: value };
                let newBabybeds = [];
                for (let b of babybeds) {
                  if (newMap[b.id]) newBabybeds.push(b.id);
                }
                if (typeof onChange !== "function") return;
                onChange({ [key]: newBabybeds });
              }}
            />
          );
        })
      )}
    </FormGroup>
  );
};
