import { useContext, useMemo } from "react";
import { UserContext } from "../context";
import jwt_decord from "jwt-decode";

export const useUserData = () => {
  const { token } = useContext(UserContext);
  return useMemo(() => {
    if (!token.access) return null;
    const payloadData = jwt_decord(token.access);
    const { token_type, exp, jti, ...userData } = payloadData;
    return userData;
  }, [token.access]);
};
