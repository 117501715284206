import useSWR from "swr";
import { URL, Fetcher as F } from "../api";

export const usePuerpera = (pid) => {
  var isFetch = pid !== null && pid !== "";
  const puerperaURL = `${URL.puerpera}${pid}/?expand=last_contract`;
  const { data } = useSWR(isFetch ? [puerperaURL] : null, F.withToken, {
    revalidateOnFocus: false,
  });
  return data;
};
