import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  TextInputSkeleton,
  Tile,
} from "carbon-components-react";
import { Save16, Undo16 } from "@carbon/icons-react";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import * as S from "../../utils/schema";
import { SchemaEditor } from "../../components/Editor";
import { SchemaContext, UserContext } from "../../context";
import { useUserData, usePuerperaAndCenter } from "../../hooks";
import { FoodVendorRow } from "../../components/Viewer";

const EditorWrapper = styled.div`
  margin: 16px;
`;

const OrderRowWrapper = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 24px;
`;

const excludeFields = [
  "id",
  "created",
  "modified",
  "employee_id",
  "housing",
  "activate_date",
  "deactivate_date",
  "status",
  "order_beverage",
  "order_beverage_list",
];

export const BeverageCreateOrEdit = () => {
  const { hid, bhid } = useParams();

  const {
    token: { access },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const user = useUserData();

  const { beverageHistory: schema, orderBeverage: schemaOfOrderBeverage } =
    useContext(SchemaContext) || {};

  const isEdit = !!bhid;

  const [puerpera, puerperaDisplay, center] = usePuerperaAndCenter({
    housing: hid,
  });

  const [currentFoodVendor, setCurrentFoodVendor] = useState();

  const foodVendorAttrURL = useMemo(() => {
    if (!center || !currentFoodVendor) return "";
    return `${URL.foodVendorAttr}?expand=foodvendor,default_beverages.meal_type&center=${center.id}&foodvendor=${currentFoodVendor?.id}`;
  }, [center, currentFoodVendor]);
  const { data: foodVendorAttrData } = useSWR(
    access && [foodVendorAttrURL],
    F.withToken
  );

  const changeFVHURL = `${URL.changeFoodVendorHistory}?expand=foodvendor&status=1&housing=${hid}&limit=1`;
  const { data: rawFoodVendorData } = useSWR(
    access && [changeFVHURL],
    F.withToken
  );

  const historyURL = `${URL.beverageHistory}${bhid}?expand=order_beverage`;
  const { data: historyData } = useSWR(
    isEdit ? access && [historyURL] : null,
    F.withToken
  );

  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!rawFoodVendorData || rawFoodVendorData == null) {
      return;
    }
    // 找到 reason 是空的，為第一個
    const findFirst = (fv) => fv.reason === null;
    var firstFV = rawFoodVendorData.find(findFirst);
    if (firstFV === undefined) {
      return;
    }
    // TODO: not only use firstFV
    setCurrentFoodVendor(firstFV.foodvendor ?? {});
  }, [rawFoodVendorData]);

  const [orderBeverageList, setOrderBeverageList] = useState([]);
  const orderBeverageRecord = useMemo(() => {
    const record = {};
    for (const ob of orderBeverageList) {
      record[ob.beverage] = ob;
    }
    return record;
  }, [orderBeverageList]);

  const [beverageRecord, mealTypeRecord] = useMemo(() => {
    if (!foodVendorAttrData) return [];
    const fva = foodVendorAttrData[0];
    if (!fva) return [];

    const historyRecord = {};
    for (const ob of historyData?.order_beverage ?? []) {
      historyRecord[ob.id] = ob;
    }

    const beverageRecord = {};
    const mealTypeRecord = {};
    for (const ob of fva.default_beverages ?? []) {
      if (historyRecord[ob.id]) {
        beverageRecord[ob.id] = historyRecord[ob.id];
      } else {
        beverageRecord[ob.id] = {
          ...S.createInitialData(schemaOfOrderBeverage),
          is_order: false,
          count: 0,
          beverage: ob.id,
          name: ob.name,
        };
      }

      if (!mealTypeRecord[ob.meal_type.id]) {
        mealTypeRecord[ob.meal_type.id] = ob.meal_type;
      }
    }
    return [beverageRecord, mealTypeRecord];
  }, [historyData, schemaOfOrderBeverage, foodVendorAttrData]);

  const displayOfOrderBeverage = useMemo(() => {
    if (!foodVendorAttrData) return null;

    const fva = foodVendorAttrData[0];
    if (!fva) return null;
    // use meal_type.name to display
    let beverageWithMealType = {};
    for (let mealType of Object.values(mealTypeRecord)) {
      beverageWithMealType[mealType.name] = [];
    }
    for (const ob of fva.default_beverages ?? []) {
      const mealName = ob.meal_type?.name ?? "";
      if (!!mealName) {
        beverageWithMealType[mealName].push(ob);
      }
    }
    return beverageWithMealType;
  }, [foodVendorAttrData, mealTypeRecord]);

  const initialBeverageHistory = useMemo(
    () => S.createInitialData(schema),
    [schema]
  );
  const [beverageHistory, setBeverageHistory] = useState(
    initialBeverageHistory
  );

  useEffect(() => {
    if (!historyData || !isEdit) return;
    // set order for edit
    setOrderBeverageList(historyData.order_beverage);
  }, [isEdit, historyData, setOrderBeverageList, schemaOfOrderBeverage]);

  useEffect(() => {
    if (!historyData) return;
    const { id, order_beverage, start_date, comment } = historyData;
    setBeverageHistory({
      id,
      start_date,
      comment,
      housing: hid,
      employee_id: user.employee_id,
    });
    if (!!isEdit) return;
    // set order for create
    setOrderBeverageList(order_beverage);
  }, [
    hid,
    user,
    historyData,
    setBeverageHistory,
    isEdit,
    setOrderBeverageList,
  ]);

  const isHistoryReady = S.createSimpleValidator(schema, excludeFields);
  const isReady = useMemo(
    () => isHistoryReady(beverageHistory),
    [isHistoryReady, beverageHistory]
  );

  const handleCancel = useCallback(async () => {
    navigate(`/housing/${hid}/beverage`);
  }, [navigate, hid]);

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;
    if (isSending) return;

    const { id, ...o } = beverageHistory;
    const payload = {
      ...o,
      order_beverage: orderBeverageList.map((x) => ({
        ...x,
        count: +x.count,
      })),
    };

    setIsSending(true);
    if (isEdit) {
      await F.put(`${URL.beverageHistory}${id}/`, payload);
    } else {
      await F.post(`${URL.beverageHistory}`, payload);
    }
    setIsSending(false);
    navigate(`/housing/${hid}/beverage`);
  }, [
    navigate,
    hid,
    isReady,
    isEdit,
    isSending,
    setIsSending,
    beverageHistory,
    orderBeverageList,
  ]);

  const handleRowUpdate = useCallback(
    (bid) => (s) => {
      setOrderBeverageList((ss) => {
        const idx = ss.findIndex((x) => x.beverage === bid);
        if (idx === -1) {
          const newOrderBeverage = {
            ...S.createInitialData(schemaOfOrderBeverage),
            ...s,
            beverage: bid,
          };
          return A.append(ss, newOrderBeverage);
        }
        return A.replace(ss, { ...ss[idx], ...s }, idx);
      });
    },
    [schemaOfOrderBeverage, setOrderBeverageList]
  );

  const isEditTitle = isEdit ? "編輯" : "新增";
  const storeButtonTitle = isEdit ? "儲存" : "新增";

  return (
    <Content className="foodVendorReason-createOrEdit">
      <Breadcrumb>
        <BreadcrumbItem href="/housing/">住房管理</BreadcrumbItem>
        <BreadcrumbItem href={`/housing/${hid}`}>
          {puerperaDisplay}
        </BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          變更飲品設定
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>{isEditTitle}更換飲品</Tile>

      <FoodVendorRow puerpera={puerpera} foodVendor={currentFoodVendor} />

      <Grid>
        {/* TODO: change to use ContainedList */}
        {displayOfOrderBeverage ? (
          Object.keys(displayOfOrderBeverage).map((key) => {
            const items = displayOfOrderBeverage[key].map((d) => {
              const bid = d.id ?? `${d.foodvendor}_${d.name}`;
              const obData = {
                ...beverageRecord[bid],
                ...orderBeverageRecord[bid],
              };

              return (
                <OrderRowWrapper>
                  <OrderRow
                    key={bid}
                    id={bid}
                    data={obData}
                    onChange={handleRowUpdate(bid)}
                  />
                </OrderRowWrapper>
              );
            });

            return (
              <Column>
                <Tile>{key}</Tile>
                {items}
              </Column>
            );
          })
        ) : (
          <>
            <TextInputSkeleton />
            <TextInputSkeleton />
            <TextInputSkeleton />
          </>
        )}

        <Column>
          <EditorWrapper>
            <SchemaEditor
              fields={schema.fields}
              excludes={excludeFields}
              data={beverageHistory}
              onChange={(x) => {
                if (!user) return;
                setBeverageHistory({
                  ...x,
                  housing: hid,
                  employee_id: user.employee_id,
                  order_beverage: orderBeverageList,
                });
              }}
            />
          </EditorWrapper>
        </Column>

        <Column>
          <Grid>
            <Row>
              <Column sm={{ span: 1, offset: 1 }}>
                <Button
                  kind="secondary"
                  size="small"
                  renderIcon={Undo16}
                  onClick={handleCancel}
                >
                  取消
                </Button>
              </Column>
              <Column>
                <Button
                  disabled={!isReady}
                  kind="primary"
                  size="small"
                  renderIcon={Save16}
                  onClick={handleSubmit}
                >
                  {storeButtonTitle}
                </Button>
              </Column>
            </Row>
          </Grid>
        </Column>
      </Grid>
    </Content>
  );
};

const OrderRow = ({ id, data, onChange }) => {
  const { orderBeverage: schema } = useContext(SchemaContext) || {};
  const user = useUserData();

  const handleChangeEvent = useCallback(
    (s) => {
      if (typeof onChange !== "function") return;
      onChange(s);
    },
    [onChange]
  );

  return (
    <>
      <Column>品項：{data.name}</Column>
      <Column sm={2}>
        <EditorWrapper>
          <SchemaEditor
            id={`editor_${id}`}
            fields={schema.fields}
            orderFields={["id", "beverage", "is_order"]}
            excludes={["id", "beverage_history", "beverage"]}
            data={data}
            onChange={(x) => {
              if (!user) return;
              handleChangeEvent(x);
            }}
          />
        </EditorWrapper>
      </Column>
    </>
  );
};
