import { useContext, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  Tile,
} from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";
import { SchemaEditor } from "../../components/Editor";
import { SchemaContext } from "../../context";
import { createInitialData, createSimpleValidator } from "../../utils/schema";

import { URL, Fetcher as F } from "../../api";

const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
`;

const Editor = styled(SchemaEditor)`
  width: 640px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

export const RoomCreate = () => {
  const navigate = useNavigate();
  const { room } = useContext(SchemaContext) || {};
  const validate = useMemo(() => createSimpleValidator(room), [room]);
  const initialData = useMemo(() => createInitialData(room), [room]);
  const [data, setData] = useState(initialData);
  const isReady = validate(data);

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;
    const response = await F.post(URL.room, data);
    console.log(`room response: `, response);

    if (response.id) {
      navigate("/room");
    }
  }, [navigate, data, isReady]);

  return (
    <Content className="room-create">
      <Breadcrumb>
        <BreadcrumbItem href="/">系統管理</BreadcrumbItem>
        <BreadcrumbItem href="/room">房間管理</BreadcrumbItem>
      </Breadcrumb>

      <Tile>新增房間</Tile>

      <EditorWrapper>
        <Editor
          fields={room.fields}
          excludes={[
            "id",
            "employee_id",
            "created",
            "modified",
            "activate_date",
            "deactivate_date",
            "employee",
          ]}
          data={data}
          onChange={setData}
        />
      </EditorWrapper>

      <Actions>
        <Button
          disabled={!isReady}
          kind="primary"
          size="small"
          renderIcon={Add16}
          onClick={handleSubmit}
        >
          儲存
        </Button>
      </Actions>
    </Content>
  );
};
