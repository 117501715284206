import { useState } from "react";
import { TextInput } from "carbon-components-react";
import { Label } from "../Label";

export const Input = ({
  id,
  className,
  style,
  field = {},
  data = "",
  onChange,
}) => {
  const { key, required = false, ui } = field;
  const { title, placeholder = "", type, default: defaultValue } = field.schema;
  const disabled = ui["ui:readonly"] ?? false;
  const [shouldValidate, setShouldValidate] = useState(false);
  const invalid = shouldValidate && required && data === "";

  return (
    <TextInput
      id={id}
      className={className}
      style={style}
      labelText={<Label required={required}>{title}</Label>}
      placeholder={placeholder}
      value={data}
      disabled={disabled}
      invalid={invalid}
      invalidText="此為必填欄位"
      onFocus={() => setShouldValidate(false)}
      onBlur={() => setShouldValidate(true)}
      onChange={(evt) => {
        if (typeof onChange !== "function") return;

        let v = evt.target.value;
        if (type === "number") {
          const vIsNumber = !!v && !isNaN(v);
          if (vIsNumber) {
            v = +parseInt(v);
            onChange({ [key]: parseInt(v) });
          } else if (defaultValue !== undefined) {
            onChange({ [key]: defaultValue });
          }
        } else {
          onChange({ [key]: v });
        }
      }}
    />
  );
};
