import styled from "styled-components";
import { Comment } from "./Comment";
import { Item } from "./Item";
import { emptyDepartment, emptyCenter } from "../../utils";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  column-gap: 64px;
  row-gap: 24px;
  grid-template-areas:
    "left right"
    "bottom bottom";
`;

const Column = styled.div`
  > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Left = styled(Column)`
  grid-area: left;
`;

const Right = styled(Column)`
  grid-area: right;
`;

const Bottom = styled(Column)`
  grid-area: bottom;
`;

export const StaffViewer = ({ loading = false, data = {} }) => {
  const {
    name = "",
    tw_identity = "",
    phone = "",
    email = "",
    note = "",
    status = 1,
    username = "",
    department = emptyDepartment,
    center = emptyCenter,
  } = data;

  return (
    <Container>
      <Left>
        <Item labelText="帳號" loading={loading}>
          {username}
        </Item>
        <Item labelText="員工姓名" loading={loading}>
          {name}
        </Item>
        <Item labelText="聯絡電話" loading={loading}>
          {phone}
        </Item>
        <Item labelText="行動電話" loading={loading}>
          {phone}
        </Item>
        <Item labelText="E-Mail" loading={loading}>
          {email}
        </Item>
      </Left>
      <Right>
        <Item labelText="員工身分證號" loading={loading}>
          {tw_identity}
        </Item>
        <Item labelText="館別" loading={loading}>
          {center.name}
        </Item>
        <Item labelText="部門" loading={loading}>
          {department.name}
        </Item>
        <Item labelText="員工狀態" loading={loading}>
          {status ? "在職" : "離職"}
        </Item>
      </Right>
      <Bottom>
        <Comment labelText="備註" loading={loading}>
          {note}
        </Comment>
      </Bottom>
    </Container>
  );
};
