import { useState } from "react";
import { Dropdown, DropdownSkeleton } from "carbon-components-react";
import { Label } from "../Label";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../../api";

export const Room = ({
  id,
  className,
  style,
  filter = {},
  field = {},
  data,
  onChange,
}) => {
  const { center } = filter;
  const { key, required = false } = field;
  const title = "房間名";

  const [shouldValidate, setShouldValidate] = useState(false);
  const invalid = shouldValidate && required && data === undefined;

  const url = `${URL.room}?expand=roomType&center=${center?.id ?? ""}`;
  const { data: roomList } = useSWR([url], F.withToken);
  const list = roomList?.results ?? [];
  const item = (list || []).find((x) => x.id === data);

  return !list ? (
    <DropdownSkeleton id={id} className={className} style={style} />
  ) : (
    <Dropdown
      id={id}
      className={className}
      style={style}
      titleText={<Label required={required}>{title}</Label>}
      label="請選擇"
      items={list}
      itemToString={(x) => `${x.name} (${x.roomType.name})`}
      selectedItem={item}
      invalid={invalid}
      invalidText="此為必填欄位"
      onFocus={() => setShouldValidate(false)}
      onBlur={() => setShouldValidate(true)}
      onChange={(e) => {
        const v = e.selectedItem.id;
        if (typeof onChange !== "function") return;
        onChange({ [key]: v });
      }}
    />
  );
};
