import { useCallback, useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "carbon-components-react";
import { Save16, Undo16 } from "@carbon/icons-react";

import { SchemaEditor } from "../../components/Editor";
import { useUserData, useSchemas } from "../../hooks";

import { useSWRConfig } from "swr";
import { URL, Fetcher as F } from "../../api";
import * as S from "../../utils/schema";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  > .bx--btn {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const NoteAddTab = ({ pid }) => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const user = useUserData();

  const { bookVisitNote: bookVisitNoteSchema } = useSchemas();

  const isBookVisitNoteReady = S.createSimpleValidator(bookVisitNoteSchema);
  const initialbookVisitNote = useMemo(
    () => S.createInitialData(bookVisitNoteSchema),
    [bookVisitNoteSchema]
  );

  const [note, setNote] = useState(initialbookVisitNote);

  const isReady = useCallback(
    () => user && isBookVisitNoteReady(note),
    [user, note, isBookVisitNoteReady]
  );

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;

    const payload = {
      ...note,
      puerpera_id: pid,
    };
    const n = await F.post(URL.bookVisit.note, payload);
    mutate([URL.bookVisit.note + n.id], n, false);
    navigate(`/puerpera/${pid}/noteInfo/${n.id}`);
  }, [navigate, mutate, pid, note, isReady]);

  return (
    <div>
      <SchemaEditor
        fields={bookVisitNoteSchema.fields}
        excludes={[
          "id",
          "puerpera_id",
          "keyin_employee_id",
          "created",
          "modified",
        ]}
        data={note}
        onChange={(data) => {
          if (!user) return;
          setNote({ ...data, keyin_employee_id: user.employee_id });
        }}
      />
      <Actions>
        <Button
          kind="primary"
          size="small"
          renderIcon={Save16}
          onClick={handleSubmit}
        >
          儲存
        </Button>
        <Button
          as={Link}
          to={`/puerpera/${pid}/notes`}
          kind="primary"
          size="small"
          renderIcon={Undo16}
        >
          取消
        </Button>
      </Actions>
    </div>
  );
};
