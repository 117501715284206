import { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "carbon-components-react";
import { RequestQuote16 } from "@carbon/icons-react";

import { SchemaEditor } from "../../components/Editor";
import { SchemaContext } from "../../context";

import useSWR, { useSWRConfig } from "swr";

import { useUserData } from "../../hooks";
import { URL, Fetcher as F } from "../../api";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  > .bx--btn {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EditTab = ({ pid }) => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const user = useUserData();

  const { puerpera: puerperaSchema } = useContext(SchemaContext) || {};

  const puerperaURL = `${URL.puerpera}${pid}/`;
  const { data: puerpera } = useSWR([puerperaURL], F.withToken);

  const [updatedPuerpera, setUpdatedPuerpera] = useState();

  useEffect(() => {
    if (!puerpera) return;
    setUpdatedPuerpera(puerpera);
  }, [puerpera]);

  const isLoading = !puerpera;

  const handleSubmit = useCallback(() => {
    const promise = F.put(puerperaURL, {
      ...updatedPuerpera,
      employee_id: user.employee_id,
    });
    mutate([puerperaURL], promise);

    navigate(`/puerpera/${updatedPuerpera.id}/info`);
  }, [navigate, mutate, user.employee_id, puerperaURL, updatedPuerpera]);

  return (
    <div>
      <SchemaEditor
        disabled={isLoading}
        fields={puerperaSchema.fields}
        excludes={["id", "created", "modified", "employee_id", "customer_id"]}
        requiredFields={["tw_identity"]}
        data={updatedPuerpera}
        onChange={(x) => {
          setUpdatedPuerpera(x);
        }}
      />

      <Actions>
        <Button
          kind="primary"
          size="small"
          renderIcon={RequestQuote16}
          onClick={handleSubmit}
        >
          完成
        </Button>
      </Actions>
    </div>
  );
};
