import { URL } from "./url";

export const login = (user) => {
  return fetch(URL.token, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      return res.json();
    })
    .then((tokens) => {
      // console.debug("tokens", tokens);
      if (tokens.access) {
        localStorage.setItem("user", JSON.stringify(tokens));
      }
      return tokens;
    });
};

export const refresh = () => {
  const user = localStorage.getItem("user");
  if (!user) throw new Error("user not found");

  try {
    const { refresh } = JSON.parse(user);
    if (!refresh) throw new Error("refresh token not found");

    return fetch(URL.refresh, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh }),
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then(({ access }) => {
        const tokens = { access, refresh };
        localStorage.setItem("user", JSON.stringify(tokens));
        return tokens;
      });
  } catch (e) {
    throw new Error("invalid user");
  }
};

export const logout = () => {
  localStorage.removeItem("user");
  return {};
};
