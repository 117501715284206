import { Link } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Button,
} from "carbon-components-react";
import { Add20, Catalog20, RequestQuote20 } from "@carbon/icons-react";
import dayjs from "dayjs";

import { DateTimeFormat } from "../../utils/datetime";
import { usePuerpera, useBaby } from "../../hooks";
import { PuerperaRow } from "../../components/Viewer";

const headers = [
  {
    key: "center",
    header: "館別",
  },
  {
    key: "baby_bed_name",
    header: "新生兒床",
  },
  {
    key: "name",
    header: "新生兒名",
  },
  {
    key: "birthday",
    header: "生日",
  },
  {
    key: "checkin_date",
    header: "入住日",
  },
  {
    key: "checkout_date",
    header: "出住日",
  },
  {
    key: "actions",
    header: "瀏覽/新增",
  },
];

export const BabyTab = ({ pid }) => {
  const puerpera = usePuerpera(pid);
  const babyList = useBaby({ puerpera: pid });

  const cleanData = babyList.map(({ baby_bed, ...other }) => {
    return {
      ...other,
      center: baby_bed?.center ?? "",
      baby_bed_name: baby_bed?.name ?? "",
    };
  });

  return (
    <div>
      <PuerperaRow showContract={true} data={puerpera ?? {}}>
        <Button renderIcon={Add20} as={Link} to="create">
          新增新生兒
        </Button>
      </PuerperaRow>

      <DataTable rows={cleanData} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    {...getHeaderProps({ header })}
                    isSortable={header.key === "sign"}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const type = cell.info.header;
                    let content;
                    switch (type) {
                      case "birthday":
                      case "checkin_date":
                      case "checkout_date":
                        content = dayjs(cell.value).format(DateTimeFormat.date);
                        break;
                      case "center":
                        content = cell.value?.name;
                        break;
                      case "actions":
                        content = (
                          <div>
                            <Button
                              kind="ghost"
                              renderIcon={Catalog20}
                              as={Link}
                              to={`../baby/${row.id}`}
                            />
                            <Button
                              kind="ghost"
                              renderIcon={RequestQuote20}
                              as={Link}
                              to={`../babyEdit/${row.id}`}
                            />
                          </div>
                        );
                        break;
                      default:
                        content = cell.value;
                        break;
                    }
                    return <TableCell key={`${cell.id}`}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DataTable>
    </div>
  );
};
