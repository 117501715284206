import { Link } from "react-router-dom";
import { Button } from "carbon-components-react";
import { Add20 } from "@carbon/icons-react";

import { usePuerpera, useBookVisit } from "../../hooks";

import { BookVisitTableViewer, PuerperaRow } from "../../components/Viewer";

export const HistoryTab = ({ pid }) => {
  const puerpera = usePuerpera(pid);
  const bookVisitList = useBookVisit({ puerpera: pid });

  const isLoading = !bookVisitList;

  return (
    <div>
      <PuerperaRow showContract={true} data={puerpera ?? {}}>
        <Button
          renderIcon={Add20}
          as={Link}
          disabled={pid === null}
          to={`/bookvisit/create?puerpera=${pid}`}
        >
          新增預約參觀
        </Button>
      </PuerperaRow>

      <BookVisitTableViewer
        loading={isLoading}
        bookVisits={bookVisitList}
        puerperaID={pid}
      />
    </div>
  );
};
