import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../hooks";
import { URL, Fetcher as F } from "../../api";
import { SelectPuerpery } from "../Puerpera/SelectPuerpery";

export const HousingSelectPuerpery = () => {
  const user = useUserData();

  const navigate = useNavigate();
  const initHousing = useCallback(
    async (pid) => {
      if (user?.employee_id === 0) {
        console.log(`super user, disabled action`);
        return;
      }

      const payload = { employee_id: user?.employee_id, puerpera_id: pid };
      // post init
      F.post(`${URL.housingInit}`, payload)
        .then((r) => {
          const hid = r.housing_id;
          navigate(`/housing/${hid}/edit`);
        })
        .catch((r) => {
          console.debug(`post housingInit catch error`, r);
        });
    },
    [user, navigate]
  );

  return <SelectPuerpery isHousing={true} targetBlock={initHousing} />;
};
