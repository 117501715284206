import styled from "styled-components";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  SkeletonText,
  Tile,
} from "carbon-components-react";
import { Add20 } from "@carbon/icons-react";
import useSWR from "swr";

import { BookVisitScheduleTableViewer } from "../../components/Viewer";
import { URL, Fetcher as F } from "../../api";

const CustomerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CustomerInfo = styled.div`
  min-height: 3rem; // same as the Carbon button
  line-height: 3rem;
`;

export const BookVisitScheduleHistory = () => {
  const { bvid } = useParams();

  const expandKeys =
    "customer,bvss.roomTypes,bvss.centers,bvss.interactionStatus,bvss.keyin_employee,bvss.service_employee";
  const bvURL = `${URL.bookVisit.list}${bvid}/?expand=${expandKeys}`;

  const { data: bookVisitData = {} } = useSWR([bvURL], F.withToken, {
    revalidateOnFocus: false,
  });

  const { customer } = bookVisitData ?? {};
  const isLoadingCustomer = !bookVisitData || !customer;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/bookvisit">預約參訪</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          歷史參觀記錄
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>歷史參觀記錄</Tile>

      <Grid>
        <CustomerRow>
          {customer && (
            <div>
              <CustomerInfo>
                聯絡人姓名：
                {!isLoadingCustomer ? customer.name : <SkeletonText />}
              </CustomerInfo>
              <CustomerInfo>
                聯絡人手機：
                {!isLoadingCustomer ? customer.mobile_phone : <SkeletonText />}
              </CustomerInfo>
              <CustomerInfo>
                聯絡人電話：
                {!isLoadingCustomer ? customer.phone : <SkeletonText />}
              </CustomerInfo>
            </div>
          )}

          <CustomerInfo sm={1}>
            <Button
              renderIcon={Add20}
              as={Link}
              to={`/schedule/${bvid}/create?by=customer`}
            >
              新增參觀記錄
            </Button>
          </CustomerInfo>
        </CustomerRow>
        <Row>
          <Column>
            <BookVisitScheduleTableViewer
              loading={!bookVisitData}
              data={bookVisitData.bvss}
            />
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
