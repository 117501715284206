import { DatePicker, DatePickerInput } from "carbon-components-react";
import { Label } from "../Label";

import dayjs from "dayjs";
import { DateTimeFormat } from "../../../utils/datetime";

export const Date = ({
  id,
  className,
  style,
  disabled = false,
  field = {},
  data = "",
  dateFormat = DateTimeFormat.date,
  onChange,
}) => {
  const { key, required = false } = field;
  const { title } = field.schema;
  const isDateTime = field.ui["ui:widget"] === "date-time";

  return (
    <DatePicker
      id={id}
      className={className}
      style={style}
      datePickerType="single"
      dateFormat="Y/m/d"
      disabled={disabled}
      value={data}
      onChange={([date = ""]) => {
        if (!date) return;
        if (typeof onChange !== "function") return;
        const d = dayjs(date);
        const v = isDateTime ? d.toISOString() : d.format(dateFormat);
        onChange({ [key]: v });
      }}
    >
      <DatePickerInput
        id={`${id || "date-picker"}__input`}
        placeholder="yyyy/mm/dd"
        labelText={<Label required={required}>{title}</Label>}
      />
    </DatePicker>
  );
};
