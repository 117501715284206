import { Link } from "react-router-dom";
import { Column, Grid, Row } from "carbon-components-react";

import { Item } from "./Item";

export const PuerperaRow = ({
  loading = false,
  showPuerperaLink = false,
  showPhone = false,
  showContract = false,
  data = {},
  children,
}) => {
  const {
    id = "",
    name = "",
    mobile_phone = "",
    phone = "",
    tw_identity = "",
    birthday = "",
    height = "",
    weight = "",
    address = "",
    last_contract,
  } = data ?? {};
  const { contract_number = "" } = last_contract ?? {};

  return (
    <Grid>
      <Row>
        <Column>
          <Item labelText="產婦姓名" showTop={false} loading={loading}>
            {showPuerperaLink && id ? (
              <Link to={`/puerpera/${id}/info`}>{name}</Link>
            ) : (
              name
            )}
          </Item>

          <Item labelText="產婦手機" showTop={false} loading={loading}>
            {mobile_phone}
          </Item>
          {showPhone && (
            <Item labelText="產婦電話" showTop={false} loading={loading}>
              {phone}
            </Item>
          )}
          <Item labelText="身份證號" showTop={false} loading={loading}>
            {tw_identity}
          </Item>
        </Column>

        <Column>
          <Item labelText="生日" showTop={false} loading={loading}>
            {birthday}
          </Item>

          <Item labelText="身高" showTop={false} loading={loading}>
            {height}
          </Item>
          <Item labelText="體重" showTop={false} loading={loading}>
            {weight}
          </Item>
        </Column>

        {showContract && (
          <Column>
            <Item labelText="合約編號" showTop={false} loading={loading}>
              {contract_number}
            </Item>
            <Item labelText="住址" showTop={false} loading={loading}>
              {address}
            </Item>
          </Column>
        )}

        {children !== undefined && <Column>{children}</Column>}
      </Row>
    </Grid>
  );
};
