import { useContext } from "react";
import { Link } from "react-router-dom";
import { Button } from "carbon-components-react";
import { RequestQuote16, Add16 } from "@carbon/icons-react";
import styled from "styled-components";

import { SchemaContext } from "../../context";
import { SchemaViewer } from "../../components/Viewer";

import { usePuerpera, useBookVisit } from "../../hooks";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  > .bx--btn {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const InfoTab = ({ pid }) => {
  const puerpera = usePuerpera(pid);
  const { puerpera: puerperaSchema } = useContext(SchemaContext) || {};
  const isLoading = !puerpera;

  const bookVisit = useBookVisit({ puerpera: pid });
  // check BookVisit for puerpera is created or not
  var bvid = null;
  if (!!bookVisit.results) {
    const first = bookVisit.results[0];
    if (first !== undefined) {
      bvid = first.id;
    }
  }

  return (
    <div>
      <SchemaViewer
        fields={puerperaSchema.fields}
        excludes={[
          "id",
          "created",
          "modified",
          "employee_id",
          "customer_id",
          "bookroom_id",
          "checkin_date",
          "checkout_date",
        ]}
        isLoading={isLoading}
        data={puerpera}
      />

      <Actions>
        <Button
          as={Link}
          to={`/puerpera/${pid}/edit`}
          kind="primary"
          size="small"
          renderIcon={RequestQuote16}
        >
          編輯
        </Button>
        {bvid === null && (
          <Button
            as={Link}
            disabled={isLoading}
            to={`/bookvisit/create?puerpera=${pid}`}
            kind="primary"
            size="small"
            renderIcon={Add16}
          >
            新增預約參訪
          </Button>
        )}
      </Actions>
    </div>
  );
};
