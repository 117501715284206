import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useCustomerBookVisit = (id) => {
  const {
    token: { access },
  } = useContext(UserContext);

  const url = `${URL.bookVisit.customer}${id}/?expand=centers,visit_schedule,first_contact_date`;
  const canFetch = access && id !== null;
  const { data: cbv, error } = useSWR(
    canFetch ? access && [url] : null,
    F.withToken,
    {
      revalidateOnFocus: false,
    }
  );

  if (!cbv || error) {
    return null;
  }
  const { visit_schedule = {}, ...o } = cbv;
  let fixCBV = {
    ...o,
    raw_visit_schedule: visit_schedule,
    visit_schedule: visit_schedule?.name ?? "",
  };

  return fixCBV;
};
