import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const usePuerperaAndCenter = ({ housing = "" }) => {
  const {
    token: { access },
  } = useContext(UserContext);

  const url = `${URL.housing}${housing}/?expand=puerperaNote.puerpera,bookRoom.current_brs.room.center,bookRoom.latest_brs.room.center`;
  const { data = [] } = useSWR(access && [url], F.withToken, {
    revalidateOnFocus: false,
  });

  let brs = undefined;
  const current_brs = data?.bookRoom?.current_brs;
  const latest_brs = data?.bookRoom?.latest_brs;
  if (current_brs !== undefined && current_brs.id !== undefined) {
    brs = current_brs;
  }
  if (latest_brs !== undefined && latest_brs.id !== undefined) {
    brs = latest_brs;
  }

  const puerpera = data?.puerperaNote?.puerpera ?? {};
  const center = brs?.room.center ?? {};
  const center_name = center.name ? `(${center.name})` : "";
  const puerperaDisplay = center
    ? `${center_name} ${puerpera.name ?? ""}`
    : "產婦";

  return [puerpera, puerperaDisplay, center, brs];
};
