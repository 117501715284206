import { useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  BreadcrumbItem,
  Breadcrumb,
  Button,
  Column,
  Content,
  DataTable,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Grid,
  Pagination,
  Row,
  Search,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
} from "carbon-components-react";
import { Add16, AddFilled20, Catalog20, Search16 } from "@carbon/icons-react";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import { CentersContext } from "../../context";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

import dayjs from "dayjs";
import { DateTimeFormat } from "../../utils/datetime";

const headers = [
  {
    key: "last_contract_sign",
    name: "簽約日",
  },
  {
    key: "last_contract_center",
    name: "合約館別",
  },
  {
    key: "name",
    name: "產婦",
  },
  {
    key: "mobile_phone",
    name: "產婦電話",
  },
  {
    key: "last_contract_number",
    name: "合約編號",
  },
  {
    key: "employee",
    name: "建檔人員",
  },
  {
    key: "last_contract_reason",
    name: "退訂原因",
  },
  {
    key: "visit",
    name: "相關記錄",
  },
];

const createQueryString = ({
  offset,
  limit,
  keyingKeyword,
  contractCenter,
  signDate,
  employeeName,
}) => {
  const params = new URLSearchParams();
  params.append("expand", "employee,last_contract.center");
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  if (keyingKeyword !== undefined) {
    params.append("search", keyingKeyword);
  }
  if (contractCenter !== undefined) {
    const cid = contractCenter.id;
    if (cid !== 0) {
      params.append("contract_center", cid);
    }
  }
  if (signDate !== undefined) {
    params.append("contract_sign", dayjs(signDate).format(DateTimeFormat.date));
  }
  if (employeeName !== undefined) {
    params.append("employee", employeeName);
  }
  return params.toString();
};

export const PuerperaSearch = () => {
  const { list: centerList } = useContext(CentersContext);

  const [limit, setLimit] = useState(defaultPageSize);
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  const [contractCenter, setContractCenter] = useState();
  const [signDate, setSignDate] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [keyingKeyword, setKeyingKeyword] = useState();

  const queryString = useMemo(
    () =>
      createQueryString({
        offset,
        limit,
        keyingKeyword,
        contractCenter,
        signDate,
        employeeName,
      }),
    [offset, limit, keyingKeyword, contractCenter, signDate, employeeName]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const searchURL = `${URL.puerpera}?${realyQuery}`;
  const {
    data: puerperaList,
    error,
    isValidating,
  } = useSWR([searchURL], F.withToken, {
    revalidateOnFocus: false,
  });

  const hasResponse = !isValidating && !error && puerperaList;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/puerpera">產婦管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          搜尋產婦
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>產婦</Tile>
      <Grid>
        <Row>
          <Column md={2}>
            <Search
              labelText="搜尋產婦"
              placeholder="電話、產婦姓名、合約編號"
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  setRealyQuery(
                    createQueryString({
                      offset,
                      limit,
                      keyingKeyword,
                      contractCenter,
                      signDate,
                      employeeName,
                    })
                  );
                }
              }}
              onChange={(e) => {
                const v = e.target.value;
                setKeyingKeyword(v);
              }}
            />
          </Column>
          <Column>
            <DatePicker
              dateFormat="Y/m/d"
              datePickerType="single"
              onChange={([date]) => {
                setSignDate(date);
              }}
            >
              <DatePickerInput
                id="puerpera__signDate"
                placeholder="yyyy/mm/dd"
                labelText="簽約日期"
              />
            </DatePicker>
          </Column>
          <Column md={1}>
            <Dropdown
              id="center-selector"
              titleText="簽約館別"
              label="全部"
              items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
              itemToString={(item) => item.name}
              selectedItem={contractCenter}
              onChange={(v) => {
                setContractCenter(v.selectedItem);
              }}
            />
          </Column>
          <Column>
            <Search
              labelText="建檔人員"
              placeholder="建檔人員"
              onChange={(e) => {
                const v = e.target.value;
                setEmployeeName(v);
              }}
            />
          </Column>
          <Column>
            <Button
              renderIcon={Search16}
              iconDescription="搜尋"
              hasIconOnly
              onClick={() => {
                setPage(1);
                setRealyQuery(
                  createQueryString({
                    offset: 0,
                    limit,
                    keyingKeyword,
                    contractCenter,
                    signDate,
                    employeeName,
                  })
                );
              }}
            ></Button>
          </Column>
        </Row>
      </Grid>

      {hasResponse ? (
        <PuerperaTable
          page={page}
          pageSize={limit}
          puerperas={puerperaList}
          onPageChange={({ page, pageSize }) => {
            // offset and limit will not be update until the next event loop iteration
            const limit = pageSize;
            const offset = (page - 1) * limit;
            setPage(page);
            setLimit(limit);
            // so we update the real query by ourselves
            setRealyQuery(
              createQueryString({
                offset,
                limit,
                keyingKeyword,
                contractCenter,
                signDate,
                employeeName,
              })
            );
          }}
        />
      ) : (
        <DataTableSkeleton showHeader={false} />
      )}
    </Content>
  );
};

function PuerperaTable({ page, pageSize, puerperas, onPageChange }) {
  const total = (puerperas && puerperas.count) || 0;
  const data = (puerperas && puerperas.results) || [];

  const pageProps = {
    disabled: !puerperas,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  const cleanData = data.map(({ last_contract, employee, ...other }) => {
    return {
      ...other,
      employee: employee?.name ?? "",
      last_contract_center: last_contract?.center?.name ?? "",
      last_contract_number: last_contract?.contract_number ?? "",
      last_contract_sign: last_contract?.sign ?? null,
      last_contract_reason: last_contract?.reason ?? "",
    };
  });

  return (
    <DataTable rows={cleanData} headers={headers} isSortable>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button renderIcon={Add16} as={Link} to="/bookvisit/create">
                新增預約參訪
              </Button>
              <Button renderIcon={Add16} as={Link} to="/puerpera/create">
                新增產婦
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    key={header.key}
                    {...getHeaderProps({
                      header,
                      isSortable: header.key === "last_contract_sign",
                    })}
                  >
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const { header = "" } = cell && cell.info;
                    let content;
                    const pID = cell.id.split(":")[0];
                    switch (header) {
                      case "mobile_phone":
                      case "name":
                      case "employee":
                      case "last_contract_center":
                      case "last_contract_number":
                      case "last_contract_reason":
                        content = cell.value;
                        break;
                      case "last_contract_sign":
                        if (cell.value !== null) {
                          content = dayjs(cell.value).format("YYYY-MM-DD");
                        } else {
                          content = <span></span>;
                        }
                        break;
                      case "visit":
                        content = (
                          <div>
                            <Button
                              kind="ghost"
                              renderIcon={Catalog20}
                              as={Link}
                              to={`/puerpera/${pID}/info`}
                            />
                            <Button
                              kind="ghost"
                              renderIcon={AddFilled20}
                              as={Link}
                              to={`/bookvisit/create?puerpera=${pID}`}
                            />
                          </div>
                        );
                        break;
                      default:
                        content = <span>?</span>;
                    }
                    return <TableCell key={cell.key}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
