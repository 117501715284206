import { useContext, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Grid,
  Pagination,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  InlineLoading,
} from "carbon-components-react";
import {
  Add16,
  DocumentExport16,
  Edit20,
  View20,
  Search16,
  Launch16,
} from "@carbon/icons-react";

import dayjs from "dayjs";
import useSWR from "swr";
import { DateTimeFormat } from "../../utils/datetime";
import { UserContext, CentersContext, SchemaContext } from "../../context";
import { useUserData } from "../../hooks";
import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

const headers = [
  {
    key: "created",
    name: "建立資料時間",
  },
  {
    key: "center",
    name: "簽約館別",
  },
  {
    key: "contract_num",
    name: "合約編號",
  },
  {
    key: "name",
    name: "媽媽姓名",
  },
  {
    key: "mobile_phone",
    name: "聯絡電話",
  },
  {
    key: "visit",
    name: "瀏覽/編輯",
  },
];

const createQueryString = ({
  since,
  until,
  keyingKeyword,
  bookType,
  center,
  offset,
  limit,
}) => {
  const params = new URLSearchParams();
  params.append("expand", "puerpera,employee,contract.center");
  if (since !== undefined) {
    params.append("since", dayjs(since).format(DateTimeFormat.date));
  }
  if (until !== undefined) {
    params.append("until", dayjs(until).format(DateTimeFormat.date));
  }
  if (center !== undefined) {
    const cid = center.id;
    if (cid !== 0) {
      params.append("center", cid);
    }
  }
  if (bookType !== undefined) {
    const btId = bookType.id;
    if (btId !== 0) {
      params.append("book_type", btId);
    }
  }
  if (keyingKeyword !== undefined) {
    params.append("search", keyingKeyword);
  }
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  params.append("c", "basic");
  return params.toString();
};

export const BookRoomSearch = () => {
  const { list: centerList } = useContext(CentersContext);
  const { bookRoomSchedule: brsSchema } = useContext(SchemaContext) || {};

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const [since, setSince] = useState();
  const [until, setUntil] = useState();
  const [center, setCenter] = useState();
  const [bookType, setBookType] = useState();
  const [keyingKeyword, setKeyingKeyword] = useState();
  const [isExporting, setIsExporting] = useState(false);
  const [exportStatus, setExportStatus] = useState("off");
  const [exportURL, setExportURL] = useState("");

  const queryString = useMemo(
    () =>
      createQueryString({
        since,
        until,
        keyingKeyword,
        bookType,
        center,
        offset,
        limit,
      }),
    [since, until, keyingKeyword, bookType, center, offset, limit]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const {
    token: { access },
  } = useContext(UserContext);

  const bookVisitURL = `${URL.book.room}?${realyQuery}`;
  const { data } = useSWR(access && [bookVisitURL], F.withToken);

  const isLoading = !data;

  const handleExport = useCallback(async () => {
    setExportStatus("active");
    setIsExporting(true);
    const url = `${URL.export.uploadBookRoom}?${queryString}`;
    F.withToken(url)
      .then((r) => {
        // console.debug(`uploadBookRoom success`, r);
        setExportStatus("finished");
        setIsExporting(false);
        if (r.sheet_url !== undefined) {
          setExportURL(r.sheet_url);
        }
      })
      .catch((r) => {
        // console.debug(`uploadBookRoom catch error`, r);
        setExportStatus("error");
        setIsExporting(false);
      });
  }, [queryString, setIsExporting, setExportStatus, setExportURL]);

  const bookTypeSchema = useMemo(() => {
    if (brsSchema === undefined) {
      return [];
    }
    const bookTypeField = brsSchema.fields.find(
      (item) => item.key === "book_type"
    );
    if (bookTypeField !== undefined) {
      var all = [{ id: 0, name: "全部" }];
      const _results = bookTypeField.schema.enum.map((x, index) => {
        return {
          id: x,
          name: bookTypeField.schema.enumNames[index],
        };
      });
      return all.concat(_results);
    }
    return [];
  }, [brsSchema]);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/bookroom">訂房/排房管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          搜尋
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>
        <Grid>
          <Row>
            <Column md={1}>
              <Dropdown
                id="center-selector"
                titleText="館別"
                label="全部"
                items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
                itemToString={(item) => item.name}
                selectedItem={center}
                onChange={(v) => {
                  setCenter(v.selectedItem);
                }}
              />
            </Column>
            <Column md={1}>
              <Dropdown
                id="book_type-selector"
                titleText="種類"
                label="全部"
                items={bookTypeSchema}
                itemToString={(item) => item.name ?? ""}
                selectedItem={bookType}
                onChange={(v) => {
                  setBookType(v.selectedItem);
                }}
              />
            </Column>
            <Column>
              <DatePicker
                dateFormat="Y/m/d"
                datePickerType="range"
                onChange={([startDate, endDate]) => {
                  setSince(startDate);
                  setUntil(endDate);
                }}
              >
                <DatePickerInput
                  id="book-room__search__startDate"
                  placeholder="yyyy/mm/dd"
                  labelText="起始日期"
                />
                <DatePickerInput
                  id="book-room__search__endDate"
                  placeholder="yyyy/mm/dd"
                  labelText="結束日期"
                />
              </DatePicker>
            </Column>
            <Column>
              <Search
                size="lg"
                labelText="搜尋"
                placeholder="產婦姓名、電話或身份證號"
                onChange={(e) => {
                  const v = e.target.value;
                  setKeyingKeyword(v);
                }}
              />
            </Column>
            <Column sm={1}>
              <Button
                renderIcon={Search16}
                iconDescription="搜尋"
                hasIconOnly
                onClick={() => {
                  setPage(1);
                  const queryString = createQueryString({
                    since,
                    until,
                    keyingKeyword,
                    bookType,
                    center,
                    offset: 0,
                    limit,
                  });
                  setRealyQuery(queryString);
                }}
              ></Button>
            </Column>
            <Column>
              <Button renderIcon={DocumentExport16} onClick={handleExport}>
                匯出訂房/排房表
                {(isExporting || exportStatus !== "off") && (
                  <InlineLoading status={exportStatus} />
                )}
              </Button>
              {(isExporting || exportStatus !== "off") && (
                <Button
                  renderIcon={Launch16}
                  iconDescription="匯出訂房/排房表網址"
                  hasIconOnly
                  disabled={exportURL === ""}
                  as="a"
                  target="_blank"
                  href={exportURL}
                />
              )}
            </Column>
          </Row>
        </Grid>
      </Tile>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <BookRoomTable
          page={page}
          offset={offset}
          limit={limit}
          bookrooms={data}
          onPageChange={({ page, pageSize }) => {
            const limit = pageSize;
            const offset = (page - 1) * limit;
            // offset and limit will not be update until the next event loop iteration
            setPage(page);
            setLimit(limit);
            // so we update the real query by ourselves
            const queryString = createQueryString({
              since,
              until,
              keyingKeyword,
              bookType,
              center,
              offset,
              limit,
            });
            setRealyQuery(queryString);
          }}
        />
      )}
    </Content>
  );
};

function BookRoomTable({ page, limit, bookrooms, onPageChange }) {
  const pageSize = limit;
  const total = (bookrooms && bookrooms.count) || 0;
  const data = (bookrooms && bookrooms.results) || [];

  const cleanData = data.map(({ puerpera, employee, contract, ...other }) => {
    return {
      ...other,
      name: puerpera?.name ?? "",
      mobile_phone: puerpera?.mobile_phone ?? "",
      service_employee: employee?.name ?? "",
      center: contract?.center?.name ?? "",
      contract_num: contract?.contract_number ?? "",
      visit: puerpera?.id ?? "",
    };
  });

  const pageProps = {
    disabled: !bookrooms,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  const user = useUserData();

  return (
    <DataTable rows={cleanData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
            <TableToolbarContent>
              <Button
                renderIcon={Add16}
                as={Link}
                to="/bookroom/selectPuerpera"
              >
                新增訂房/排房
              </Button>
            </TableToolbarContent>
          </TableToolbar>

          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const { header = "" } = cell && cell.info;
                    const bvid = cell.id.split(":")[0];
                    let content;
                    /* let center; */
                    /* if (header === "contract.center") console.log(cell.value); */
                    switch (header) {
                      case "book_date":
                      case "created":
                        if (cell.value !== null) {
                          content = dayjs(cell.value).format(
                            DateTimeFormat.date
                          );
                        } else {
                          content = cell.value;
                        }
                        break;
                      case "mobile_phone":
                      case "name":
                      case "service_employee":
                      case "status":
                      case "center":
                      case "contract_num":
                        content = cell.value;
                        break;
                      case "visit":
                        content = (
                          <div>
                            <Button
                              kind="ghost"
                              renderIcon={View20}
                              iconDescription="瀏覽"
                              as={Link}
                              to={`/bookroom/${bvid}`}
                            />
                            {user["e_book"] && (
                              <Button
                                kind="ghost"
                                renderIcon={Edit20}
                                iconDescription="編輯"
                                as={Link}
                                to={`/bookroom/${cell.value}/edit`}
                              />
                            )}
                          </div>
                        );
                        break;
                      default:
                        content = <span>?</span>;
                    }
                    return <TableCell key={cell.id}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
