import { Column, Row, Grid } from "carbon-components-react";
import styled from "styled-components";

import { Comment } from "./Comment";
import { Item } from "./Item";
import { PuerperaRow } from "./PuerperaRow";

const ScheduleItem = styled.div`
  margin: 24px;
`;

export const BookRoomViewer = ({ loading = false, data = {} }) => {
  const {
    puerpera = {
      name: "",
      mobile_phone: "",
      phone: "",
    },
    comment = "",
    schedules = [],
    contract,
  } = data;

  return (
    <div>
      <PuerperaRow showPuerperaLink={true} data={puerpera} loading={loading} />

      <Grid>
        <Column sm={1} md={2} lg={4}>
          <Item labelText="最後更新的合約編號" loading={loading}>
            {contract && contract.contract_number}
          </Item>
        </Column>
      </Grid>

      {schedules.map((s) => (
        <ScheduleItem>
          <Grid>
            <Row>
              <Column>
                <Item labelText="種類" loading={loading}>
                  {s.book_type === 1
                    ? "訂房"
                    : s.book_type === 2
                    ? "排房"
                    : "退房"}
                </Item>
              </Column>
              <Column>
                <Item labelText="館別" loading={loading}>
                  {s.room.center.name}
                </Item>
              </Column>
              <Column>
                <Item labelText="房間" loading={loading}>
                  {s.room.name}
                </Item>
              </Column>
              <Column>
                <Item labelText="入住日期" loading={loading}>
                  {s.checkin_date}
                </Item>
              </Column>
              <Column>
                <Item labelText="退房日期" loading={loading}>
                  {s.checkout_date}
                </Item>
              </Column>
              <Column>
                <Item labelText="天數" loading={loading}>
                  {s.book_days}
                </Item>
              </Column>
            </Row>

            <Row>
              <Column>
                <Item labelText="房型" loading={loading}>
                  {s.room.roomType?.name ?? ""}
                </Item>
              </Column>
              <Column>
                <Item labelText="樓層" loading={loading}>
                  {s.room.floor}
                </Item>
              </Column>
              <Column>
                <Item labelText="嬰兒床" loading={loading}>
                  {s.babybeds.map((b) => `${b.name},`)}
                </Item>
              </Column>
              <Column>
                <Item labelText="車位" loading={loading}>
                  {s.parkingspace?.name}
                </Item>
              </Column>
              <Column>
                <Item labelText="每日住房費用金額" loading={loading}>
                  {s.cost}
                </Item>
              </Column>
              <Column>
                <Item labelText="總金額" loading={loading}>
                  {s.cost_sum}
                </Item>
              </Column>
            </Row>

            <Row>
              <Column>
                <Item labelText="合約來源" loading={loading}>
                  {s.contract?.contract_number}
                </Item>
              </Column>
            </Row>
          </Grid>
        </ScheduleItem>
      ))}

      <Grid>
        <Column>
          <Comment labelText="備註" loading={loading}>
            {comment || "無"}
          </Comment>
        </Column>
      </Grid>
    </div>
  );
};
