import { useState, useRef } from "react";
import { NumberInput } from "carbon-components-react";

export const Number = ({
  id,
  className,
  style,
  field = {},
  data = "",
  onChange,
}) => {
  const inputRef = useRef();
  const { key, required = false, ui } = field;
  const { min = 0, max = 1000, step = 1 } = ui;
  const { title, placeholder = "", default: defaultValue } = field.schema;
  const disabled = ui["ui:readonly"] ?? false;
  const [shouldValidate, setShouldValidate] = useState(false);

  var invalidString = "";
  const checkInvalid = (v) => {
    var invalid = false;
    if (required && !!v) {
      invalidString = "此為必填欄位";
      invalid = v === "";
    }
    const vIsNumber = !!v && !isNaN(v);
    if (vIsNumber) {
      const n = parseFloat(v);
      if (n > max || n < min) {
        invalidString = `請輸入合法數值，值需大於 ${min} 小於 ${max}`;
        invalid = true;
      }
    }
    return invalid;
  };

  const invalid = shouldValidate && checkInvalid(data);

  return (
    <NumberInput
      ref={inputRef}
      id={id}
      className={className}
      style={style}
      label={title}
      placeholder={placeholder}
      value={data}
      defaultValue={defaultValue}
      disabled={disabled}
      invalid={invalid}
      invalidText={invalidString}
      min={min}
      max={max}
      step={step}
      onFocus={() => {
        if (inputRef.current) {
          inputRef.current.addEventListener("wheel", disableWheel);
        }
        setShouldValidate(false);
      }}
      onBlur={() => {
        if (inputRef.current) {
          inputRef.current.removeEventListener("wheel", disableWheel);
        }
        setShouldValidate(true);
      }}
      onChange={(e) => {
        const v = e.imaginaryTarget.value;
        const vIsNumber = !!v && !isNaN(v);
        if (typeof onChange !== "function") return;
        if (vIsNumber) {
          onChange({ [key]: +v });
        }
      }}
    />
  );
};

/**
 * It prevents any wheel event from emitting.
 *
 * We want to prevent this input field from changing by the user accidentally
 * when the user scrolling up or down in a long form. So we prevent the default
 * behavior of wheel events when it is focused.
 *
 * Because React uses passive event handler by default, we can't just call
 * `preventDefault` in the `onWheel` event handler. So we have to get the input
 * ref and add our event handler manually.
 *
 * @see https://github.com/facebook/react/pull/19654
 * @param {WheelEvent} e A wheel event.
 */
function disableWheel(e) {
  e.preventDefault();
}
