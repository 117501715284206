import { useContext, useState, useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "carbon-components-react";
import { Save20 } from "@carbon/icons-react";
import useSWR from "swr";

import { ContractEditor } from "../../components/Editor";
import { PuerperaRow } from "../../components/Viewer";
import { CentersContext } from "../../context";
import { usePuerpera, useUserData } from "../../hooks";

import { URL, Fetcher as F } from "../../api";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

const PuerperaSpace = styled.div`
  min-height: 3rem; // same as the Carbon button
`;

export const ContractCreateOrEditTab = ({ pid }) => {
  const navigate = useNavigate();
  const { cid } = useParams();

  const puerpera = usePuerpera(pid);

  const isEdit = !!cid;
  const { data: contractData } = useSWR(
    isEdit ? [URL.contract + cid] : null,
    F.withToken
  );

  const [contract, setContract] = useState();

  useEffect(() => {
    if (!contractData) return;
    setContract(contractData);
  }, [contractData]);

  const user = useUserData();
  const { list: centerList = [] } = useContext(CentersContext);

  const isReady = !!(
    user &&
    user.employee_id &&
    pid &&
    isContractReady(contract, isEdit)
  );

  const handleContract = useCallback(async () => {
    if (!isReady) return;
    const {
      center_id,
      contract_number,
      file,
      note,
      sign,
      terminate_date,
      reason,
    } = contract;

    if (isEdit) {
      const formData = new FormData();
      formData.append("employee_id", user.employee_id);
      if (contractData.center_id !== center_id) {
        formData.append("center_id", center_id);
      }
      if (contractData.contract_number !== contract_number) {
        formData.append("contract_number", contract_number);
      }
      if (sign && contractData.sign !== sign) {
        formData.append("sign", sign);
      }
      if (terminate_date && contractData.terminate_date !== terminate_date) {
        formData.append("terminate_date", terminate_date);
      }
      if (file && contractData.file !== file) {
        formData.append("file", file);
      }
      if (note && contractData.note !== note) {
        formData.append("note", note);
      }
      if (reason && contractData.reason !== reason) {
        formData.append("reason", reason);
      }
      await F.patchWithFormData(`${URL.contract}${cid}/`, formData);
      navigate(-1);
    } else {
      const formData = new FormData();
      formData.append("center_id", center_id);
      formData.append("employee_id", user.employee_id);
      formData.append("puerpera_id", pid);
      formData.append("contract_number", contract_number);
      formData.append("sign", sign);
      formData.append("terminate_date", terminate_date);
      formData.append("reason", reason);
      if (file) formData.append("file", file);
      if (note) formData.append("note", note);
      await F.postWithFormData(URL.contract, formData);
      navigate(-1);
    }
  }, [navigate, isReady, contract, user, pid, cid, isEdit, contractData]);

  return (
    <div>
      <PuerperaSpace>
        <PuerperaRow showContract={true} data={puerpera ?? {}} />
      </PuerperaSpace>
      <ContractEditor
        isEdit={isEdit}
        centerList={centerList}
        data={contract}
        onChange={setContract}
      />
      <Actions>
        <Button
          disabled={!isReady}
          renderIcon={Save20}
          onClick={handleContract}
        >
          儲存
        </Button>
      </Actions>
    </div>
  );
};

function isContractReady(contract, isEdit) {
  const check =
    contract && contract.center_id && contract.contract_number && contract.sign;
  if (isEdit) {
    return check;
  } else {
    return check && contract.file;
  }
}
