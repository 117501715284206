import { useContext, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Grid,
  Pagination,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Tile,
} from "carbon-components-react";

import { Search16, View16 } from "@carbon/icons-react";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import useSWR from "swr";
import { DateTimeFormat } from "../../utils/datetime";
import { CentersContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";

import {
  defaultPageSize,
  defaultPageSizeOptions,
  BabyStatusSummaryStatusViewer,
} from "../../components/Viewer";

dayjs.extend(duration);

const headers = [
  {
    key: "record",
    name: "日期",
  },
  {
    key: "puerpera",
    name: "媽媽姓名",
  },
  {
    key: "sum_milk_mon",
    name: "母奶",
  },
  {
    key: "sum_milk_formula",
    name: "配方",
  },
  {
    key: "sum_milk_duration",
    name: "親子同室時數",
  },
  {
    key: "count_breast_feeding",
    name: "親餵次數",
  },
  {
    key: "count_urinate",
    name: "小便",
  },
  {
    key: "count_stool",
    name: "大便",
  },
  {
    key: "avg_rr",
    name: "呼吸",
  },
  {
    key: "avg_hr",
    name: "心跳",
  },
  {
    key: "avg_temperature",
    name: "體溫",
  },
  {
    key: "avg_weight",
    name: "體重",
  },
  {
    key: "avg_guts",
    name: "膽色素",
  },
  {
    key: "actions",
    name: "",
  },
];

const createQueryString = ({
  since,
  until,
  keyword,
  center,
  roomName,
  offset,
  limit,
  babyID,
}) => {
  const params = new URLSearchParams();
  if (since !== undefined) {
    params.append("since", dayjs(since).format(DateTimeFormat.date));
  }
  if (until !== undefined) {
    params.append("until", dayjs(until).format(DateTimeFormat.date));
  }
  if (keyword !== undefined) {
    params.append("search", keyword);
  }
  if (center !== undefined) {
    const cid = center.id;
    if (cid !== 0) {
      params.append("center", cid);
    }
  }
  if (roomName !== undefined) {
    params.append("room", roomName);
  }
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  if (babyID !== undefined) {
    params.append("baby", babyID);
  }
  return params.toString();
};

export const SearchForBabyStatusSummary = () => {
  const { list: centerList } = useContext(CentersContext);
  const { bid: babyID } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const [since, setSince] = useState();
  const [until, setUntil] = useState();
  const [center, setCenter] = useState();
  const [keyword, setKeyingKeyword] = useState();
  const [roomName, setRoomName] = useState();

  const [aberrent, setAberrent] = useState("");

  const queryString = useMemo(
    () =>
      createQueryString({
        since,
        until,
        keyword,
        center,
        roomName,
        offset,
        limit,
      }),
    [since, until, keyword, center, roomName, offset, limit]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);
  const [isAberrentQuery, setIsAberrentQuery] = useState(false);
  const [aberrentTitle, setAberrentTitle] = useState("");

  const summaryStatusURL = `${URL.summary.status}?${realyQuery}`;
  const { data: statusData } = useSWR([summaryStatusURL], F.withToken);

  const babyStatusURL = `${URL.summary.babyStatusRecord}?${realyQuery}`;
  const bsrAberrentURL = `${URL.summary.aberrent}?${realyQuery}&${aberrent}`;
  const { data: statusRecordData } = useSWR(
    [isAberrentQuery ? bsrAberrentURL : babyStatusURL],
    F.withToken
  );

  const isLoadingStatus = !statusData;
  const isLoadingStatusRecord = !statusRecordData;

  return (
    <Content className="housing-info">
      <Breadcrumb>
        <BreadcrumbItem href="/care">護理管理</BreadcrumbItem>
        <BreadcrumbItem
          href=""
          isCurrentPage={!isAberrentQuery}
          onClick={() => {
            setIsAberrentQuery(false);
            setAberrentTitle("");
          }}
        >
          每日所有新生兒統計
        </BreadcrumbItem>
        {isAberrentQuery && (
          <BreadcrumbItem href="" isCurrentPage>
            {`${aberrentTitle}`}
          </BreadcrumbItem>
        )}
      </Breadcrumb>

      <Tile>{isAberrentQuery ? `${aberrentTitle}` : "每日所有新生兒統計"}</Tile>

      <Grid>
        <Column>
          {!isAberrentQuery && (
            <Row>
              <Column md={1}>
                <Dropdown
                  id="center-selector"
                  titleText="館別"
                  label="全部"
                  items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
                  itemToString={(item) => item.name}
                  selectedItem={center}
                  onChange={(v) => {
                    setCenter(v.selectedItem);
                  }}
                />
              </Column>
              <Column>
                <DatePicker
                  dateFormat="Y/m/d"
                  datePickerType="range"
                  onChange={([startDate, endDate]) => {
                    setSince(startDate);
                    setUntil(endDate);
                  }}
                >
                  <DatePickerInput
                    id="care__search__sinceDate"
                    placeholder="yyyy/mm/dd"
                    labelText="起始日期"
                  />
                  <DatePickerInput
                    id="care__search__endDate"
                    placeholder="yyyy/mm/dd"
                    labelText="結束日期"
                  />
                </DatePicker>
              </Column>
              <Column>
                <Search
                  labelText="搜尋"
                  placeholder="產婦姓名、電話或身份證號"
                  onChange={(e) => {
                    const v = e.target.value;
                    setKeyingKeyword(v);
                  }}
                />
              </Column>
              <Column>
                <Search
                  placeholder="房名"
                  onChange={(e) => {
                    const v = e.target.value;
                    setRoomName(v);
                  }}
                />
              </Column>
              <Column sm={1}>
                <Button
                  renderIcon={Search16}
                  iconDescription="搜尋"
                  hasIconOnly
                  onClick={() => {
                    setPage(1);
                    const queryString = createQueryString({
                      since,
                      until,
                      keyword,
                      center,
                      roomName,
                      offset: 0,
                      limit,
                    });
                    setRealyQuery(queryString);
                  }}
                ></Button>
              </Column>
            </Row>
          )}
        </Column>
        <Column>
          {!isAberrentQuery && (
            <BabyStatusSummaryStatusViewer
              loading={isLoadingStatus}
              data={statusData}
              onChangeLimit={(v) => {
                const { k, a, q } = v;
                setAberrent(q);
                setAberrentTitle(k + a);
                setIsAberrentQuery(true);
              }}
            />
          )}

          {isLoadingStatusRecord ? (
            <DataTableSkeleton showHeader={false} />
          ) : (
            <BabyStatusRecordTable
              page={page}
              offset={offset}
              limit={limit}
              babyID={babyID}
              data={statusRecordData}
              onPageChange={({ page, pageSize }) => {
                setPage(page);
                setLimit(pageSize);
              }}
            />
          )}
        </Column>
      </Grid>
    </Content>
  );
};

function BabyStatusRecordTable({ page, limit, babyID, data, onPageChange }) {
  const pageSize = limit;
  const total = (data && data.count) || 0;
  const _data = (data && data.results) || [];

  const cleanData = _data.map(({ record, baby, ...other }) => {
    return {
      ...other,
      id: `${baby}-${record}`,
      record,
      actions: baby,
    };
  });

  const pageProps = {
    disabled: !data,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  return (
    <DataTable rows={cleanData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const { header = "" } = cell && cell.info;
                    let content;
                    switch (header) {
                      case "record":
                        if (cell.value !== null) {
                          content = dayjs(cell.value).format(
                            DateTimeFormat.date
                          );
                        } else {
                          content = cell.value;
                        }
                        break;
                      case "sum_milk_duration":
                        if (cell.value !== null) {
                          content = dayjs
                            .duration(cell.value * 1000)
                            .format(DateTimeFormat.time);
                        } else {
                          content = cell.value;
                        }
                        break;
                      case "sum_milk_mon":
                      case "sum_milk_formula":
                      case "count_breast_feeding":
                      case "count_urinate":
                      case "count_stool":
                      case "avg_rr":
                      case "avg_hr":
                      case "avg_temperature":
                      case "avg_weight":
                      case "avg_guts":
                        content = cell.value;
                        break;
                      case "puerpera":
                        content = (
                          <Link to={`/puerpera/${cell.value?.id}/info`}>
                            {cell.value?.name}
                          </Link>
                        );
                        break;
                      case "actions":
                        content = (
                          <Button
                            kind="ghost"
                            renderIcon={View16}
                            iconDescription="新生兒護理明細"
                            as={Link}
                            to={`/care/${cell.value}`}
                          />
                        );
                        break;
                      default:
                        content = <span>?</span>;
                        break;
                    }
                    return <TableCell key={cell.id}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
