import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  Tile,
} from "carbon-components-react";
import { Save16 } from "@carbon/icons-react";
import { SchemaEditor } from "../../components/Editor";
import { SchemaContext } from "../../context";
import { useUserData } from "../../hooks";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import * as S from "../../utils/schema";

const EditorWrapper = styled.div`
  margin: 16px;
`;

export const BabyStatusCreateOrEdit = () => {
  const { bid, bsrid } = useParams();
  const navigate = useNavigate();
  const user = useUserData();

  const [isSending, setIsSending] = useState(false);
  const { babyStatusRecord: schema } = useContext(SchemaContext) || {};

  const isEdit = !!bsrid;

  const url = `${URL.babyStatus}${bsrid}/?expand=`;
  const { data: babyStatusRecordData } = useSWR(
    isEdit ? [url] : null,
    F.withToken
  );

  const initialBabyStatusRecord = useMemo(
    () => S.createInitialData(schema),
    [schema]
  );

  const [babyStatusRecord, setBabyStatusRecord] = useState(
    initialBabyStatusRecord
  );

  useEffect(() => {
    if (!babyStatusRecordData || !isEdit) return;
    setBabyStatusRecord(babyStatusRecordData);
  }, [setBabyStatusRecord, babyStatusRecordData, isEdit]);

  const isBabyStatusRecordReady = S.createSimpleValidator({
    ...schema,
    moreRequriedkeys: ["registrant"],
  });
  const isReady = useMemo(
    () => isBabyStatusRecordReady(babyStatusRecord),
    [isBabyStatusRecordReady, babyStatusRecord]
  );

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;
    if (isSending) return;
    const { id, baby, ...o } = babyStatusRecord;
    const babyID = isEdit ? baby : bid;
    const payload = { baby: babyID, ...o };

    setIsSending(true);
    if (isEdit) {
      await F.put(`${URL.babyStatus}${id}/`, payload);
    } else {
      await F.post(`${URL.babyStatus}`, payload);
    }
    setIsSending(false);

    navigate(`/care/${babyID}`);
  }, [
    navigate,
    bid,
    babyStatusRecord,
    isReady,
    isEdit,
    isSending,
    setIsSending,
  ]);

  return (
    <Content className="babyStatusRecord-create">
      <Breadcrumb>
        <BreadcrumbItem href="/care">護理管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          新增編輯新生兒記錄
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>新增/編輯新生兒記錄</Tile>

      <Grid>
        <Column>
          <EditorWrapper>
            <SchemaEditor
              fields={schema.fields}
              excludes={[
                "id",
                "keyin_employee_id",
                "created",
                "modified",
                "baby",
                "brs",
              ]}
              filter={{ department: user.care_employee_groups }}
              data={babyStatusRecord}
              onChange={(x) => {
                if (!user) return;
                let r = {
                  ...x,
                  keyin_employee_id: user.employee_id,
                  registrant: user.employee_id,
                };
                if (!isEdit) {
                  r["baby"] = bid;
                }
                setBabyStatusRecord(r);
              }}
            />
          </EditorWrapper>
        </Column>
        <Column>
          <Grid>
            <Row>
              <Column>
                <Button
                  disabled={!isReady}
                  kind="primary"
                  size="small"
                  renderIcon={Save16}
                  onClick={handleSubmit}
                >
                  儲存
                </Button>
              </Column>
            </Row>
          </Grid>
        </Column>
      </Grid>
    </Content>
  );
};
