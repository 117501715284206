import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import {
  Content,
  Breadcrumb,
  BreadcrumbItem,
  Tile,
  Accordion,
  AccordionItem,
  Button,
} from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";

import { BookVisitScheduleEditor } from "../../components/Editor";
import { CustomerViewer } from "../../components/Viewer";
import { SchemaContext } from "../../context";
import { PuerperaRow, SchemaViewer } from "../../components/Viewer";

import { CentersContext, RoomTypeContext, StatusContext } from "../../context";
import useSWR, { useSWRConfig } from "swr";
import { useUserData, useQuery, usePuerpera } from "../../hooks";
import { URL, Fetcher as F } from "../../api";

const Container = styled.div`
  background-color: #f4f4f4;
  padding: 32px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
`;

const PuerperaSpace = styled.div`
  min-height: 3rem; // same as the Carbon button
`;

export const BookVisitCreateOrEditSchedule = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const user = useUserData();
  const { list: ccList } = useContext(CentersContext);
  const vsEnum = useMemo(() => {
    if (!ccList || ccList.length === 0) return [];
    return ccList[0].visit_schedules.items ?? [];
  }, [ccList]);

  const { bvid, sid } = useParams();

  const qs = useQuery();
  const back = qs.get("back") || "";
  const puerperaID = qs.get("p") || "";
  const { puerpera: puerperaSchema } = useContext(SchemaContext) || {};

  const { interactionStatus } = useContext(StatusContext);

  const bvURL = `${URL.bookVisit.list}${bvid}/?expand=customer`;
  const { data: bv } = useSWR([bvURL], F.withToken, {
    revalidateOnFocus: false,
  });

  const puerpera = usePuerpera(puerperaID);

  const department = user.bookvisit_schedule_employee_groups ?? "";
  // TODO: Add center filter, upgarde to use same Editor
  const staffParams = new URLSearchParams();
  staffParams.append("fields", "id,name");
  staffParams.append("status", 1);
  if (department !== undefined) {
    department.split(",").forEach((x) => {
      staffParams.append("department", x);
    });
  }
  const staffURL = `${URL.staff}?${staffParams.toString()}`;

  const { data: staffList } = useSWR([staffURL], F.withToken, {
    revalidateOnFocus: false,
  });
  const { list: roomTypeList } = useContext(RoomTypeContext);

  const isEdit = !!sid;
  const { data: scheduleData } = useSWR(
    isEdit
      ? [`${URL.bookVisit.schedule}${sid}/?omit=id,created,modified`]
      : null,
    F.withToken,
    { revalidateOnFocus: false }
  );

  const [schedule, setSchedule] = useState({});

  useEffect(() => {
    if (!scheduleData) return;
    setSchedule(scheduleData);
  }, [scheduleData]);

  const isReady = useMemo(
    () => user && isDataReady(schedule),
    [user, schedule]
  );

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;

    let n;
    if (isEdit) {
      // Remove null attributes
      let payload = Object.entries(schedule).reduce(
        (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
        {}
      );
      if (puerpera) {
        payload["puerpera_id"] = puerperaID;
      }
      n = await F.put(`${URL.bookVisit.schedule}${sid}/`, payload);
    } else {
      var payload = schedule;
      if (puerpera) {
        payload["puerpera_id"] = puerperaID;
      }
      if (bv.customer_id) {
        payload["customer_id"] = bv.customer_id;
      }
      n = await F.post(URL.bookVisit.schedule, payload);
    }
    mutate([URL.bookVisit.schedule + n.id], n, false);

    if (back === "puerpera") {
      navigate(`/puerpera/${puerperaID}/history`);
    } else {
      navigate(`/schedule/${bvid}/history`);
    }
  }, [
    navigate,
    mutate,
    isReady,
    schedule,
    bvid,
    bv,
    isEdit,
    sid,
    back,
    puerperaID,
    puerpera,
  ]);

  const prefixString = isEdit ? "編輯" : "新增";
  const hadPuerpera = puerperaID !== "" && !!puerpera;
  const infoTitle = hadPuerpera ? "產婦" : "聯絡人";

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/bookvisit">預約參訪</BreadcrumbItem>
        <BreadcrumbItem
          href=""
          isCurrentPage
        >{`${prefixString}參觀記錄`}</BreadcrumbItem>
      </Breadcrumb>

      <Tile>{prefixString}預約參訪</Tile>

      <Container>
        {hadPuerpera && (
          <PuerperaSpace>
            <PuerperaRow
              showPuerperaLink={true}
              showContract={true}
              data={puerpera}
            ></PuerperaRow>
          </PuerperaSpace>
        )}
        <Accordion>
          <AccordionItem title={infoTitle} open>
            {hadPuerpera ? (
              <SchemaViewer
                fields={puerperaSchema.fields}
                excludes={[
                  "id",
                  "created",
                  "modified",
                  "employee_id",
                  "customer_id",
                  "bookroom_id",
                  "checkin_date",
                  "checkout_date",
                ]}
                isLoading={!puerpera}
                data={puerpera}
              />
            ) : (
              <CustomerViewer loading={!bv} data={bv?.customer} />
            )}
          </AccordionItem>
          <AccordionItem title="預約參訪" open>
            <BookVisitScheduleEditor
              roomTypeList={roomTypeList}
              centerList={ccList}
              interactionStatusList={interactionStatus}
              service_staff={staffList}
              vsEnum={vsEnum}
              data={schedule}
              onChange={(x) => {
                if (!user) return;
                setSchedule({
                  ...x,
                  keyin_employee_id: user.employee_id,
                  book_visit_id: bvid,
                });
              }}
            />
          </AccordionItem>
        </Accordion>
        <Actions>
          <Button
            disabled={!isReady}
            kind="primary"
            size="small"
            renderIcon={Add16}
            onClick={handleSubmit}
          >
            {prefixString}參觀記錄
          </Button>
        </Actions>
      </Container>
    </Content>
  );
};

function isDataReady(schedule) {
  return (
    schedule &&
    schedule.interactionStatus_id &&
    schedule.book_date &&
    schedule.visit_schedule_display &&
    schedule.service_employee_id
  );
}
