import { useMemo } from "react";
import styled from "styled-components";
import { Item } from "./Item";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
`;

const Column = styled.div`
  > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CustomerViewer = ({ loading = false, data = {} }) => {
  const {
    name = "",
    phone = "",
    mobile_phone = "",
    gender = "M",
    email = "",
    address = "",
    relationship = "",
  } = data ?? {};
  const genderText = useMemo(() => {
    if (gender === "M") return "男";
    if (gender === "F") return "女";
    return "不明";
  }, [gender]);

  return (
    <Container>
      <Column>
        <Item labelText="聯絡人姓名" loading={loading}>
          {name}
        </Item>
        <Item labelText="聯絡人電話" loading={loading}>
          {phone}
        </Item>
        <Item labelText="聯絡人手機" loading={loading}>
          {mobile_phone}
        </Item>
      </Column>
      <Column>
        <Item labelText="聯絡人性別" loading={loading}>
          {genderText}
        </Item>
        <Item labelText="與產婦關係" loading={loading}>
          {relationship}
        </Item>
        <Item labelText="E-Mail" loading={loading}>
          {email}
        </Item>
        <Item labelText="地址" loading={loading}>
          {address}
        </Item>
      </Column>
    </Container>
  );
};
