import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  Tile,
} from "carbon-components-react";
import { RequestQuote16, Undo16 } from "@carbon/icons-react";
import { BookRoomViewer } from "../../components/Viewer";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import { useUserData } from "../../hooks";

export const BookRoomInfo = () => {
  const { bid } = useParams();

  const queryString = useMemo(() => {
    const params = new URLSearchParams();
    params.append(
      "expand",
      "employee,babybeds,room.center,room.roomType,parkingspace,contract"
    );
    if (bid !== undefined) {
      params.append("bookroom_id", bid);
    }
    return params.toString();
  }, [bid]);

  const user = useUserData();

  const bookroomScheduleURL = `${URL.book.roomSchedule}?${queryString}`;

  const bookroomURL = `${URL.book.room}${bid}/?expand=puerpera,employee,contract`;
  const { data = {} } = useSWR([bookroomURL], F.withToken);

  const { data: schedulesData = [] } = useSWR(
    [bookroomScheduleURL],
    F.withToken
  );

  const isLoading = !data || !schedulesData;

  const bookroom = useMemo(
    () => ({
      ...data,
      schedules: schedulesData,
    }),
    [data, schedulesData]
  );

  return (
    <Content className="bookroom-info">
      <Breadcrumb>
        <BreadcrumbItem href="/bookroom">訂房/排房管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          瀏覽
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>瀏覽訂房/排房資訊</Tile>

      <Grid>
        <Column>
          <BookRoomViewer loading={isLoading} data={bookroom} />

          <Row>
            <Column sm={{ span: 1, offset: 1 }}>
              {user["e_book"] && (
                <Button
                  as={Link}
                  to={`/bookroom/${bookroom.puerpera_id}/edit`}
                  kind="primary"
                  size="small"
                  renderIcon={RequestQuote16}
                >
                  編輯
                </Button>
              )}
            </Column>
            <Column>
              <Button
                as={Link}
                to="/bookroom/"
                kind="primary"
                size="small"
                renderIcon={Undo16}
              >
                返回訂房/排房管理
              </Button>
            </Column>
          </Row>
        </Column>
        <Column sm={1} />
      </Grid>
    </Content>
  );
};
