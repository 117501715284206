import { Checkbox as CarbonCheckbox } from "carbon-components-react";
import { Label } from "../Label";

export const Checkbox = ({
  id,
  className,
  style,
  field = {},
  data = false,
  onChange,
}) => {
  const { key, required = false } = field;
  const { title } = field.schema;

  return (
    <CarbonCheckbox
      id={id}
      className={className}
      style={style}
      labelText={<Label required={required}>{title}</Label>}
      checked={data}
      onChange={(value) => {
        if (typeof onChange !== "function") return;
        onChange({ [key]: value });
      }}
    />
  );
};
