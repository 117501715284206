import { useContext } from "react";
import { useMemo } from "react";
import useSWR from "swr";
import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useBookVisit = (filter = {}) => {
  const {
    token: { access },
  } = useContext(UserContext);

  const { puerpera } = filter;
  const params = new URLSearchParams();
  params.append("expand", "note,centers,keyin_employee,customer,status");
  if (puerpera) {
    params.append("puerpera", puerpera);
  }

  const bookVisitURL = `${URL.bookVisit.list}?${params.toString()}`;
  const { data: rawBookVisit } = useSWR(access && [bookVisitURL], F.withToken);
  const { customer_id, customer, note_id, note, ...rest } = rawBookVisit ?? {};

  const bookVisit = useMemo(() => {
    return { ...rest, customer, note };
  }, [rest, customer, note]);

  return bookVisit;
};
