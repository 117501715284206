import { useState } from "react";
import { NoteArea } from "../NoteArea";
import { Label } from "../Label";

export const Note = ({
  id,
  className,
  style,
  field = {},
  data = "",
  onChange,
}) => {
  const { key, required = false, ui } = field;
  const { title } = field.schema;

  const disabled = ui["ui:readonly"] ?? false;
  const [shouldValidate, setShouldValidate] = useState(false);
  const invalid = shouldValidate && required && data === "";

  return (
    <NoteArea
      id={id}
      className={className}
      style={style}
      labelText={<Label required={required}>{title}</Label>}
      placeholder="詳細說明"
      disabled={disabled}
      value={data}
      invalid={invalid}
      invalidText="此為必填欄位"
      onFocus={() => setShouldValidate(false)}
      onBlur={() => setShouldValidate(true)}
      onChange={(evt) => {
        let v = evt.target.value;
        if (typeof onChange !== "function") return;
        onChange({ [key]: v });
      }}
    />
  );
};
