import { useCallback } from "react";
import styled from "styled-components";
import {
  Dropdown,
  FormGroup,
  TextInput,
  Toggle,
} from "carbon-components-react";
import { NoteArea } from "./NoteArea";
import { hasId } from "../../utils";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
`;

const Column = styled.div`
  > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StaffEditor = ({
  disabled = false,
  isCreater = false,
  centers = [],
  departments = [],
  data = {},
  onChange,
}) => {
  const {
    hr_id = "",
    tw_identity = "",
    address = "",
    phone = "",
    email = "",
    note = "",
    status = 1,
    username,
    password = "",
    name,
    department_id = -1,
    center_id = -1,
  } = data;

  const update = useCallback(
    (obj) => ({
      hr_id,
      tw_identity,
      address,
      phone,
      email,
      note,
      status,
      username,
      password,
      name,
      department_id,
      center_id,
      ...obj,
    }),
    [
      hr_id,
      tw_identity,
      address,
      phone,
      email,
      note,
      status,
      username,
      password,
      name,
      department_id,
      center_id,
    ]
  );

  return (
    <Container>
      <Column>
        <TextInput
          id="staff__account"
          disabled={!isCreater || disabled}
          labelText="使用者帳號"
          placeholder="請輸入使用者帳號"
          value={username}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ username: evt.target.value }));
          }}
        />
        {isCreater && (
          <TextInput.PasswordInput
            id="staff__password"
            disabled={!isCreater || disabled}
            labelText="使用者密碼"
            placeholder="請輸入使用者密碼"
            value={password}
            onChange={(evt) => {
              if (typeof onChange !== "function") return;
              onChange(update({ password: evt.target.value }));
            }}
          />
        )}
        <TextInput
          id="staff__hr_id"
          disabled={disabled}
          labelText="員工代碼"
          placeholder="請輸入員代碼"
          value={hr_id}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ hr_id: evt.target.value }));
          }}
        />
        <TextInput
          id="staff__name"
          disabled={disabled}
          labelText="員工姓名"
          placeholder="請輸入員工姓名"
          value={name}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ name: evt.target.value }));
          }}
        />
        <TextInput
          id="staff__tw_identity"
          disabled={disabled}
          labelText="員工身分證號"
          placeholder="請輸入員工身分證號"
          value={tw_identity}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ tw_identity: evt.target.value }));
          }}
        />
        <TextInput
          id="staff__phone"
          disabled={disabled}
          labelText="行動電話"
          placeholder="請輸入行動電話"
          value={phone}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ phone: evt.target.value }));
          }}
        />
        <TextInput
          id="staff__email"
          disabled={disabled}
          labelText="E-Mail"
          placeholder="請輸入E-Mail"
          value={email}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ email: evt.target.value }));
          }}
        />
        <TextInput
          id="staff__address"
          disabled={disabled}
          labelText="住址"
          placeholder="請輸入住址"
          value={address}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ address: evt.target.value }));
          }}
        />
      </Column>
      <Column>
        <Dropdown
          id="staff__center"
          disabled={disabled}
          label="館別"
          titleText="館別"
          items={centers}
          itemToString={(c) => (c && c.name ? c.name : "請選擇")}
          selectedItem={centers ? centers.find(hasId(center_id)) : null}
          onChange={(v) => {
            if (typeof onChange !== "function") return;
            onChange(update({ center_id: v.selectedItem.id }));
          }}
        />
        <Dropdown
          id="staff__department"
          disabled={disabled}
          label="部門"
          titleText="部門"
          items={departments}
          itemToString={(d) => (d && d.name ? d.name : "請選擇")}
          selectedItem={
            departments ? departments.find(hasId(department_id)) : null
          }
          onChange={(v) => {
            if (typeof onChange !== "function") return;
            onChange(update({ department_id: v.selectedItem.id }));
          }}
        />
        <FormGroup legendText="員工狀態">
          <Toggle
            id="staff__status"
            disabled={disabled}
            labelA="離職"
            labelB="在職"
            toggled={status === 1}
            onChange={(evt) => {
              if (typeof onChange !== "function") return;
              onChange(update({ status: evt.target.checked ? 1 : 0 }));
            }}
          />
        </FormGroup>
        <NoteArea
          id="staff__note"
          disabled={disabled}
          labelText="備註"
          placeholder="詳細說明"
          value={note}
          onChange={(evt) => {
            if (typeof onChange !== "function") return;
            onChange(update({ note: evt.target.value }));
          }}
        />
      </Column>
    </Container>
  );
};
