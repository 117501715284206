import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Dropdown,
  DropdownSkeleton,
  FormGroup,
  Grid,
  Row,
  TextArea,
  TextInput,
  Tile,
  Toggle,
} from "carbon-components-react";
import { Save16 } from "@carbon/icons-react";
import { RoomTypeContext } from "../../context";
import "./Edit.scss";

import { CentersContext } from "../../context";
import useSWR, { useSWRConfig } from "swr";
import { URL, Fetcher as F } from "../../api";
import { hasId } from "../../utils";

export const RoomEdit = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { rid } = useParams();

  const roomURL = `${URL.room}${rid}/`;
  const { data } = useSWR([roomURL], F.withToken);

  const [center, setCenter] = useState("");
  const [roomType, setRoomType] = useState("");
  const [floor, setFloor] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState(false);
  const [note, setNote] = useState("");

  const { list: centerList } = useContext(CentersContext);
  const { list: roomTypeList } = useContext(RoomTypeContext);

  const room = useMemo(
    () => ({ id: rid, center, roomType, name, status, note, floor }),
    [rid, center, roomType, name, status, note, floor]
  );
  const roomReady = !!(room.center && room.roomType && room.name && room.floor);

  useEffect(() => {
    if (!data) return;
    setName(data.name);
    setStatus(data.status);
    setNote(data.note);
    setFloor(data.floor);
  }, [data]);

  useEffect(() => {
    if (!data || !centerList) return;
    const fc = centerList.find(hasId(data.center_id));
    if (fc) setCenter(fc);
  }, [data, centerList]);

  useEffect(() => {
    if (!data || !roomTypeList) return;
    const fr = roomTypeList.find(hasId(data.roomType_id));
    if (fr) setRoomType(fr);
  }, [data, roomTypeList]);

  const handleSubmit = useCallback(async () => {
    if (!roomReady) return;
    const { center, roomType, status, ..._room } = room;
    const payload = {
      ..._room,
      center_id: room.center.id,
      roomType_id: room.roomType.id,
      status: room.status ? 1 : 0,
    };

    mutate([roomURL], payload, false);
    const promise = F.put(roomURL, payload);
    mutate([roomURL], promise);

    navigate(`/room/${rid}`);
  }, [navigate, mutate, roomURL, room, roomReady, rid]);

  return (
    <Content className="room-edit">
      <Breadcrumb>
        <BreadcrumbItem href="/">系統管理</BreadcrumbItem>
        <BreadcrumbItem href="/room">房間管理</BreadcrumbItem>
      </Breadcrumb>

      <Tile>編輯房間</Tile>

      <Grid>
        <Row>
          <Column className="room-edit__content" sm={{ span: 2, offset: 1 }}>
            <div className="room-edit__data">
              <div className="room-edit__center">
                {!center && !centerList ? (
                  <DropdownSkeleton />
                ) : (
                  <Dropdown
                    id="room__center"
                    titleText="館別"
                    lable={center.name}
                    items={centerList}
                    itemToString={(item) => (item ? item.name : "")}
                    selectedItem={center}
                    onChange={(e) => setCenter(e.selectedItem)}
                  />
                )}
              </div>
              <div className="room-edit__room-type">
                {!roomType && !roomTypeList ? (
                  <DropdownSkeleton />
                ) : (
                  <Dropdown
                    id="room__room-type"
                    titleText="房型"
                    label={roomType.name}
                    items={roomTypeList}
                    itemToString={(item) => (item ? item.name : "")}
                    selectedItem={roomType}
                    onChange={(e) => setRoomType(e.selectedItem)}
                  />
                )}
              </div>
              <div className="room-edit__floor">
                <TextInput
                  id="room__floor"
                  labelText="房間樓層"
                  disabled={!data}
                  placeholder="請輸入房間樓層"
                  value={floor}
                  onChange={(evt) => setFloor(evt.target.value)}
                />
              </div>
              <div className="room-edit__name">
                <TextInput
                  id="room__name"
                  labelText="房間名稱"
                  disabled={!data}
                  placeholder="請輸入房間名稱"
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </div>
              <div className="room-edit__status">
                <FormGroup legendText="房間狀態">
                  <Toggle
                    id="room__status"
                    disabled={!data}
                    labelA="不開放"
                    labelB="開放"
                    toggled={status === 1}
                    onChange={(evt) => setStatus(evt.target.checked ? 1 : 0)}
                  />
                </FormGroup>
              </div>
              <div className="room-edit__note">
                <div className="bx--label room-edit__note__count">
                  {note.length}/100
                </div>
                <TextArea
                  id="room__note"
                  labelText="備註"
                  disabled={!data}
                  placeholder="詳細說明"
                  value={note}
                  onChange={(evt) => setNote(evt.target.value)}
                />
              </div>
            </div>
            <div className="room-edit__actions">
              <Button
                kind="primary"
                size="small"
                renderIcon={Save16}
                disabled={!data || !roomReady}
                onClick={handleSubmit}
              >
                儲存
              </Button>
            </div>
          </Column>
          <Column sm={1} />
        </Row>
      </Grid>
    </Content>
  );
};
