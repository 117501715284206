import { useContext, useState } from "react";
import { Dropdown, DropdownSkeleton } from "carbon-components-react";
import { Label } from "../Label";
import { RoomTypeContext } from "../../../context";

export const RoomType = ({
  id,
  className,
  style,
  field = {},
  data,
  onChange,
}) => {
  const { list: roomTypeList } = useContext(RoomTypeContext);

  const { key, required = false } = field;
  const { type = "number", title } = field.schema;
  const item = (roomTypeList || []).find((x) => x.id === data);
  const [shouldValidate, setShouldValidate] = useState(false);
  const invalid = shouldValidate && required && data === undefined;

  return !roomTypeList ? (
    <DropdownSkeleton id={id} className={className} style={style} />
  ) : (
    <Dropdown
      id={id}
      className={className}
      style={style}
      titleText={<Label required={required}>{title}</Label>}
      label="請選擇"
      items={roomTypeList}
      itemToString={(item) => (item ? item.name : "")}
      selectedItem={item}
      invalid={invalid}
      invalidText="此為必填欄位"
      onFocus={() => setShouldValidate(false)}
      onBlur={() => setShouldValidate(true)}
      onChange={(e) => {
        let v = e.selectedItem.id;
        if (type === "number") {
          v = +v;
        }
        if (typeof onChange !== "function") return;
        onChange({ [key]: v });
      }}
    />
  );
};
