import { useState, useCallback, useEffect } from "react";
import { Auth } from "../api";

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user")) || {};
};

export const useAuth = () => {
  const [token, setToken] = useState(getCurrentUser());

  const login = useCallback((user) => {
    return Auth.login(user)
      .then((tokens) => {
        setToken(tokens);
        return tokens;
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const logout = useCallback(() => {
    const tokens = Auth.logout();
    setToken(tokens);
  }, []);

  useEffect(() => {
    const handleChange = (evt) => {
      if (evt.key === "user") setToken(JSON.parse(evt.newValue) || {});
    };
    window.addEventListener("storage", handleChange);
    return () => {
      window.removeEventListener("storage", handleChange);
    };
  }, []);

  return {
    token,
    login,
    logout,
  };
};
