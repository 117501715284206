import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  Tile,
} from "carbon-components-react";
import { RequestQuote16, Undo16 } from "@carbon/icons-react";
import { StaffViewer } from "../../components/Viewer";

import { useStaff } from "../../hooks";

const StaffColumn = styled(Column)`
  padding-top: 38px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  > .bx--btn {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StaffInfo = () => {
  const { sid } = useParams();
  const staff = useStaff(sid);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/">系統管理</BreadcrumbItem>
        <BreadcrumbItem href="/staff">員工管理</BreadcrumbItem>
      </Breadcrumb>

      <Tile>瀏覽員工資訊</Tile>

      <Grid>
        <Row>
          <StaffColumn sm={{ span: 2, offset: 1 }}>
            <StaffViewer loading={!staff} data={staff} />
            <Actions>
              <Button
                as={Link}
                to={`/staff/${sid}/edit`}
                kind="primary"
                size="small"
                renderIcon={RequestQuote16}
              >
                編輯
              </Button>
              <Button
                as={Link}
                to="/staff/"
                kind="primary"
                size="small"
                renderIcon={Undo16}
              >
                返回員工清單
              </Button>
            </Actions>
          </StaffColumn>
          <Column sm={1} />
        </Row>
      </Grid>
    </Content>
  );
};
