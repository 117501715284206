import { Column, Grid, Link, Row } from "carbon-components-react";

import { Item } from "./Item";

export const PuerperaWithBabyViewer = ({ loading = false, data = {} }) => {
  const {
    puerpera = {
      id: "",
      name: "",
      mobile_phone: "",
      phone: "",
      birthday: "",
      tw_identity: "",
    },
    puerperaNote = {
      pregnancy_week: "",
      birth_method: "",
    },
    bookroom,
    birthday,
    gender,
    weight,
    checkin_date,
    checkout_date,
  } = data;

  const brs = bookroom?.current_brs;
  const babyRoom = brs?.room?.name;

  return (
    <div>
      <Grid>
        <Row>
          <Column>
            <Item labelText="產婦姓名" loading={loading}>
              <Link href={`/puerpera/${puerpera.id}/info`}>
                {puerpera.name}
              </Link>
            </Item>
          </Column>
          <Column>
            <Item labelText="產婦證號" loading={loading}>
              {puerpera.tw_identity}
            </Item>
          </Column>
          <Column>
            <Item labelText="產婦生日" loading={loading}>
              {puerpera.birthday}
            </Item>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Column>
            <Item labelText="生產週數" loading={loading}>
              {puerperaNote?.pregnancy_week}
            </Item>
          </Column>
          <Column>
            <Item labelText="產婦手機" loading={loading}>
              {puerpera.mobile_phone}
            </Item>
          </Column>
          <Column>
            <Item labelText="生產方式" loading={loading}>
              {puerperaNote?.birth_method}
            </Item>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Column>
            <Item labelText="館別" loading={loading}>
              {brs?.room?.center?.name}
            </Item>
          </Column>
          <Column>
            <Item labelText="房名" loading={loading}>
              {brs?.room?.name}
            </Item>
          </Column>
          <Column>
            <Item labelText="房型" loading={loading}>
              {brs?.room?.roomType.name}
            </Item>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Column>
            <Item labelText="媽媽入住日" loading={loading}>
              {brs?.checkin_date}
            </Item>
          </Column>
          <Column>
            <Item labelText="媽媽退房日" loading={loading}>
              {brs?.checkout_date}
            </Item>
          </Column>
          <Column>
            <Item labelText="合約編號" loading={loading}>
              {bookroom?.contract?.contract_number}
            </Item>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Column>
            <Item labelText="新生兒生日" loading={loading}>
              {birthday}
            </Item>
          </Column>
          <Column>
            <Item labelText="新生兒性別" loading={loading}>
              {gender === "M" ? "男" : "女"}
            </Item>
          </Column>
          <Column>
            <Item labelText="出生體重(公克)" loading={loading}>
              {weight}
            </Item>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Column>
            <Item labelText="新生兒入住日" loading={loading}>
              {checkin_date}
            </Item>
          </Column>
          <Column>
            <Item labelText="新生兒退房日" loading={loading}>
              {checkout_date}
            </Item>
          </Column>
          <Column>
            <Item labelText="新生兒床號" loading={loading}>
              {babyRoom}
            </Item>
          </Column>
        </Row>
      </Grid>
    </div>
  );
};
