import * as C from "./center";

export function isEqual(a, b) {
  if (a === undefined || b === undefined) {
    return a === b;
  }

  return (
    a.id === b.id &&
    a.name === b.name &&
    a.floor === b.floor &&
    C.isEqual(a.center, b.center)
  );
}
