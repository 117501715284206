import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "carbon-components-react";
import { Save16, Undo16 } from "@carbon/icons-react";

import { SchemaEditor } from "../../components/Editor";
import { PuerperaRow } from "../../components/Viewer";
import { useUserData, usePuerpera, useSchemas } from "../../hooks";

import useSWR, { useSWRConfig } from "swr";
import { URL, Fetcher as F } from "../../api";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  > .bx--btn {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const PuerperaSpace = styled.div`
  min-height: 3rem; // same as the Carbon button
`;

export const NoteEditTab = ({ pid }) => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const user = useUserData();

  const puerpera = usePuerpera(pid);

  const { bookVisitNote: bookVisitNoteSchema } = useSchemas();

  const { nid } = useParams();
  const noteInfoURL = `/puerpera/${pid}/noteInfo/${nid}`;

  const [updatedNote, setUpdatedNote] = useState();

  const noteURL = `${URL.bookVisit.note}${nid}/`;
  const { data: noteData } = useSWR(nid ? [noteURL] : null, F.withToken);

  useEffect(() => {
    if (!noteData) return;
    setUpdatedNote(noteData);
  }, [noteData]);

  const handleSubmit = useCallback(() => {
    const payload = updatedNote;
    mutate([noteURL], payload, false);
    const promise = F.put(noteURL, payload);
    mutate([noteURL], promise);

    navigate(noteInfoURL);
  }, [navigate, mutate, noteURL, updatedNote, noteInfoURL]);

  const isLoading = !updatedNote;

  return (
    <div>
      <PuerperaSpace>
        <PuerperaRow showContract={true} data={puerpera ?? {}} />
      </PuerperaSpace>
      <SchemaEditor
        fields={bookVisitNoteSchema.fields}
        excludes={[
          "id",
          "puerpera_id",
          "keyin_employee_id",
          "created",
          "modified",
        ]}
        isLoading={isLoading}
        data={updatedNote}
        onChange={(data) => {
          if (!user) return;
          setUpdatedNote({ ...data, keyin_employee_id: user.employee_id });
        }}
      />
      <Actions>
        <Button
          kind="primary"
          size="small"
          renderIcon={Save16}
          onClick={handleSubmit}
        >
          儲存
        </Button>
        <Button
          as={Link}
          to={noteInfoURL}
          kind="primary"
          size="small"
          renderIcon={Undo16}
        >
          取消
        </Button>
      </Actions>
    </div>
  );
};
