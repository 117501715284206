import { useContext, useState, useCallback, useMemo, useEffect } from "react";
import { Modal, Select, SelectItem } from "carbon-components-react";
import { CentersContext } from "../../context";
import { ModalPortal } from "./Base";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../api";

export const EmployeeModal = ({
  open = false,
  onRequestClose,
  onRequestSubmit,
}) => {
  const { data: staffs = [] } = useSWR([URL.staff], F.withToken);
  const { list: centerList } = useContext(CentersContext);
  const [center, setCenter] = useState("");
  const [employee, setEmployee] = useState("");
  const filteredStaffs = useMemo(() => {
    if (!center) return staffs;
    const id = +center;
    return staffs.filter((staff) => staff.center_id === id);
  }, [staffs, center]);

  const handleSubmit = useCallback(() => {
    if (typeof onRequestSubmit !== "function") return;
    onRequestSubmit(employee);
  }, [onRequestSubmit, employee]);

  useEffect(() => {
    if (!open) return;
    setCenter("");
    setEmployee("");
  }, [open]);

  return (
    <ModalPortal>
      <Modal
        modalHeading="選擇服務人員"
        open={open}
        primaryButtonDisabled={!employee}
        primaryButtonText="確定"
        secondaryButtonText="取消"
        onRequestClose={onRequestClose}
        onRequestSubmit={handleSubmit}
      >
        <Select
          id="center-selector"
          labelText="館別"
          value={center}
          onChange={(evt) => {
            setCenter(evt.target.value);
            setEmployee("");
          }}
        >
          <SelectItem value="" text="全部" />
          {centerList.map((center) => (
            <SelectItem key={center.id} value={center.id} text={center.name} />
          ))}
        </Select>
        <Select
          id="employee-selector"
          labelText="員工"
          value={employee}
          onChange={(evt) => setEmployee(evt.target.value)}
        >
          <SelectItem disabled hidden value="" text="請選擇員工" />
          {filteredStaffs.map((staff) => (
            <SelectItem key={staff.id} value={staff.id} text={staff.name} />
          ))}
        </Select>
      </Modal>
    </ModalPortal>
  );
};
