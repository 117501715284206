import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import CherieHeader from "./components/CherieHeader";
import LoginPage from "./content/LoginPage";
import {
  BookVisitCreate,
  BookVisitCreateOrEditSchedule,
  BookVisitEdit,
  BookVisitInfo,
  BookVisitScheduleHistory,
  BookVisitSearch,
  CustomerBookVisitSearch,
  CustomerBookVisitEdit,
  CustomerBookVisitSelectPuerpery,
} from "./content/BookVisit";
import {
  BookRoomCreateOrEdit,
  BookRoomInfo,
  BookRoomSearch,
  BookRoomSelectPuerpery,
  ManyDayActivity,
} from "./content/BookRoom";
import {
  PuerperaSearch,
  PuerperaCreate,
  PuerperaRoot,
} from "./content/Puerpera";
import {
  HousingCreateOrEdit,
  HousingInfo,
  HousingSearch,
  HousingSelectPuerpery,
  FoodVendorFromHousing,
  FoodVendorHistoryCreateOrEdit,
  StopMealsHistory,
  BeverageList,
  BeverageCreateOrEdit,
  ExportFoodVendorHistory,
} from "./content/Housing";

import {
  BabyStatusCreateOrEdit,
  BabyStatusSummary,
  BabyWithBabyStatusInfo,
  CareSearch,
  SearchForBabyStatusSummary,
} from "./content/Care";
import { GroupList } from "./content/Group";
import { RoomList, RoomInfo, RoomCreate, RoomEdit } from "./content/Room";
import { StaffList, StaffInfo, StaffCreate, StaffEdit } from "./content/Staff";
import {
  CentersContext,
  DepartmentsContext,
  RoomTypeContext,
  SchemaContext,
  StatusContext,
  UserContext,
} from "./context";
import { UserList, UserInfo, UserProfile } from "./content/User";
import { Demo } from "./content/Demo";
import {
  useAuth,
  useSchemas,
  useCenters,
  useDepartment,
  useBookVisitStatus,
  useBookVisitInteractionStatus,
  useCustomerBookVisitStatus,
  useChangeFoodVendorReason,
  useFoodMeal,
  useFoodVendor,
} from "./hooks";

import useSWR from "swr";
import { URL, Fetcher as F } from "./api";

import "./app.scss";

const RoomRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<RoomList />} />
      <Route path="create" element={<RoomCreate />} />
      <Route path=":rid/edit" element={<RoomEdit />} />
      <Route path=":rid" element={<RoomInfo />} />
    </Routes>
  );
};

const BookVisitRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<BookVisitSearch />} />
      <Route path="/customer/" element={<CustomerBookVisitSearch />} />
      <Route
        path="/customer/selectPuerpera"
        element={<CustomerBookVisitSelectPuerpery />}
      />
      <Route path="/customer/:cid/edit" element={<CustomerBookVisitEdit />} />
      <Route path="create" element={<BookVisitCreate />} />
      <Route path=":bvid/edit" element={<BookVisitEdit />} />
      <Route path=":bvid" element={<BookVisitInfo />} />
    </Routes>
  );
};

const ScheduleRoute = () => {
  return (
    <Routes>
      <Route path=":bvid/history" element={<BookVisitScheduleHistory />} />
      <Route path=":bvid/create" element={<BookVisitCreateOrEditSchedule />} />
      <Route
        path=":bvid/edit/:sid"
        element={<BookVisitCreateOrEditSchedule />}
      />
    </Routes>
  );
};

const PuerperaRoute = () => {
  return (
    <Routes>
      <Route path="create" element={<PuerperaCreate />} />
      <Route path=":pid" element={<Navigate to="info" />} />
      <Route path=":pid/*" element={<PuerperaRoot />} />
      <Route path="/" element={<PuerperaSearch />} />
    </Routes>
  );
};

const BookroomRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<BookRoomSearch />} />
      <Route path="selectPuerpera" element={<BookRoomSelectPuerpery />} />
      <Route path=":pid/edit" element={<BookRoomCreateOrEdit />} />
      <Route path=":bid" element={<BookRoomInfo />} />
    </Routes>
  );
};

const HousingRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HousingSearch />} />
      <Route path="selectPuerpera" element={<HousingSelectPuerpery />} />
      <Route path="create" element={<HousingCreateOrEdit />} />
      <Route path=":hid/edit" element={<HousingCreateOrEdit />} />
      <Route path=":hid" element={<HousingInfo />} />
      <Route path="foodVendor/:hid" element={<FoodVendorFromHousing />} />
      <Route
        path=":hid/foodVendorHisotry/:fvhid/edit"
        element={<FoodVendorHistoryCreateOrEdit />}
      />
      <Route
        path=":hid/createFoodVendorHisotry"
        element={<FoodVendorHistoryCreateOrEdit />}
      />
      <Route path=":hid/stopMeals" element={<StopMealsHistory />} />
      <Route path=":hid/beverage" element={<BeverageList />} />
      <Route
        path=":hid/beverage/:bhid/edit"
        element={<BeverageCreateOrEdit />}
      />
      <Route path=":hid/createBeverage" element={<BeverageCreateOrEdit />} />
    </Routes>
  );
};

const FoodVendorRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<ExportFoodVendorHistory />} />
    </Routes>
  );
};

const CareRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<CareSearch />} />
      <Route path="summary" element={<SearchForBabyStatusSummary />} />
      <Route path=":bid/summary" element={<BabyStatusSummary />} />
      <Route
        path="babyStatus/:bsrid/edit"
        element={<BabyStatusCreateOrEdit />}
      />
      <Route
        path=":bid/createBabyStatus"
        element={<BabyStatusCreateOrEdit />}
      />
      <Route path=":bid" element={<BabyWithBabyStatusInfo />} />
    </Routes>
  );
};

const StaffRoute = () => {
  return (
    <Routes>
      <Route path="create" element={<StaffCreate />} />
      <Route path=":sid/edit" element={<StaffEdit />} />
      <Route path=":sid" element={<StaffInfo />} />
      <Route path="/" element={<StaffList />} />
    </Routes>
  );
};

const UsersRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<UserList />} />
      <Route path=":uid" element={<UserInfo />} />
    </Routes>
  );
};

const AppContent = () => {
  return (
    <div className="App">
      <CherieHeader />

      <div className="App__content">
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="room/*" element={<RoomRoute />} />
          <Route path="bookvisit/*" element={<BookVisitRoute />} />
          <Route path="schedule/*" element={<ScheduleRoute />} />
          <Route path="puerpera/*" element={<PuerperaRoute />} />
          <Route path="bookroom/*" element={<BookroomRoute />} />
          <Route path="housing/*" element={<HousingRoute />} />
          <Route path="foodvendor/*" element={<FoodVendorRoute />} />
          <Route path="care/*" element={<CareRoute />} />
          <Route path="staff/*" element={<StaffRoute />} />
          <Route path="users/*" element={<UsersRoute />} />
          <Route path="/group" element={<GroupList />} />
          <Route path="/room-activity" element={<ManyDayActivity />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/demo" element={<Demo />} />
        </Routes>
      </div>

      <div className="App__footer">
        © 璽悅產後護理之家 All rights reserved 2024{" "}
        <div className="App__commit_version">{window.version}</div>
      </div>
    </div>
  );
};

function AppContextProvider({ children }) {
  const [currentCenter, setCurrentCenter] = useState({});
  const schemas = useSchemas();
  const centerList = useCenters();
  const statusList = useBookVisitStatus();
  const customerBookVisitStatusList = useCustomerBookVisitStatus();
  const interactionStatusList = useBookVisitInteractionStatus();
  const changeFoodVendorReasonList = useChangeFoodVendorReason();
  const foodMealList = useFoodMeal();
  const foodVendorList = useFoodVendor();

  const { data: roomTypeList } = useSWR([URL.roomType], F.withToken);
  const departmentList = useDepartment();

  return (
    <CentersContext.Provider
      value={{
        current: currentCenter,
        setCurrent: setCurrentCenter,
        list: centerList,
      }}
    >
      <RoomTypeContext.Provider value={{ list: roomTypeList }}>
        <DepartmentsContext.Provider value={{ list: departmentList }}>
          <StatusContext.Provider
            value={{
              bookVisitStatus: statusList,
              customerBookVisitStatus: customerBookVisitStatusList,
              interactionStatus: interactionStatusList,
              changeFoodVendorReason: changeFoodVendorReasonList,
              foodMeals: foodMealList,
              foodVendor: foodVendorList,
            }}
          >
            <SchemaContext.Provider value={schemas}>
              {children}
            </SchemaContext.Provider>
          </StatusContext.Provider>
        </DepartmentsContext.Provider>
      </RoomTypeContext.Provider>
    </CentersContext.Provider>
  );
}

function App() {
  useEffect(() => {
    document.title = `璽悅資訊系統`;
  });

  const auth = useAuth();

  return (
    <UserContext.Provider value={auth}>
      <AppContextProvider>
        <AppContent />
      </AppContextProvider>
    </UserContext.Provider>
  );
}

export default App;
