import { useState, useCallback, useMemo, useEffect } from "react";
import { Checkbox, CheckboxSkeleton, Modal } from "carbon-components-react";
import { ModalPortal } from "./Base";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../api";

export const BabyBedModal = ({
  open = false,
  filter = {},
  className = "",
  onRequestClose,
  onRequestSubmit,
}) => {
  const { center } = filter;

  const url = `${URL.babybed}?center=${center ?? ""}`;
  const { data: babybeds = [] } = useSWR([url], F.withToken);
  const [selectedBabyBeds, setSelectedBabyBeds] = useState([]);
  const babybedMap = useMemo(() => {
    let result = {};
    for (let babybed of babybeds) {
      result[babybed.id] = false;
    }
    for (let babybed of selectedBabyBeds) {
      result[babybed.id] = true;
    }
    return result;
  }, [babybeds, selectedBabyBeds]);

  const handleSubmit = useCallback(() => {
    if (typeof onRequestSubmit !== "function") return;
    onRequestSubmit(selectedBabyBeds);
  }, [onRequestSubmit, selectedBabyBeds]);

  useEffect(() => {
    if (!open) return;
    setSelectedBabyBeds([]);
  }, [open]);

  const isLoading = !babybeds;

  return (
    <ModalPortal>
      <Modal
        className={className}
        modalHeading="請選擇嬰兒床位"
        open={open}
        primaryButtonDisabled={!selectedBabyBeds}
        primaryButtonText="確定"
        secondaryButtonText="取消"
        onRequestClose={onRequestClose}
        onRequestSubmit={handleSubmit}
      >
        {isLoading ? (
          <>
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
          </>
        ) : (
          babybeds.map((babybed) => {
            const id = `babybed_${babybed.id}`;
            return (
              <Checkbox
                key={id}
                id={id}
                className={className}
                labelText={`${babybed.name}`}
                checked={babybedMap[babybed.id]}
                onChange={(value) => {
                  const newMap = { ...babybedMap, [babybed.id]: value };
                  let newBabybeds = [];
                  for (let babybed of babybeds) {
                    if (newMap[babybed.id]) newBabybeds.push(babybed);
                  }
                  setSelectedBabyBeds(newBabybeds);
                }}
              />
            );
          })
        )}
      </Modal>
    </ModalPortal>
  );
};
