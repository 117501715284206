import {
  FormGroup,
  RadioButtonGroup,
  RadioButton,
} from "carbon-components-react";
import { Label } from "../Label";

export const Radio = ({ id, className, style, field = {}, data, onChange }) => {
  const { key, required = false, ui } = field;
  const { enum: enums = [], enumNames = [], title } = field.schema;
  const disabled = ui["ui:readonly"] ?? false;
  let pairs = [];
  for (let i = 0; i < enums.length; ++i) {
    pairs.push([enums[i], enumNames[i]]);
  }

  return (
    <FormGroup
      id={id}
      className={className}
      style={style}
      legendText={<Label required={required}>{title}</Label>}
    >
      <RadioButtonGroup
        disabled={disabled}
        name={`${id}__${key}`}
        valueSelected={data}
        defaultSelected={data}
        onChange={(value) => {
          if (typeof onChange !== "function") return;
          onChange({ [key]: value });
        }}
      >
        {pairs.map(([val, desc]) => (
          <RadioButton
            key={val}
            id={`${id}__${key}--${val}`}
            labelText={desc}
            value={val}
          />
        ))}
      </RadioButtonGroup>
    </FormGroup>
  );
};
