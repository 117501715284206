import {
  useParams,
  useNavigate,
  useResolvedPath,
  useMatch,
  Route,
  Routes,
} from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Content,
  Tabs,
  Tab,
  Grid,
  Row,
  Column,
} from "carbon-components-react";
import { BabyCreatedOrEditTab } from "./BabyCreatedOrEditTab";
import { BabyInfoTab } from "./BabyInfoTab";
import { BabyTab } from "./BabyTab";
import { ContractCreateOrEditTab } from "./ContractEditTab";
import { ContractTab } from "./ContractTab";
import { EditTab } from "./EditTab";
import { HistoryTab } from "./HistoryTab";
import { InfoTab } from "./InfoTab";
import { NoteAddTab } from "./NoteAddTab";
import { NoteEditTab } from "./NoteEditTab";
import { NoteInfoTab } from "./NoteInfoTab";
import { NoteTab } from "./NoteTab";

const TabContentRenderedOnlyWhenSelected = ({
  selected,
  children,
  className,
  prefix,
  ...other
}) =>
  !selected ? (
    <div {...other} className={`${prefix}--visually-hidden`} />
  ) : (
    <div
      {...other}
      className={`${prefix}--tab-content`}
      role="tabpanel"
      selected={selected}
    >
      {children}
    </div>
  );

export const PuerperaRoot = () => {
  const navigate = useNavigate();
  const { pid } = useParams();
  const resolved = useResolvedPath(":tab/*");
  const match = useMatch(resolved.pathname);
  const { tab } = match.params;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/puerpera/">產婦管理</BreadcrumbItem>
        <BreadcrumbItem href={`/puerpera/${pid}`} isCurrentPage>
          產婦資訊
        </BreadcrumbItem>
      </Breadcrumb>

      <Grid>
        <Row>
          <Column>
            <Tabs type="container" selected={indexFromTab(tab)}>
              <Tab
                label="產婦"
                renderContent={TabContentRenderedOnlyWhenSelected}
                onClick={() => navigate("info")}
              >
                <Routes>
                  <Route
                    path="info"
                    element={<InfoTab pid={pid} prefix="cbx" />}
                  />
                  <Route
                    path="edit"
                    element={<EditTab pid={pid} prefix="p_edit" />}
                  />
                </Routes>
              </Tab>
              <Tab label="預約參觀" onClick={() => navigate("history")}>
                <Routes>
                  <Route path="history" element={<HistoryTab pid={pid} />} />
                </Routes>
              </Tab>
              <Tab label="合約" onClick={() => navigate("contract")}>
                <Routes>
                  <Route
                    path="contract/create"
                    element={<ContractCreateOrEditTab pid={pid} />}
                  />
                  <Route
                    path="contractEdit/:cid"
                    element={<ContractCreateOrEditTab pid={pid} />}
                  />
                  <Route path="contract" element={<ContractTab pid={pid} />} />
                </Routes>
              </Tab>
              <Tab label="入住資訊" onClick={() => navigate("notes")}>
                <Routes>
                  <Route path="notes" element={<NoteTab pid={pid} />} />
                  <Route path="noteCreate" element={<NoteAddTab pid={pid} />} />
                  <Route
                    path="noteInfo/:nid"
                    element={<NoteInfoTab pid={pid} />}
                  />
                  <Route
                    path="noteEdit/:nid"
                    element={<NoteEditTab pid={pid} />}
                  />
                </Routes>
              </Tab>
              <Tab label="新生兒" onClick={() => navigate("baby")}>
                <Routes>
                  <Route
                    path="baby/create"
                    element={<BabyCreatedOrEditTab pid={pid} />}
                  />
                  <Route path="baby/:bid" element={<BabyInfoTab pid={pid} />} />
                  <Route
                    path="babyEdit/:bid"
                    element={<BabyCreatedOrEditTab pid={pid} />}
                  />
                  <Route path="baby" element={<BabyTab pid={pid} />} />
                </Routes>
              </Tab>
            </Tabs>
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};

const tabMap = {
  info: 0,
  edit: 0,
  history: 1,
  contract: 2,
  contractEdit: 2,
  notes: 3,
  noteCreate: 3,
  noteInfo: 3,
  noteEdit: 3,
  baby: 4,
  babyEdit: 4,
};

function indexFromTab(tab) {
  return tabMap[tab] ?? 0;
}
