import {
  Breadcrumb,
  BreadcrumbItem,
  Content,
  Tile,
  Grid,
  Row,
  Column,
} from "carbon-components-react";

export const List = () => (
  <Content>
    <Breadcrumb>
      <BreadcrumbItem href="#">系統管理</BreadcrumbItem>
      <BreadcrumbItem href="#" isCurrentPage>
        權限管理
      </BreadcrumbItem>
    </Breadcrumb>

    <Tile>權限角色清單</Tile>

    <Grid>
      <Row>
        <Column>{/* 內容放在這 */}</Column>
      </Row>
    </Grid>
  </Content>
);
