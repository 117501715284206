import React from "react";
import { useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Grid,
  Pagination,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Tile,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
} from "carbon-components-react";
import { AddFilled20, Search16 } from "@carbon/icons-react";

import dayjs from "dayjs";
import useSWR from "swr";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";
import { DateTimeFormat } from "../../utils/datetime";
import { SchemaContext } from "../../context";
import { SchemaViewer } from "../../components/Viewer";
import { URL, Fetcher as F } from "../../api";
import { UserContext, CentersContext, StatusContext } from "../../context";
import { hasId } from "../../utils";
import * as A from "../../utils/array";

const headers = [
  {
    key: "created",
    name: "建立資料時間",
  },
  {
    key: "puerpera_status",
    name: "比對產婦",
  },
  {
    key: "cbv_status",
    name: "比對網路",
  },
  {
    key: "contract_name",
    name: "姓名",
  },
  {
    key: "contract_phone",
    name: "聯絡電話",
  },
  {
    key: "edc",
    name: "預產期",
  },
  {
    key: "visit_date",
    name: "希望參觀日",
  },
  {
    key: "centers_str",
    name: "選擇館別",
  },
  {
    key: "updated_date",
    name: "處理日",
  },
  {
    key: "employee_name",
    name: "經辦",
  },
  {
    key: "status_name",
    name: "狀態",
  },
  {
    key: "action",
    name: "轉入",
  },
];

const createQueryString = ({
  keyingKeyword,
  center,
  sinceCreated,
  untilCreated,
  status,
  offset,
  limit,
}) => {
  const params = new URLSearchParams();
  params.append("expand", "employee,centers,status,visit_schedule");
  if (center !== undefined) {
    const cid = center.id;
    if (cid !== 0) {
      params.append("center", cid);
    }
  }
  if (keyingKeyword !== undefined) {
    params.append("search", keyingKeyword);
  }
  if (sinceCreated !== undefined) {
    params.append(
      "sinceCreated",
      dayjs(sinceCreated).format(DateTimeFormat.date)
    );
  }
  if (untilCreated !== undefined) {
    params.append(
      "untilCreated",
      dayjs(untilCreated).format(DateTimeFormat.date)
    );
  }
  if (status !== undefined) {
    const sid = status.id;
    if (sid !== 0) {
      params.append("status", status.id);
    }
  }
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  return params.toString();
};

export const CustomerBookVisitSearch = () => {
  const { list: centerList } = useContext(CentersContext);
  const { customerBookVisitStatus: customerStatusList } =
    useContext(StatusContext);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const [sinceCreated, setSinceCreated] = useState();
  const [untilCreated, setUntilCreated] = useState();

  const [center, setCenter] = useState();
  const [status, setStatus] = useState();
  const [keyingKeyword, setKeyingKeyword] = useState();

  const queryString = useMemo(
    () =>
      createQueryString({
        keyingKeyword,
        center,
        sinceCreated,
        untilCreated,
        status,
        offset,
        limit,
      }),
    [keyingKeyword, center, sinceCreated, untilCreated, status, offset, limit]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const {
    token: { access },
  } = useContext(UserContext);
  const url = `${URL.bookVisit.customer}?${realyQuery}`;
  const { data } = useSWR(access && [url], F.withToken);

  const isLoading = !data;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/bookvisit/">預約參訪</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          網路預約
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>網路預約</Tile>

      <Grid>
        <Row>
          <Column>
            <Search
              labelText="搜尋"
              placeholder="姓名或手機號碼"
              onChange={(e) => {
                const v = e.target.value;
                setKeyingKeyword(v);
              }}
            />
          </Column>
          <Column md={1}>
            <Dropdown
              id="center-selector"
              titleText="館別"
              label="全部"
              items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
              itemToString={(item) => item.name}
              selectedItem={center}
              onChange={(v) => {
                setCenter(v.selectedItem);
              }}
            />
          </Column>
          <Column>
            <DatePicker
              dateFormat="Y/m/d"
              datePickerType="range"
              onChange={([startDate, endDate]) => {
                setSinceCreated(startDate);
                setUntilCreated(endDate);
              }}
            >
              <DatePickerInput
                id="bv_customer__search__createdDate"
                placeholder="yyyy/mm/dd"
                labelText="起始建立時間"
              />
              <DatePickerInput
                id="bv_customer__search__createdDate"
                placeholder="yyyy/mm/dd"
                labelText="結束建立時間"
              />
            </DatePicker>
          </Column>
          <Column md={1}>
            <Dropdown
              id="status-selector"
              titleText="狀態"
              label="全部"
              items={A.insert(customerStatusList, { id: 0, name: "全部" }, 0)}
              itemToString={(item) => item.name}
              selectedItem={status}
              onChange={(v) => {
                setStatus(v.selectedItem);
              }}
            />
          </Column>
          <Column sm={1}>
            <Button
              renderIcon={Search16}
              iconDescription="搜尋"
              hasIconOnly
              onClick={() => {
                setPage(1);
                const queryString = createQueryString({
                  keyingKeyword,
                  center,
                  sinceCreated,
                  untilCreated,
                  status,
                  offset: 0,
                  limit,
                });
                setRealyQuery(queryString);
              }}
            ></Button>
          </Column>
        </Row>
      </Grid>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <CustomerBookVisitTable
          page={page}
          offset={offset}
          limit={limit}
          data={data}
          onPageChange={({ page, pageSize }) => {
            const limit = pageSize;
            const offset = (page - 1) * limit;
            // offset and limit will not be update checkout the next event loop iteration
            setPage(page);
            setLimit(limit);
            // so we update the real query by ourselves
            const queryString = createQueryString({
              keyingKeyword,
              center,
              sinceCreated,
              untilCreated,
              status,
              offset,
              limit,
            });
            setRealyQuery(queryString);
          }}
        />
      )}
    </Content>
  );
};

function CustomerBookVisitTable({ page, limit, data, onPageChange }) {
  const { customerBookVisit: customerBookVisitSchema } =
    useContext(SchemaContext) || {};
  const excludedExpandFields = [
    "id",
    "status",
    "created",
    "modified",
    "run_check_status",
    "contract_name",
    "contract_phone",
    "puerpera_status",
    "cbv_status",
    "edc",
    "updated_date",
    "employee",
  ];

  const pageSize = limit;
  const total = (data && data.count) || 0;
  const _data = (data && data.results) || [];

  const cleanData = _data.map(({ employee, status, centers, ...other }) => {
    return {
      ...other,
      employee_name: employee?.name ?? "",
      status_name: status?.name ?? "",
      centers_str: centers.map((c) => c.name).toString(),
      centers,
    };
  });

  const pageProps = {
    disabled: !data,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  return (
    <DataTable rows={cleanData} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getExpandHeaderProps,
        getRowProps,
        getTableProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                <TableExpandHeader
                  enableExpando={true}
                  {...getExpandHeaderProps()}
                />
                {headers.map((header) => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const rowData = cleanData.find(hasId(row.id));
                return (
                  <React.Fragment key={row.id}>
                    <TableExpandRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => {
                        const { header = "" } = cell && cell.info;
                        const cellId = cell.id.split(":")[0];
                        let content;
                        switch (header) {
                          case "edc":
                          case "updated_date":
                            if (cell.value !== null) {
                              content = dayjs(cell.value).format(
                                DateTimeFormat.date
                              );
                            } else {
                              content = cell.value;
                            }
                            break;
                          case "created":
                            if (cell.value !== null) {
                              content = dayjs(cell.value).format(
                                DateTimeFormat.datetime
                              );
                            } else {
                              content = cell.value;
                            }
                            break;
                          case "employee_name":
                          case "puerpera_status":
                          case "cbv_status":
                          case "centers_str":
                          case "contract_name":
                          case "contract_phone":
                          case "visit_date":
                          case "status_name":
                            content = cell.value;
                            break;
                          case "action":
                            content = (
                              <div>
                                <Button
                                  kind="ghost"
                                  renderIcon={AddFilled20}
                                  as={Link}
                                  to={`/bookvisit/customer/${cellId}/edit`}
                                />
                              </div>
                            );
                            break;
                          default:
                            content = <span>?</span>;
                        }
                        return <TableCell key={cell.id}>{content}</TableCell>;
                      })}
                    </TableExpandRow>
                    <TableExpandedRow
                      colSpan={headers.length + 1}
                      key={`${row.id}__expanded`}
                    >
                      <SchemaViewer
                        fields={customerBookVisitSchema.fields}
                        excludes={excludedExpandFields}
                        isLoading={!rowData}
                        data={rowData}
                      />
                    </TableExpandedRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
